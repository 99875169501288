import {
  faCalculator,
  faCloudRain,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useHistory } from "react-router-dom";
import useAILessonCreatorClient from "../../../../hooks/useAILessonCreatorClient";
import ErrorComponent from "../../../common_components/ErrorComponent";
import { LoadingComponentFullScreen } from "../../../common_components/LoadingComponent";
import styles from "./LessonCreationMenu.module.css";
import { BuildExampleCard } from "../BuildExampleCard/BuildExampleCard";
import { InputSection } from "../../../common_components/Input/InputSection";

export const LessonCreationMenu: React.FC = () => {
  const history = useHistory();
  const [error, setError] = React.useState("");
  const [prompt, setStatePrompt] = React.useState("");
  const { loading, generateSectionContentFromPrompt } =
    useAILessonCreatorClient();
  const handleChangeValue = React.useCallback(
    (value: string) => {
      setStatePrompt(value);
    },
    [setStatePrompt, prompt]
  );

  const handleSubmitPrompt = React.useCallback(
    async (maybePresetPrompt?: string) => {
      if (maybePresetPrompt) {
        await generateSectionContentFromPrompt(maybePresetPrompt).then(
          (lessonId: string) => {
            history.push(`/lesson/${lessonId}`);
          }
        );
      } else if (prompt) {
        await generateSectionContentFromPrompt(prompt).then((lessonId) => {
          history.push(`/lesson/${lessonId}`);
        });
      }
    },
    [prompt]
  );

  return (
    <div className={styles.container}>
      {loading && <LoadingComponentFullScreen />}
      {error && (
        <ErrorComponent
          title="Lesson generation failed."
          close={() => setError("")}
        >
          {error}
        </ErrorComponent>
      )}
      <div className={styles.aiContainer}>
        <div className={styles.aiBody}>
          <div className={styles.gridTitle}>
            Hello, what are you building{" "}
            <span className={styles.todayText}>today?</span>
          </div>

          <div className={styles.inputGroup}>
            <InputSection
              value={prompt}
              placeholder={"Ask cham..."}
              handleChangeValue={handleChangeValue}
              handleSubmitInput={handleSubmitPrompt}
            />

            <div className={styles.examples}>
              <BuildExampleCard
                icon={faListCheck}
                appName={"A to-do list app"}
                // eslint-disable-next-line @typescript-eslint/promise-function-async
                runFunction={() => handleSubmitPrompt("Build a todo list app")}
              />
              <BuildExampleCard
                icon={faCloudRain}
                appName={"Weather app"}
                // eslint-disable-next-line @typescript-eslint/promise-function-async
                runFunction={() => handleSubmitPrompt("Build a weather app")}
              />
              <BuildExampleCard
                icon={faCalculator}
                appName={"Simple calculator"}
                // eslint-disable-next-line @typescript-eslint/promise-function-async
                runFunction={() =>
                  handleSubmitPrompt("Build a simple calculator app")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
