import React, { useEffect, useRef } from "react";
import styles from "./LessonSectionPreview.module.css";
import { ILessonPageSection } from "../../../types/lesson";
import { useTerminalHighlight } from "../ChamIDE/contexts/TerminalHighlightContext";
import { useWorkspaceHighlight } from "../ChamIDE/contexts/WorkspaceHighlightContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { MarkdownPreviewCodeBlock } from "./MarkdownPreviewCodeBlock";
import useAILessonCreatorClient from "../../../hooks/useAILessonCreatorClient";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { useClarificationChat } from "../ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { useViewContext } from "../ChamIDE/contexts/ViewContext/ViewContext";
import { ViewState } from "../LessonLayout/types";

interface IRichTextPreviewProps {
  section: ILessonPageSection;
  pageId: string;
  lessonId: string;
}

function MarkdownPreview({
  section,
  pageId,
  lessonId,
}: IRichTextPreviewProps): React.JSX.Element {
  const { setIsTerminalHighlighted } = useTerminalHighlight();
  const { setIsWorkspaceHighlighted } = useWorkspaceHighlight();
  const contentRef = useRef<HTMLDivElement>(null);
  const { clarifySectionWithChamAI } = useAILessonCreatorClient();
  const {
    setActiveChatId,
    setFetchClarificationsTrigger,
    fetchClarificationsTrigger,
  } = useClarificationChat();
  const handleTerminalHighlight = (): void => {
    setIsTerminalHighlighted?.(true);
    console.log("Terminal highlighted");
  };
  const [question, setQuestion] = React.useState<string>();
  const content = section.content.markdown?.content;
  const [showClarifyMessage, setShowClarifyMessage] =
    React.useState<boolean>(false);
  const { setCurrentView } = useViewContext();

  const handleTerminalRemoveHighlight = (): void => {
    setIsTerminalHighlighted?.(false);
  };

  const handleWorkspaceHighlight = (): void => {
    setIsWorkspaceHighlighted?.(true);
  };

  const handleWorkspaceRemoveHighlight = (): void => {
    setIsWorkspaceHighlighted?.(false);
  };

  const wrapKeywordsWithSpans = (htmlString: string): string => {
    const terminalRegex = /(chamterminal|terminal)/gi;
    const workspaceRegex = / workspace /gi;

    let result = htmlString.replace(
      terminalRegex,
      (match) =>
        `<span class="terminal-highlight" style="color: #39a76b; font-weight: bold; cursor: pointer;">${match}</span>`
    );

    result = result.replace(
      workspaceRegex,
      (match) =>
        `<span class="workspace-highlight" style="color: #ff9800; font-weight: bold; cursor: pointer;">${match}</span>`
    );

    return result;
  };

  useEffect(() => {
    const contentElement = contentRef.current;

    if (!contentElement) return;

    const handleEvent = (event: Event): void => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("terminal-highlight")) {
        if (event.type === "mouseenter" || event.type === "touchstart") {
          handleTerminalHighlight();
        } else if (event.type === "mouseleave" || event.type === "touchend") {
          handleTerminalRemoveHighlight();
        }
      } else if (target.classList.contains("workspace-highlight")) {
        if (event.type === "mouseenter" || event.type === "touchstart") {
          handleWorkspaceHighlight();
        } else if (event.type === "mouseleave" || event.type === "touchend") {
          handleWorkspaceRemoveHighlight();
        }
      }
    };

    contentElement.addEventListener("mouseenter", handleEvent, true);
    contentElement.addEventListener("mouseleave", handleEvent, true);
    contentElement.addEventListener("touchstart", handleEvent, true);
    contentElement.addEventListener("touchend", handleEvent, true);

    return () => {
      contentElement.removeEventListener("mouseenter", handleEvent, true);
      contentElement.removeEventListener("mouseleave", handleEvent, true);
      contentElement.removeEventListener("touchstart", handleEvent, true);
      contentElement.removeEventListener("touchend", handleEvent, true);
    };
  }, []);

  const renderedComponent = React.useMemo(() => {
    if (section.clarification.id) {
      if (section.clarification.summary) {
        return (
          <div
            onClick={() => {
              setActiveChatId?.(section.clarification.id);
              setCurrentView(ViewState.ClarificationChat);
            }}
            className={styles.clarificationSummary}
          >
            {parse(section.clarification.summary)}
          </div>
        );
      }
    }
  }, [section.clarification, section]);

  const handleClarifySection = React.useCallback(
    async (question: string) => {
      if (section.id && question) {
        const clarificationId = await clarifySectionWithChamAI(
          lessonId,
          section.id,
          pageId,
          question
        );

        setActiveChatId?.(clarificationId);
        setFetchClarificationsTrigger?.((fetchClarificationsTrigger ?? 0) + 1);
        setShowClarifyMessage(false);
      }
    },
    [
      lessonId,
      section.id,
      pageId,
      clarifySectionWithChamAI,
      setFetchClarificationsTrigger,
      fetchClarificationsTrigger,
    ]
  );

  if (!content) {
    console.error("content not found");
    return <div></div>;
  }

  return (
    <>
      <div
        className={styles.markdownPreview}
        ref={contentRef}
        onMouseLeave={handleTerminalRemoveHighlight}
        onTouchCancel={handleTerminalRemoveHighlight}
      >
        <div className={styles.markdownPreviewHeader}>
          {!showClarifyMessage ? (
            <button
              onClick={(e) => {
                if (section.clarification.id) {
                  setCurrentView(ViewState.ClarificationChat);
                  setActiveChatId?.(section.clarification.id);
                } else {
                  setShowClarifyMessage(true);
                }
              }}
              className={styles.markdownRegenerateButton}
            >
              Clarify
            </button>
          ) : (
            <div className={styles.question}>
              <input
                className={styles.questionInput}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
                type="text"
                name="question"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && question) {
                    void handleClarifySection(question);
                  }
                }}
                placeholder="Ask ChamAI specific questions to clarify this phrase"
                value={question}
              />
              <button
                className={
                  question != null && question !== ""
                    ? styles.arrowBtn
                    : styles.arrowBtnDisabled
                }
                onClick={() => {
                  if (question) {
                    void handleClarifySection(question);
                  }
                }}
              >
                <FontAwesomeIcon
                  className={styles.arrowIcn}
                  icon={faArrowUpLong}
                />
              </button>
            </div>
          )}
        </div>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            // Use this temporarily
            code: MarkdownPreviewCodeBlock,
          }}
        >
          {wrapKeywordsWithSpans(content)}
        </ReactMarkdown>
      </div>
      {renderedComponent}
    </>
  );
}

export default MarkdownPreview;
