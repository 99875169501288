import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LessonProvider } from "../common_components/LessonContext/LessonContext";
import Layout from "../common_components/LessonLayout/Layout";
import { LessonCreationContainer } from "./components/LessonCreationContainer/LessonCreationContainer";
import useLessonApiClient from "../../hooks/useLessonApiClient";
import DashboardHeaderProvider from "../common_components/DashboardHeaderComponent/DashboardHeaderContext";

function LessonCreationView(): React.JSX.Element {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [lessonAuthorId, setLessonAuthorId] = useState<string>("");
  const { fetchLesson } = useLessonApiClient();

  useEffect(() => {
    fetchLesson(lessonId)
      .then((result) => {
        setLessonAuthorId(result.authorId);
      })
      .catch((_error) => {
        setLessonAuthorId("");
      });
  }, [lessonId]);

  return (
    <LessonProvider lessonId={lessonId} lessonAuthorId={lessonAuthorId}>
      <DashboardHeaderProvider isTransparent={true}>
        <Layout>
          <LessonCreationContainer lessonId={lessonId} />
        </Layout>
      </DashboardHeaderProvider>
    </LessonProvider>
  );
}

export default LessonCreationView;
