import React from "react";
import styles from "./input.module.css";

interface InputProps {
  className: string;
}

export default function Input(
  props: InputProps &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
): JSX.Element {
  return <input {...props} className={styles.input + " " + props.className} />;
}
