import React from "react";
import styles from "./LoadingComponent.module.css";
import IconLogo from "../Logo/icon-logo";

export const LoadingComponentContained = (): JSX.Element => {
  return (
    <div className={styles.containerContained}>
      <IconLogo />
      <span className={styles.title}>BuildQL</span>
    </div>
  );
};

export const LoadingComponentFullScreen = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <IconLogo height="150px" />
      <span className={styles.title}>BuildQL</span>
    </div>
  );
};
