import React from "react";

export type PopoverLocation = "editor" | "content";

export interface PopoverContextData {
  popoverRef?: React.RefObject<HTMLDivElement>;
  showPopover?: boolean;
  setShowPopover?: (show: boolean) => void;
  highlightedText?: string;
  setHighlightedText?: (text: string) => void;
  popoverPosition?: { top: number; left: number };
  setPopoverPosition?: (position: { top: number; left: number }) => void;
  popoverLocation?: PopoverLocation;
  setPopoverLocation?: (location: "editor" | "content") => void;
}
export const PopoverContext = React.createContext<
  PopoverContextData | undefined
>(undefined);

interface PopoverProviderProps extends PopoverContextData {
  children: React.ReactNode;
}

export function PopoverProvider({
  children,
}: PopoverProviderProps): React.ReactNode {
  const [showPopover, setShowPopover] = React.useState(false);
  const [popoverPosition, setPopoverPosition] = React.useState({
    top: 0,
    left: 0,
  });
  const [highlightedText, setHighlightedText] = React.useState<string>();
  const [popoverLocation, setPopoverLocation] =
    React.useState<PopoverLocation>("content");

  const popoverRef = React.useRef<HTMLDivElement>(null);

  return (
    <PopoverContext.Provider
      value={{
        popoverRef,
        showPopover,
        setPopoverPosition,
        setShowPopover,
        popoverPosition,
        highlightedText,
        setHighlightedText,
        popoverLocation,
        setPopoverLocation,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
}

export function usePopoverContext(): PopoverContextData {
  const {
    showPopover,
    setShowPopover,
    popoverPosition,
    setPopoverPosition,
    popoverRef,
    highlightedText,
    setHighlightedText,
    popoverLocation,
    setPopoverLocation,
  } = React.useContext(PopoverContext) ?? {};
  return {
    showPopover,
    setShowPopover,
    popoverPosition,
    setPopoverPosition,
    popoverRef,
    highlightedText,
    setHighlightedText,
    popoverLocation,
    setPopoverLocation,
  };
}
