import React, { useMemo } from "react";
import styles from "./SectionEdit.module.css";
import CodeMirror from "@uiw/react-codemirror";
import { githubLight } from "@uiw/codemirror-theme-github";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { java } from "@codemirror/lang-java";
import { rust } from "@codemirror/lang-rust";

interface CodeBlockEditorProps {
  content?: {
    codeBlock: string;
    codeLanguage: string;
  };
  setContent: (content: { codeBlock?: string; codeLanguage?: string }) => void;
}

function CodeBlockEditor({
  content,
  setContent,
}: CodeBlockEditorProps): React.JSX.Element {
  const extensions = useMemo(() => {
    switch (content?.codeLanguage) {
      case "javascript":
        return [javascript()];
      case "python":
        return [python()];
      case "java":
        return [java()];
      case "rust":
        return [rust()];
      default:
        return [];
    }
  }, []);
  return (
    <div className={styles.codeBlockEditor}>
      <CodeMirror
        value={content?.codeBlock}
        onChange={(codeBlock) => setContent({ ...content, codeBlock })}
        className={styles.editor}
        theme={githubLight}
        extensions={extensions}
      />
    </div>
  );
}

export default CodeBlockEditor;
