import {
  faBars,
  faCirclePlus,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import useLessonManager from "../../../../hooks/useLessonManager";
import { IPageErrorData } from "../../../../types/lesson";
import CheckLoginComponent from "../../../common_components/CheckLoginComponent";
import ErrorComponent from "../../../common_components/ErrorComponent";
import { LoadingComponentContained } from "../../../common_components/LoadingComponent";
import PageBuilder from "../PageBuilder";
import PageMenu from "../PageMenu";
import styles from "./LessonCreationContainer.module.css";
import { useUserApiClient } from "../../../../hooks/useUserApiClient";
import { useHistory } from "react-router-dom";
import CheckSubscriptionComponent from "../../../common_components/CheckSubscriptionComponent";

export const LessonCreationContainer = ({
  lessonId,
}: {
  lessonId: string;
}): JSX.Element => {
  const {
    isLoading,
    isLessonSaved,
    lesson,
    currentPageIndex,
    addPage,
    savePage,
    updatePage,
    addPageSection,
    updatePageSection,
    deletePageSection,
    updateCurrentPageIndex,
  } = useLessonManager(lessonId);
  const [pageError, setPageError] = useState<IPageErrorData>();
  const { signedInUser, loading: fetchSignedInUserLoading } =
    useUserApiClient();
  const history = useHistory();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      if (!isLessonSaved) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLessonSaved]);

  useEffect(() => {
    if (
      !fetchSignedInUserLoading &&
      !isLoading &&
      signedInUser?.id !== lesson.authorId
    ) {
      history.push(`/lesson/${lessonId}`);
    }
  }, [signedInUser, fetchSignedInUserLoading, lesson.authorId]);

  return (
    <CheckLoginComponent>
      <CheckSubscriptionComponent>
        {isLoading ? (
          // TODO: Use diff loader(Maybe skeleton)
          <LoadingComponentContained />
        ) : (
          <div className={styles.container}>
            <div className={styles.pageBuilderContainer}>
              {pageError && (
                <div className={styles.errorContainer}>
                  <ErrorComponent
                    title={pageError.title}
                    close={() => setPageError(undefined)}
                  >
                    {pageError.message}
                  </ErrorComponent>
                </div>
              )}
              <div className={styles.pageTitle}>
                <button>
                  <FontAwesomeIcon icon={faBars} className={styles.icon} />
                </button>
                <input
                  placeholder="Enter Page Title"
                  value={lesson.pages[currentPageIndex].title}
                  onChange={(event) =>
                    updatePage({
                      ...lesson.pages[currentPageIndex],
                      title: event.target.value,
                    })
                  }
                />
              </div>
              <PageBuilder
                sections={lesson.pages[currentPageIndex].sections}
                updateSection={updatePageSection}
                deleteSection={deletePageSection}
              />
              <div className={styles.addContent}>
                <button onClick={addPageSection}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className={styles.icon}
                  />
                </button>
              </div>
              <div className={styles.savePage}>
                {!isLessonSaved && (
                  <button
                    className={styles.savePageButton}
                    onClick={() => {
                      savePage().catch((error) =>
                        setPageError({
                          title: "Unable to save page.",
                          message:
                            error.message ?? "Error occured while saving page.",
                        })
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} />
                  </button>
                )}
              </div>
            </div>
            <PageMenu
              currentPageIndex={currentPageIndex}
              totalPages={lesson.pages.length}
              updateCurrentPageIndex={updateCurrentPageIndex}
              addPage={addPage}
            />
          </div>
        )}
      </CheckSubscriptionComponent>
    </CheckLoginComponent>
  );
};
