import React from "react";
import styles from "../LessonCreation.module.css";
import SectionTypeIcons from "./SectionEdit/ContentTypeIcons";
import { ContentTypes, ILessonPageSection } from "../../../types/lesson";
import SectionEdit from "./SectionEdit";
import SectionPreview from "./SectionPreview";

interface IPageBuilderProps {
  sections: ILessonPageSection[];
  updateSection: (sectionIndex: number, section: ILessonPageSection) => void;
  deleteSection: (sectionIndex: number) => void;
}

function PageBuilder({
  sections,
  updateSection,
  deleteSection,
}: IPageBuilderProps): React.JSX.Element {
  return (
    <>
      {sections.map((section, sectionIndex) => (
        <div key={sectionIndex} className={styles.contentArea}>
          <>
            {section.contentType === ContentTypes.Unknown ? (
              <SectionTypeIcons
                updateContentType={(contentType: ContentTypes) =>
                  updateSection(sectionIndex, {
                    ...section,
                    contentType,
                  })
                }
              />
            ) : section.showPreview ? (
              <SectionPreview
                section={section}
                hidePreview={() =>
                  updateSection(sectionIndex, {
                    ...section,
                    showPreview: false,
                  })
                }
                deleteSection={() => deleteSection(sectionIndex)}
              />
            ) : (
              <SectionEdit
                section={section}
                updateSection={(section: ILessonPageSection) =>
                  updateSection(sectionIndex, section)
                }
                deleteSection={() => deleteSection(sectionIndex)}
              />
            )}
          </>
        </div>
      ))}
    </>
  );
}

export default PageBuilder;
