import React from "react";
import styles from "./submit.module.css";

interface SubmitProps {
  className: string;
}

export default function Submit(
  props: SubmitProps &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
): JSX.Element {
  return <input {...props} className={styles.submit + " " + props.className} />;
}
