import React from "react";
import { Input, Submit } from "../../../../common_components/Form";
import styles from "./PasswordResetForm.module.css";

interface ResetPasswordFormComponentProps {
  submit: (newPassword: string) => void;
}

function ResetPasswordForm({
  submit,
}: ResetPasswordFormComponentProps): JSX.Element {
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault();
        password === passwordConfirmation
          ? submit(password)
          : alert("Passwords do not match.");
      }}
    >
      <div className={styles.titleComponent}>Create New Password</div>
      <div className={styles.textComponent}>
        We will ask for this password whenever you sign in.
      </div>
      <Input
        className={styles.input}
        required
        type="password"
        placeholder="New Password"
        onChange={(event) => setPassword(event.target.value)}
      />
      <div className={styles.info}>Passwords must be at least 6 characters</div>
      <Input
        className={styles.input}
        required
        type="password"
        placeholder="Confirm Password"
        onChange={(event) => setPasswordConfirmation(event.target.value)}
      />
      <div className={styles.spacing} />
      <Submit className={styles.submit} type="submit" value="Continue" />
    </form>
  );
}

export default ResetPasswordForm;
