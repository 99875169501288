import React from "react";
import { TreeNodeDataType } from "../../utils";
import { NodeModel } from "@minoru/react-dnd-treeview/dist/types";

export interface DirectoryContextValue {
  directoryTree?: Array<NodeModel<TreeNodeDataType>>;
  setDirectoryTree?: (
    directoryTree: Array<NodeModel<TreeNodeDataType>>
  ) => void;
  openedFileId?: string | number | undefined;
  setOpenedFileId?: (openedFileId: string | number | undefined) => void;
}

export const DirectoryContext = React.createContext<DirectoryContextValue>({});

interface DirectoryProviderProps {
  children: React.ReactNode;
}

export function DirectoryProvider({
  children,
}: DirectoryProviderProps): React.ReactNode {
  const [directoryTree, setDirectoryTree] = React.useState<
    Array<NodeModel<TreeNodeDataType>>
  >([]);
  const [openedFileId, setOpenedFileId] = React.useState<
    string | number | undefined
  >(undefined);
  return (
    <DirectoryContext.Provider
      value={{
        directoryTree,
        setDirectoryTree,
        openedFileId,
        setOpenedFileId,
      }}
    >
      {children}
    </DirectoryContext.Provider>
  );
}

export function useDirectoryTree(): DirectoryContextValue {
  const { directoryTree, setDirectoryTree, openedFileId, setOpenedFileId } =
    React.useContext(DirectoryContext);
  return {
    directoryTree,
    setDirectoryTree,
    openedFileId,
    setOpenedFileId,
  };
}
