import React, { useRef } from "react";
import styles from "./Chat.module.css";
import { faPaperPlane, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAILessonCreatorClient from "../../../../../hooks/useAILessonCreatorClient";
import { ClarificationWithMessage } from "../../../../../types/clarifications";
import { useLessonId } from "../../../../common_components/LessonContext/LessonContext";
import { ChatMessageView } from "./ChatMessageView";
import { useClarificationChat } from "../../../../common_components/ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { ClarificationType } from "../../../../../__generated__/graphql";

interface IChatView {
  chatId: string;
}
export const ChatView: React.FC<IChatView> = ({ chatId }) => {
  const { clarifyTextWithChamAI } = useAILessonCreatorClient();
  const { getClarification } = useAILessonCreatorClient();
  const [clarification, setClarification] =
    React.useState<ClarificationWithMessage>();
  const [isLoadingClarification, setIsLoadingClarification] =
    React.useState(true);
  const {
    setActiveChatId,
    setFetchClarificationsTrigger,
    fetchClarificationsTrigger,
  } = useClarificationChat();

  const lessonId = useLessonId();
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const MAX_INPUT_HEIGHT = 150;
  React.useEffect(() => {
    const getClarificationEffect = async (): Promise<void> => {
      setIsLoadingClarification(true);
      if (chatId) {
        const res = await getClarification(chatId);
        setClarification(res);
        setIsLoadingClarification(false);
      } else {
        setIsLoadingClarification(false);
      }
    };
    if (chatId !== "new") {
      void getClarificationEffect();
    } else {
      setIsLoadingClarification(false);
      setClarification(undefined);
    }
  }, [chatId]);

  const handleSendMessage = React.useCallback(
    async (input: string, onSend: () => void) => {
      if (!lessonId) {
        return;
      }
      setIsSendingMessage(true);
      const activeId = await clarifyTextWithChamAI(
        "",
        lessonId,
        undefined,
        input,
        chatId === "new" ? ClarificationType.Content : undefined,
        chatId !== "new" ? chatId : undefined
      );
      setActiveChatId?.(activeId);
      onSend();
      setIsSendingMessage(false);
      if (chatId === "new") {
        setFetchClarificationsTrigger?.((fetchClarificationsTrigger ?? 0) + 1);
      }
    },
    [
      lessonId,
      chatId,
      fetchClarificationsTrigger,
      setFetchClarificationsTrigger,
    ]
  );

  return (
    <div className={styles.chatContainer}>
      <div className={styles.chatTopSection}>
        <div className={styles.clarificationChatTitle}>
          <h2>Clarifications</h2>
        </div>
      </div>
      {lessonId && (
        <ChatMessageView
          chatId={chatId}
          clarification={clarification}
          isLoading={isLoadingClarification}
          lessonId={lessonId}
        />
      )}
      <div className={styles.messageBox}>
        <textarea
          name="message"
          ref={inputRef}
          rows={1}
          className={styles.messageInput}
          placeholder="Type here..."
          onKeyDown={(e) => {
            if (
              e.key === "Enter" &&
              !e.shiftKey &&
              inputRef?.current?.value &&
              inputRef.current.value.length > 0
            ) {
              e.preventDefault();
              void handleSendMessage(inputRef.current.value, () => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              });
            }
          }}
          onChange={(_) => {
            if (inputRef.current) {
              inputRef.current.style.height = "auto"; // Reset height to auto to shrink if necessary
              if (inputRef.current.scrollHeight > MAX_INPUT_HEIGHT) {
                inputRef.current.style.height = `${MAX_INPUT_HEIGHT}px`; // Set to maxHeight if scrollHeight exceeds it
              } else {
                inputRef.current.style.height = `${inputRef.current.scrollHeight.toString()}px`; // Set height to scrollHeight
              }
            }
          }}
        />
        <FontAwesomeIcon
          icon={isSendingMessage ? faSpinner : faPaperPlane}
          className={`${isSendingMessage ? "fa-spin" : styles.sendIcon}`}
          onClick={() => {
            if (
              !isSendingMessage &&
              inputRef?.current?.value &&
              inputRef.current.value.length > 0
            ) {
              void handleSendMessage(inputRef.current.value, () => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              });
            }
          }}
        />
      </div>
    </div>
  );
};
