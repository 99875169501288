import { type NodeModel } from "@minoru/react-dnd-treeview";
import { ROOT_DIRECTORY, TreeNodeDataType } from "../../utils";
import { apiDomain } from "../../../../../config";

enum ContentType {
  URL = 0,
  CODE = 1,
}
interface SessionFile {
  filename: string;
  contentType: ContentType;
  content: string;
}
interface SessionDirectory {
  directoryName: string;
  files?: SessionFile[];
  subdirectories?: SessionDirectory[];
}

export const getSessionDirectory = async (
  sessionId: string,
): Promise<Array<NodeModel<TreeNodeDataType>>> => {
  const response = await fetch(
    `${apiDomain}/session-service/session/getDirectory/${sessionId}`,
    {
      method: "GET",
      credentials: "include",
    },
  );

  if (!response.ok && response.status >= 400) {
    return await response.json().then((res) => {
      throw new Error(
        `Error getting saved session, server responded with the error: ${
          res.status as string
        } ${res.error as string}`,
      );
    });
  } else {
    const directoryTree = await response.json().then((data) => {
      return data.directoryTree as SessionDirectory;
    });
    return getNodeModelsFromSessionDirectory(directoryTree, ROOT_DIRECTORY);
  }
};

const getNodeModelsFromSessionDirectory = (
  directoryTree: SessionDirectory,
  parentId: string,
): Array<NodeModel<TreeNodeDataType>> => {
  const resArray: Array<NodeModel<TreeNodeDataType>> = [];
  directoryTree.files?.forEach((file) => {
    resArray.push({
      id: crypto.randomUUID(),
      parent: parentId,
      text: file.filename,
      droppable: false,
      data: {
        content: file.content,
      },
    });
  });
  directoryTree.subdirectories?.forEach((subDir) => {
    const dirId = crypto.randomUUID();
    resArray.push({
      id: dirId,
      parent: parentId,
      text: subDir.directoryName,
      droppable: true,
    });
    resArray.push(...getNodeModelsFromSessionDirectory(subDir, dirId));
  });
  return resArray;
};
