import React, { useEffect, useRef } from "react";
import parse, {
  Element,
  HTMLReactParserOptions,
  Text,
} from "html-react-parser";
import styles from "./LessonSectionPreview.module.css";
import { ILessonTextFieldContent } from "../../../types/lesson";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useTerminalHighlight } from "../ChamIDE/contexts/TerminalHighlightContext";
import { useWorkspaceHighlight } from "../ChamIDE/contexts/WorkspaceHighlightContext";

export const CLARIFICATION_KEY = "clarifications";

interface IRichTextPreviewProps {
  content: ILessonTextFieldContent;
  sectionId?: string;
  pageId?: string;
}

function TextFieldPreview({
  content,
  sectionId,
  pageId,
}: IRichTextPreviewProps): React.JSX.Element {
  const { setIsTerminalHighlighted } = useTerminalHighlight();
  const { setIsWorkspaceHighlighted } = useWorkspaceHighlight();
  const contentRef = useRef<HTMLDivElement>(null);

  const handleTerminalHighlight = (): void => {
    setIsTerminalHighlighted?.(true);
    console.log("Terminal highlighted");
  };

  const handleTerminalRemoveHighlight = (): void => {
    setIsTerminalHighlighted?.(false);
  };

  const handleWorkspaceHighlight = (): void => {
    setIsWorkspaceHighlighted?.(true);
  };

  const handleWorkspaceRemoveHighlight = (): void => {
    setIsWorkspaceHighlighted?.(false);
  };

  const wrapKeywordsWithSpans = (htmlString: string): string => {
    const terminalRegex = /(chamterminal|terminal)/gi;
    const workspaceRegex = /workspace/gi;

    let result = htmlString.replace(
      terminalRegex,
      (match) =>
        `<span class="terminal-highlight" style="color: #39a76b; font-weight: bold; cursor: pointer;">${match}</span>`
    );

    result = result.replace(
      workspaceRegex,
      (match) =>
        `<span class="workspace-highlight" style="color: #ff9800; font-weight: bold; cursor: pointer;">${match}</span>`
    );

    return result;
  };

  const parserOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      const element = domNode as Element;
      if (element.name === "pre" && element.children[0] instanceof Text) {
        return (
          <div className={styles.codeBlockPreview}>
            <SyntaxHighlighter customStyle={{ margin: 0 }} theme={a11yDark}>
              {element.children[0].data.replace(
                /\\\\\\\\\\\\\\\\\\\\\\\\\\\n/g,
                "\\\\\\\\\\\\\\\n"
              )}
            </SyntaxHighlighter>
          </div>
        );
      }
    },
  };

  useEffect(() => {
    const contentElement = contentRef.current;

    if (!contentElement) return;

    const handleEvent = (event: Event): void => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("terminal-highlight")) {
        if (event.type === "mouseenter" || event.type === "touchstart") {
          handleTerminalHighlight();
        } else if (event.type === "mouseleave" || event.type === "touchend") {
          handleTerminalRemoveHighlight();
        }
      } else if (target.classList.contains("workspace-highlight")) {
        if (event.type === "mouseenter" || event.type === "touchstart") {
          handleWorkspaceHighlight();
        } else if (event.type === "mouseleave" || event.type === "touchend") {
          handleWorkspaceRemoveHighlight();
        }
      }
    };

    contentElement.addEventListener("mouseenter", handleEvent, true);
    contentElement.addEventListener("mouseleave", handleEvent, true);
    contentElement.addEventListener("touchstart", handleEvent, true);
    contentElement.addEventListener("touchend", handleEvent, true);

    return () => {
      contentElement.removeEventListener("mouseenter", handleEvent, true);
      contentElement.removeEventListener("mouseleave", handleEvent, true);
      contentElement.removeEventListener("touchstart", handleEvent, true);
      contentElement.removeEventListener("touchend", handleEvent, true);
    };
  }, []);

  return (
    <>
      (
      <div
        className={styles.textFieldPreview}
        ref={contentRef}
        onMouseLeave={handleTerminalRemoveHighlight}
        onTouchCancel={handleTerminalRemoveHighlight}
      >
        {parse(wrapKeywordsWithSpans(content.text), parserOptions)}
      </div>
      )
    </>
  );
}

export default TextFieldPreview;
