import { apiDomain } from "../../../../../config";
export const deleteSessionDirectory = async (
  relativeParentPath: string,
  directoryName: string,
  sessionId: string,
): Promise<boolean> => {
  const response = await fetch(
    `${apiDomain}/session-service/session/deleteDirectory`,
    {
      method: "DELETE",
      body: JSON.stringify({
        sessionId,
        relativeParentPath,
        directoryName,
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    },
  );

  const createdFile = await response.json().then((data) => data.success);
  return createdFile;
};
