import React from "react";
import DashboardComponent from "../common_components/DashboardComponent";
import { LessonCreationMenu } from "./components/AIPromptSectionComponent/LessonCreationMenu";
// import FeaturedLessonsComponent from "./components/FeaturedLessonsComponent";
// import { useUserApiClient } from "../../hooks/useUserApiClient";

export default function Menu(): JSX.Element {
  // const { signedInUser } = useUserApiClient();
  return (
    <DashboardComponent
    // headerText={
    //   signedInUser?.firstName
    //     ? `Welcome back, ${signedInUser?.firstName} 👋🏾`
    //     : ""
    // }
    >
      <LessonCreationMenu />
    </DashboardComponent>
  );
}
