import React from "react";
import styles from "./header.module.css";

export default function Header(): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.header}></div>
    </div>
  );
}
