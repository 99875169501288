import React from "react";

interface SavedLessonContextData {
  savedLessons: Set<string>;
  setSavedLessons: (savedLessons: Set<string>) => void;
}

export const SavedLessonContext = React.createContext<
  SavedLessonContextData | undefined
>(undefined);

interface SavedLessonProviderProps {
  savedLessons: Set<string>;
  setSavedLessons: (savedLessons: Set<string>) => void;
  children: React.ReactNode;
}

export function SavedLessonProvider({
  children,
  savedLessons,
  setSavedLessons,
}: SavedLessonProviderProps): React.ReactNode {
  return (
    <SavedLessonContext.Provider value={{ savedLessons, setSavedLessons }}>
      {children}
    </SavedLessonContext.Provider>
  );
}

export function useSavedLessonIds(): SavedLessonContextData {
  const { savedLessons, setSavedLessons } = React.useContext(
    SavedLessonContext,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) ?? { savedLessons: new Set(), setSavedLessons: () => {} };
  return { savedLessons, setSavedLessons };
}
