import React, { HTMLAttributes, useCallback, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TextFieldEditor.css";
import styles from "./SectionEdit.module.css";
import { ILessonPracticeQuestion } from "../../../../types/lesson";

interface IRichTextEditorProps {
  questions: ILessonPracticeQuestion[];
  setQuestions: (questions: ILessonPracticeQuestion[]) => void;
}

function PracticeQuestionsEditor({
  questions,
  setQuestions,
}: HTMLAttributes<HTMLDivElement> & IRichTextEditorProps): React.JSX.Element {
  const initialEditorValue = useMemo(() => {
    let questionsHTML = "";
    for (const question of questions) {
      questionsHTML += `<li>${question.text}</li>`;
    }
    return questionsHTML === ""
      ? "<ul><li></li></ul>"
      : `<ul>${questionsHTML}</ul>`;
  }, []);

  const [editorValue, setEditorValue] = useState(initialEditorValue);
  const handleEditorChange = useCallback((text: string) => {
    const newQuestions: ILessonPracticeQuestion[] = text
      .split("\n")
      .filter((question) => question !== "")
      .map((question, index) => ({
        text: question,
        order: index,
      }));
    setQuestions(newQuestions);
  }, []);

  return (
    <div className={styles.practiceQuestions}>
      <h2>Practice Questions</h2>
      <div className="textFieldEditor">
        <ReactQuill
          modules={{
            toolbar: [["bold", "italic", "underline", "link"]],
          }}
          theme="snow"
          value={editorValue}
          onChange={(value, _delta, source, editor) => {
            if (source !== "api" && value !== null) {
              setEditorValue(value);
              handleEditorChange(editor.getText());
            }
          }}
        />
      </div>
    </div>
  );
}

export default PracticeQuestionsEditor;
