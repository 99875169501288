import React, { useEffect, useRef, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWandMagicSparkles,
  faArrowUpLong,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./AIClarificationPopover.module.css";

interface AIClarificationPopoverProps {
  isShowingPopover: boolean;
  setIsShowingPopover: (isShowing: boolean) => void;
  popoverPosition: { top: number; left: number };
  onSubmit: (question: string) => Promise<void>;
}

export const AIClarificationPopover: React.FC<AIClarificationPopoverProps> = ({
  isShowingPopover,
  setIsShowingPopover,
  popoverPosition,
  onSubmit,
}) => {
  const [question, setQuestion] = useState<string>("");
  const popoverRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleClarifyRequest = useCallback(async () => {
    setIsLoading(true);
    await onSubmit(question.trim() ? question : "Clarify");
    setIsLoading(false);
    setQuestion(""); // Clear the question input after submission
  }, [question, onSubmit]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsShowingPopover(false);
      }
    },
    [setIsShowingPopover, popoverRef]
  );

  useEffect(() => {
    if (isShowingPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShowingPopover, handleClickOutside]);

  if (!isShowingPopover) {
    return null;
  }

  return (
    <div
      ref={popoverRef}
      className={styles.popover}
      style={{
        top: popoverPosition.top,
        left: popoverPosition.left,
        width: "30%",
      }}
    >
      {isLoading ? (
        <div className={styles.loading}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        <div className={styles.popItems}>
          <div className={styles.question}>
            <input
              className={styles.questionInput}
              type="text"
              name="question"
              placeholder="Ask ChamAI specific questions to clarify this phrase"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && question.trim()) {
                  void handleClarifyRequest();
                }
              }}
            />
            <button
              className={
                question.trim() ? styles.arrowBtn : styles.arrowBtnDisabled
              }
              onClick={() => {
                void handleClarifyRequest();
              }}
              disabled={!question.trim()}
            >
              <FontAwesomeIcon
                className={styles.arrowIcn}
                icon={faArrowUpLong}
              />
            </button>
          </div>
          <div className={styles.menuBtns}>
            <button
              className={styles.button}
              onClick={() => {
                void handleClarifyRequest();
              }}
            >
              <FontAwesomeIcon
                className={styles.clarifyIcon}
                icon={faWandMagicSparkles}
              />{" "}
              Ask ChamAI to clarify this
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIClarificationPopover;
