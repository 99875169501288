import React from "react";

interface PromptOutlineProviderData {
  outline?: string[];
  setOutline?: (outline: string[]) => void;
  prompt?: string;
  setPrompt?: (prompt: string) => void;
}

export const PromptOutlineContext = React.createContext<
PromptOutlineProviderData
>({});

interface PromptOutlineProviderProps extends PromptOutlineProviderData{
  children: JSX.Element,
}

export function PromptOutlineProvider ({
  outline,
  setOutline,
  prompt,
  setPrompt,
  children
}: PromptOutlineProviderProps): React.ReactNode {
  return (
    <PromptOutlineContext.Provider value={{ outline, setOutline, prompt, setPrompt }}>
      {children}
    </PromptOutlineContext.Provider>
  );
}

export function usePromptOutlineProvider(): PromptOutlineProviderData {
  const { outline, setOutline, prompt, setPrompt } = React.useContext(
    PromptOutlineContext,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  );
  return { prompt, setPrompt, outline, setOutline };
}
