import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  // Line,
} from "recharts";
import ChamPieChart from "./ChamPieChart";
import styles from "./Analytics.module.css";
import DashboardComponent from "../common_components/DashboardComponent";
// import useLessonApiClient from "../../hooks/useLessonApiClient";
// import { useUserApiClient } from "../../hooks/useUserApiClient";

export default function Analytics(): JSX.Element {
  // const { signedInUser } = useUserApiClient();
  // const {fetchLessonsByAuthorId } = useLessonApiClient()
  const [stats] = useState([
    {
      id: "lessonViews",
      statName: "Lesson Views",
      icon: <i className="fas fa-book"></i>,
      topMetric: "103",
      timeseriesData: [
        { name: "Introduction to Python", Views: 41 },
        { name: "Introduction to Haskell", Views: 19 },
        {
          name: "Machine Learning for Beginners",
          Views: 40,
        },
      ],
      piechartData: [
        { name: "Introduction to Python", value: 41 },
        { name: "Introduction to Haskell", value: 19 },
        { name: "Machine Learning for Beginners", value: 43 },
      ],
    },
    {
      id: "lessonCompletions",
      statName: "Lessons Completed",
      icon: <i className="fas fa-check"></i>,
      topMetric: "37",
      timeseriesData: [
        { name: "Introduction to Python", Views: 4 },
        { name: "Web Development 101", Views: 23 },
        {
          name: "Machine Learning for Beginners",
          Views: 10,
        },
      ],
      piechartData: [
        { name: "Introduction to Python", value: 4 },
        { name: "Web Development 101", value: 23 },
        {
          name: "Machine Learning for Beginners",
          value: 10,
        },
      ],
    },
  ]);
  const [statSelected, setStatSelected] = useState(stats[0]);

  return (
    <DashboardComponent>
      <div className={styles.container}>
        <div className={styles.gridTitle}>Analytics</div>
        <div className={styles.toggleButtonContainer}>
          {stats.map((stat) => (
            <button
              key={stat.id}
              className={`${styles.graphToggleButton} ${
                stat === statSelected ? styles.activeGraphToggleButton : ""
              }`}
              onClick={() => setStatSelected(stat)}
            >
              <div className={styles.toggleIcon}>{stat.icon}</div>
              <div className={styles.toggleText}>
                <div className={styles.toggleTextTopMetric}>
                  {stat.topMetric}
                </div>
                <div>{stat.statName}</div>
              </div>
              <div className={styles.rightIcon}>
                <i className="fas fa-chevron-right"></i>
              </div>
            </button>
          ))}
        </div>
        <div className={styles.analyticsCards}>
          <div className={styles.analyticsColumnMajor}>
            <div
              className={`${styles.analyticsCard} ${styles.analyticsTimeSeries}`}
            >
              <div className={styles.chartTitle}>
                <i className="fas fa-chart-line"></i>&nbsp;&nbsp;&nbsp;
                {statSelected.statName}
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={statSelected.timeseriesData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={20}
                >
                  <XAxis
                    dataKey="name"
                    scale="point"
                    padding={{ left: 10, right: 10 }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Bar
                    dataKey="Views"
                    fill="hsl(147, 52%, 46%)"
                    background={{ fill: "#eee" }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className={styles.analyticsColumn}>
            <div
              className={`${styles.analyticsCard} ${
                statSelected.piechartData.length > 0
                  ? styles.analyticsPiechart
                  : ""
              }`}
              style={{ height: 500, marginBottom: 30 }}
            >
              <div className={styles.chartTitle}>
                <i className="fas fa-chart-pie"></i>&nbsp;&nbsp;&nbsp;Breakdown
              </div>
              {statSelected.piechartData.length > 0 ? (
                <ChamPieChart
                  data={statSelected.piechartData}
                  dataKey="value"
                  nameKey="name"
                />
              ) : (
                <div className={styles.warning}>
                  <i className="fas fa-exclamation-triangle"></i>
                  <div>More Data Needed</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardComponent>
  );
}
