import React from "react";
import styles from "./ModalOverlayComponent.module.css";

interface ModalOverlayComponentProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function ModalOverlayComponent({
  children,
  className,
  onClick,
}: ModalOverlayComponentProps): JSX.Element {
  return (
    <div
      className={`${styles.container} ${className ?? ""}`}
      onClick={() => onClick?.()}
    >
      {children}
    </div>
  );
}
