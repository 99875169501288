import {
  faBullhorn,
  faRightToBracket,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import { FEEDBACK_URL } from "../FeedbackButton";
import styles from "./SidebarComponent.module.css";

export default function SideBarComponent(): JSX.Element {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button
          onClick={() => {
            history.push("/menu");
          }}
          className={styles.button}
        >
          <FontAwesomeIcon
            icon={faRightToBracket}
            className={styles.buttonIcon}
          />
          <div className={styles.buttonText}>Login</div>
        </button>
        <button
          onClick={() => {
            history.push("/lesson-creation");
          }}
          className={styles.button}
        >
          <FontAwesomeIcon icon={faUserPlus} className={styles.buttonIcon} />
          <div className={styles.buttonText}>Sign up</div>
        </button>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.button}
          onClick={() => {
            window.open(FEEDBACK_URL, "_blank");
          }}
        >
          <FontAwesomeIcon
            icon={faBullhorn}
            className={styles.buttonIcon}
            flip="horizontal"
          />
          <div className={styles.buttonText}>Feedback</div>
        </button>
      </div>
    </div>
  );
}
