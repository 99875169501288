import { apiDomain } from "../../../../../config";

export const getUserSessionForLesson = async (
  lessonId: string,
): Promise<string | undefined> => {
  const response = await fetch(
    `${apiDomain}/session-service/session/getUserSessionIfExists/${lessonId}`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return await response.json().then((data) => data.sessionId ?? undefined);
};
