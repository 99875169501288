import { gql } from "./__generated__/gql";

export const CREATE_LESSON_MUTATION = gql(`
  mutation CreateLesson($data: CreateLessonInput!) {
    createLesson(data: $data) {
      data {
        id
      }
      error {
        message
      }
    }
  }
`);

export const UPDATE_LESSON_MUTATION = gql(`
  mutation UpdateLesson($updateLessonId: ID!, $data: UpdateLessonInput!) {
    updateLesson(id: $updateLessonId, data: $data) {
      data {
        id
        authorId
        title
        imageUrl
        pages {
          id
        }
        createdDate
        modifiedDate
      }
      error {
        message
      }
    }
  }
`);

export const FETCH_LESSON_QUERY = gql(`
query FetchLesson($lessonId: ID!) {
  lesson(id: $lessonId) {
    data {
      id
      authorId
      title
      imageUrl
      duration
      pages {
        id
        lessonId
        title
        pageOrder
        sections {
          id
          pageId
          title
          sectionOrder
          clarification {
            id
            summary
          }
          content {
            video {
              videoLink
              dimensions {
                width
                height
              }
            }
            codeBlock {
              codeBlock
              codeLanguage
            }
            textField {
              text
            }
            practiceQuestions {
              questions {
                questionOrder
                questionText
              }
            }
            markdown {
              content
            }
          }
        }
      }
      createdDate
      modifiedDate
    }
    error {
      message
    }
  }
}
`);

export const FETCH_LESSONS_BY_AUTHOR_ID = gql(`
  query FetchLessonsByAuthorId($authorId: ID!) {
    lessonsByAuthorId(authorId: $authorId) {
      data {
        id
        authorId
        title
        imageUrl
        duration
        createdDate
        modifiedDate
      }
      error {
        message
      }
    }
  }
`);

export const FETCH_PAGINATED_LESSSONS_BY_AUTHOR_ID = gql(`
  query LessonsByAuthorId($authorId: ID!, $page: Int!, $size: Int!) {
    paginatedLessonsByAuthorId(authorId: $authorId, page: $page, size: $size) {
      data {
        content {
          id
          title
          authorId
          createdDate
          modifiedDate
        }
        totalPages
        totalElements
        hasNext
      }
      error {
        message
      }
    }
  }
`);

export const FETCH_FEATURED_LESSONS_QUERY = gql(`
  query FetchFeaturedLessons {
    featuredLessons {
      error {
        message
      }
      data {
        id
        authorId
        title
        imageUrl
        duration
        pages {
          id
        }
        createdDate
        modifiedDate
      }
    }
  }
`);

export const GENERATE_SECTION_CONTENT_FOR_LESSON_MUTATION = gql(`
  mutation GenerateSectionContentForLesson($question: String!) {
    generateSectionContentForLesson(question: $question) {
        data {
          lessonId
        }
        error {
            message
        }
    }
}`);

export const GENERATE_NEXT_SECTION_IN_LESSON_MUTATION = gql(`
  mutation GenerateNextSectionForLesson($lessonId: String!, $pageId: String!, $prompt: String) {
    generateNextSection(lessonId: $lessonId, pageId: $pageId, prompt: $prompt) {
      data
      error {
          message
      }
  }
}`);

export const REGENERATE_SECTION_IN_LESSON_MUTATION = gql(`
  mutation RegenerateSectionInLesson($lessonId: String!, $pageId: String!, $sectionId: String!, $clarificationId: String!) {
    regenerateSectionWithClarification(lessonId: $lessonId, pageId: $pageId, sectionId: $sectionId, clarificationId: $clarificationId) {
      data
      error {
          message
      }
  }
}`);

export const REGENERATE_SECTION_IN_LESSON_FROM_QUESTION_MUTATION = gql(`
  mutation RegenerateSection($lessonId: String!, $pageId: String!, $sectionOrder: Int!, $question: String!) {
    regenerateSection(
        lessonId: $lessonId
        pageId: $pageId
        sectionOrder: $sectionOrder
        question: $question
    ) {
        data
        error {
          message
      }
    }
}`);

export const SUMMARIZE_CLARIFICATION_MUTATION = gql(`
  mutation SummarizeSectionClarification($clarificationId: String!, $pageId: String!, $sectionId: String!) {
    summarizeSectionClarification(clarificationId: $clarificationId, pageId: $pageId, sectionId: $sectionId) {
      data
      error {
          message
      }
  }
}`);

export const CLARIFY_TEXT_MUTATION = gql(`
  mutation ClarifyText($text: String!, $lessonId: String!, $question: String, $clarificationId:String, $clarificationType:ClarificationType, $sectionId: String) {
    clarifyText(text: $text, lessonId: $lessonId, question: $question, clarificationId: $clarificationId, clarificationType: $clarificationType, sectionId: $sectionId) {
      data {
        clarificationId
      }
      error {
        message
      }
    }
}`);

export const CLARIFY_SECTION_MUTATION = gql(`
  mutation CreateSectionClarification($lessonId: String!, $sectionId: String!, $pageId: String!, $question: String!, $text: String) {
    createSectionClarification(sectionId: $sectionId, lessonId: $lessonId, pageId: $pageId, question: $question, text: $text) {
      data
      error {
        message
      }
    }
}`);

export const GET_USER_CLARIFICATIONS_FOR_LESSON_QUERY = gql(`
  query clarifications($lessonId: String!) {
    clarifications(lessonId: $lessonId) {
      data {
        clarifications {
          clarificationId
          phrase
          clarificationQuestion
          lessonId
          sectionId
          clarificationType
        }
      }
      error {
        message
      }
    }
  }
`);

export const GET_CLARIFICATION_QUERY = gql(`
  query clarification($clarificationId: String!) {
    clarification(clarificationId: $clarificationId) {
      data {
          clarificationId
          phrase
          clarificationQuestion
          lessonId
          sectionId
          clarificationType
          messages {
            role
            content
        }
      }
      error {
        message
      }
    }
  }
`);

export const GET_CLARIFICATION_SUBSCRIPTION = gql(`
  subscription ClarificationSubscription($clarificationId: String!) {
    clarification(clarificationId: $clarificationId) {
      error {
        message
      }
      data {
        clarificationId
        phrase
        clarificationQuestion
        lessonId
        userId
        messages {
          role
          content
        }
      }
    }
  }
`);

export const CREATE_PAGE_MUTATION = gql(`
  mutation CreatePage($data: CreatePageInput!) {
    createPage(data: $data) {
      error {
        message
      }
      data {
        id
      }
    }
  }
`);

export const UPDATE_PAGE_MUTATION = gql(`
  mutation UpdatePage($updatePageId: ID!, $data: UpdatePageInput!) {
    updatePage(id: $updatePageId, data: $data) {
      error {
        message
      }
      data {
        id
      }
    }
  }
`);

export const UPDATE_USER_PROFILE_MUTATION = gql(`
  mutation UpdateUserProfile(
    $firstName: String
    $lastName: String
    $userName: String
    $profilePictureUrl: String
  ) {
    updateUserProfile(
      firstName: $firstName
      lastName: $lastName
      userName: $userName
      profilePictureUrl: $profilePictureUrl
    ) {
      user {
        id
        email
        firstName
        lastName
        userName
        hasCompletedOnboarding
        profilePictureUrl
      }
      error {
        message
      }
    }
  }
`);

export const CREATE_CUSTOMER_MUTATION = gql(`
  mutation CreateCustomer {
    createCustomer {
        response {
            userId
            customerId
        }
        error {
            message
        }
    }
}
`);

export const CREATE_CHECKOUT_SESSION_MUTATION = gql(`
  mutation CreateCheckoutSession {
    createCheckoutSession {
        checkoutSessionId
        clientSecret
        error {
            message
        }
    }
  }
`);

export const CANCEL_SUBSCRIPTION_MUTATION = gql(`
  mutation CancelSubscription {
    cancelSubscription {
        success
        error {
            message
        }
    }
}

`);

export const CREATE_CUSTOMER_PORTAL_SESSION_MUTATION = gql(`
  mutation CreateCustomerPortalSession {
    createCustomerPortalSession
  }
`);

export const GET_CHECKOUT_SESSION = gql(`
  query GetCheckoutSession($sessionId: String!) {
    getCheckoutSession(sessionId: $sessionId) {
        checkoutSessionId
        checkoutSessionStatus
        error {
          message
        }
    }
}
`);

export const GET_USER_SUBSCRIPTION_STATUS = gql(`
  query GetUserSubscriptionStatus {
    getUserSubscriptionStatus {
        startDate
        endDate
        cancellationDate
        status
        error {
          message
        }
    }
}
`);

export const CREATE_SECTION_MUTATION = gql(`
  mutation CreateSection($data: CreateSectionInput!) {
    createSection(data: $data) {
      error {
        message
      }
      data {
        id
      }
    }
  }
`);

export const UPDATE_SECTION_MUTATION = gql(`
  mutation UpdateSection($updateSectionId: ID!, $data: UpdateSectionInput!) {
    updateSection(id: $updateSectionId, data: $data) {
      data {
        id
      }
      error {
        message
      }
    }
  }
`);

export const DELETE_SECTION_MUTATION = gql(`
  mutation DeleteSection($deleteSectionId: ID!) {
    deleteSection(id: $deleteSectionId) {
      data {
        id
      }
      error {
        message
      }
    }
  }
`);

export const DELETE_LESSON_MUTATION = gql(`
  mutation DeleteLesson($deleteLessonId: ID!) {
    deleteLesson(id: $deleteLessonId) {
      data {
        id
      }
      error {
        message
      }
    }
  }
`);

export const SIGN_IN_MUTATION = gql(`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      isLoggedIn
      error {
        message
      }
    }
  }
`);

export const SIGN_OUT_MUTATION = gql(`
  mutation SignOut {
    signOut
  }
`);

export const CREATE_USER_MUTATION = gql(`
mutation signUp($email: String!, $password: String!) {
    signUp(email: $email, password: $password) {
      user {
        id
        email
        userName
      }
      error {
        message
      }
    }
  }
  
`);

export const FETCH_USER_QUERY = gql(`
  query FetchUser($getUserByIdId: ID!) {
    getUserByID(id: $getUserByIdId) {
      user {
        id
        email
        firstName
        lastName
        hasCompletedOnboarding
        userName
        profilePictureUrl
      }
      error {
        message
      }
    }
  }
`);

export const FETCH_SIGNED_IN_USER_QUERY = gql(`
  query FetchSignedInUser {
    getSignedInUser {
      user {
        id
        email
        firstName
        lastName
        hasCompletedOnboarding
        userName
        profilePictureUrl
      }
      error {
        message
      }
    }
  }
`);

export const FETCH_USER_PAGES_COMPLETED_QUERY = gql(`
  query FetchUserPagesCompletedQuery($lessonId: String!) {
    getLessonPagesCompletedForUser(lessonId: $lessonId) {
      pagesCompleted 
    }
  }
`);

export const SAVE_USER_PAGE_COMPLETED_MUTATION = gql(`
  mutation SaveUserPageAsCompleted($lessonId: String!, $pageId: String!) {
    addLessonsPageCompleted(lessonId: $lessonId, pageId: $pageId) {
      success,
      error {
        message
      } 
    }
  }
`);

export const REMOVE_USER_PAGE_COMPLETED_MUTATION = gql(`
  mutation RemoveUserPageFromCompleted($lessonId: String!, $pageId: String!) {
    removeLessonsPageCompleted(lessonId: $lessonId, pageId: $pageId) {
      success,
      error {
        message
      } 
    }
  }
`);

export const FETCH_USER_BOOKMARKED_LESSONS_QUERY = gql(`
  query FetchUserBookmarkedLessons {
    getBookmarkedLessons {
      bookmarkedLessons 
    }
  }
`);

export const SAVE_USER_BOOKMARKED_LESSON_MUTATION = gql(`
  mutation SaveUserBookmarkedLesson($lessonId: String!) {
    bookmarkLesson(lessonId: $lessonId) {
      success,
      error {
        message
      } 
    }
  }
`);

export const REMOVE_USER_BOOKMARKED_LESSON_MUTATION = gql(`
  mutation RemoveUserBookmarkedLesson($lessonId: String!) {
    removeBookmarkedLesson(lessonId: $lessonId) {
      success,
      error {
        message
      } 
    }
  }
`);

export const FETCH_ENROLLED_USERS_QUERY = gql(`
  query FetchEnrolledUsers($lessonId: String!) {
    getEnrolledUsers(lessonId: $lessonId) {
      enrolledUserIds 
    }
  }
`);

export const FETCH_USER_ENROLLED_LESSONS_QUERY = gql(`
  query FetchUserEnrolledLessons {
    getEnrolledLessons {
      enrolledLessons 
    }
  }
`);

export const ADD_USER_ENROLLED_LESSON_MUTATION = gql(`
  mutation AddUserEnrolledLesson($lessonId: String!) {
    enrollLesson(lessonId: $lessonId) {
      success,
      error {
        message
      } 
    }
  }
`);

export const REMOVE_USER_ENROLLED_LESSON_MUTATION = gql(`
  mutation RemoveUserEnrolledLesson($lessonId: String!) {
    removeEnrolledLesson(lessonId: $lessonId) {
      success,
      error {
        message
      } 
    }
  }
`);

export const REQUEST_PASSWORD_RESET_MUTATION = gql(`
  mutation RequestPasswordReset($email: String) {
    requestPasswordReset(email: $email) {
      error {
        message
      }
    }
  }
`);

export const RESET_PASSWORD_MUTATION = gql(`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      error {
        message
      }
    }
  }
`);

export const LIVE_LESSON_SUBSCRIPTION = gql(`
  subscription LessonSubscription($lessonId: ID!) {
    lesson(id: $lessonId) {
      data {
        id,
        title,
        authorId,
        createdDate,
        pages {
          title,
          id,
          pageOrder,
          sections {
            title
            id
            sectionOrder,
            clarification {
              id
              summary
            }
            content {
              codeBlock {
                codeBlock
              },
              textField {
                text
              },
              video {
                videoLink,
                dimensions {
                  width
                  height
                }
              },
              practiceQuestions {
                questions {
                  questionText
                  questionOrder
                }
              },
              markdown {
                content
              }
            }
          }
        }
        createdDate
        modifiedDate
      }
      error {
        message
      }
      endOfStream
    }
  }
`);

export const SEARCH_LESSONS_QUERY = gql(`
  query SearchLessons($searchQuery: String, $filter: LessonFilter) {
    searchLessons(searchQuery: $searchQuery, filter: $filter) {
      error {
        message
      }
      data
    }
  }
`);
