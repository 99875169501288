import mobile1 from "./defaultAssets/defaultLesson.png";
import mobile2 from "./defaultAssets/defaultLessonCyan.png";
import mobile3 from "./defaultAssets/defaultLessonGold.png";
import mobile4 from "./defaultAssets/defaultLessonGreen.png";

import desktop1 from "./defaultAssets/defaultLessonDesktopCyan.png";
import desktop2 from "./defaultAssets/defaultLessonDesktopGold.png";
import desktop3 from "./defaultAssets/defaultLessonDesktopGreen.png";
import desktop4 from "./defaultAssets/defaultLessonDesktopPurple.png";

export const selectRandomLessonImageMobile = (titleHash: number): string => {
  const array = [mobile1, mobile2, mobile3, mobile4];
  return array[titleHash % 4];
};

export const selectRandomLessonImageDesktop = (titleHash: number): string => {
  const array = [desktop1, desktop2, desktop3, desktop4];
  return array[titleHash % 4];
};

export const hashString = (str: string): number => {
  // set variable hash as 0
  let hash = 0;
  // if the length of the string is 0, return 0
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const ch = str.charCodeAt(i);
    hash = (hash << 5) - hash + ch;
    hash = hash & hash;
  }
  return hash >>> 0;
};

export const calculateTimeAgo = (createdDate: string): string => {
  const now = new Date();
  const date = new Date(createdDate).getTime();
  const secondsAgo = Math.round((now.getTime() - date) / 1000);
  const minutesAgo = Math.round(secondsAgo / 60);
  const hoursAgo = Math.round(minutesAgo / 60);
  const daysAgo = Math.round(hoursAgo / 24);
  const monthsAgo = Math.round(daysAgo / 30);
  const yearsAgo = Math.round(monthsAgo / 12);

  if (secondsAgo < 60) {
    return `${secondsAgo}s ago`;
  } else if (minutesAgo < 60) {
    return `${minutesAgo}min ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo}h ago`;
  } else if (daysAgo < 30) {
    return `${daysAgo}d ago`;
  } else if (monthsAgo < 12) {
    return `${monthsAgo}mon ago`;
  } else {
    return `${yearsAgo}y ago`;
  }
};
