import React from "react";
import styles from "./LessonSectionPreview.module.css";
import { ILessonPracticeQuestionsContent } from "../../../types/lesson";

interface IPracticeQuestionsPreviewProps {
  content: ILessonPracticeQuestionsContent;
}

function PracticeQuestionsPreview({
  content,
}: IPracticeQuestionsPreviewProps): React.JSX.Element {
  content.questions.sort((a, b) => a.order - b.order);
  return (
    <>
      <div className={styles.practiceQuestionsPreview}>
        <h2 className={styles.practiceQuestionsHeading}>Practice Questions</h2>
        <ul className={styles.practiceQuestions}>
          {content.questions.map((question, index) => (
            <li className={styles.practiceQuestion} key={index}>
              {question.text}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default PracticeQuestionsPreview;
