import React from "react";
import { Redirect } from "react-router-dom";
import styles from "./CheckLoginComponent.module.css";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

interface CheckLoginComponentProps {
  children: React.ReactNode;
}

export default function CheckLoginComponent({
  children,
}: CheckLoginComponentProps): React.JSX.Element {
  const { signedInUser, loading } = useUserApiClient();
  return (
    <div className={styles.container}>
      {!loading && !signedInUser ? <Redirect to="/login" /> : children}
    </div>
  );
}
