import React from "react";
import { ILesson } from "../../../types/lesson";
import { LessonCardVertical } from "../LessonCardVertical";
import { LessonCardHorizontal } from "../LessonCardHorizontal";
import styles from "./LessonCard.module.css";
interface LessonCardProps {
  lesson: ILesson;
  clickUrl: string;
  showLessonEditModal?: (lessonId: string) => void;
  canBeDeleted?: boolean;
}

const LessonCard: React.FC<LessonCardProps> = ({
  lesson,
  clickUrl,
  showLessonEditModal,
  canBeDeleted = false,
}: LessonCardProps) => {
  return (
    <>
      <div className={styles.desktop}>
        <LessonCardVertical
          lesson={lesson}
          clickUrl={clickUrl}
          showLessonEditModal={showLessonEditModal}
          canBeDeleted={canBeDeleted}
        />
      </div>
      <div className={styles.mobile}>
        <LessonCardHorizontal
          lesson={lesson}
          clickUrl={clickUrl}
          showLessonEditModal={showLessonEditModal}
          canBeDeleted={canBeDeleted}
        />
      </div>
    </>
  );
};

export default LessonCard;
