import { NodeModel } from "@minoru/react-dnd-treeview";
import { TreeNodeDataType } from "../../utils";

import { apiDomain } from "../../../../../config";

export const createSessionDirectory = async (
  node: NodeModel<TreeNodeDataType>,
  relativeParentPath: string,
  name: string,
  sessionId: string,
): Promise<boolean> => {
  let response;
  if (node.droppable) {
    response = await fetch(
      `${apiDomain}/session-service/session/createFolder`,
      {
        method: "POST",
        body: JSON.stringify({
          sessionId,
          relativeParentPath,
          folderName: name,
        }),
        credentials: "include",
        headers: {
          "Content-type": "application/json",
        },
      },
    );
  } else {
    response = await fetch(`${apiDomain}/session-service/session/createFile`, {
      method: "POST",
      body: JSON.stringify({
        sessionId,
        relativeParentPath,
        file: {
          filename: name,
          contentType: 1,
          content: "",
        },
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    });
  }
  const createdFile = await response.json().then((data) => data.success);
  return createdFile;
};
