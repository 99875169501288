import React from "react";
import {
  faCode,
  faFont,
  faQ,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SectionEdit.module.css";
import { ContentTypes } from "../../../../types/lesson";

interface IContentTypeIconsProps {
  activeContentType?: ContentTypes;
  updateContentType: (contentType: ContentTypes) => void;
}

function ContentTypeIcons({
  activeContentType,
  updateContentType,
}: IContentTypeIconsProps): React.JSX.Element {
  const contentTypeIconMaps = [
    { contentType: ContentTypes.Video, icon: faUpload },
    { contentType: ContentTypes.TextField, icon: faFont },
    {
      contentType: ContentTypes.PracticeQuestions,
      icon: faQ,
    },
    { contentType: ContentTypes.CodeBlock, icon: faCode },
  ];

  return (
    <div className={`${styles.sectionTypeIcons}`}>
      {contentTypeIconMaps.map((contentTypeIconMap, index) => (
        <button
          key={index}
          className={`${styles.icon} ${
            contentTypeIconMap.contentType === activeContentType
              ? styles.activeIcon
              : ""
          }`}
          onClick={() => updateContentType(contentTypeIconMap.contentType)}
        >
          <FontAwesomeIcon icon={contentTypeIconMap.icon} />
        </button>
      ))}
    </div>
  );
}

export default ContentTypeIcons;
