import React, { useEffect, useMemo } from "react";

import styles from "./Sidebar.module.css";
import { WorkspaceSideBar } from "../../ChamIDE/components/DirectoryTree/WorkspaceSidebar";
import { useWorkspaceHighlight } from "../../ChamIDE/contexts/WorkspaceHighlightContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faMessage,
  faNewspaper,
} from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "@mui/material";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { ClarificationsView } from "../../../Lesson/components/Clarifications";
import { ViewState } from "../types";
import { faCode, faGlobe, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { useViewContext } from "../../ChamIDE/contexts/ViewContext/ViewContext";

interface SideBarProps {
  setError: (error: string) => void;
  width: number;
  setActualWidth?: (width: number) => void;
  actualWidth?: number;
}

export const SideBar = ({
  setError,
  width,
  setActualWidth,
  actualWidth,
}: SideBarProps): JSX.Element => {
  const { currentView, setCurrentView } = useViewContext();

  const isSidebarOpen = useMemo(
    () =>
      currentView === ViewState.File ||
      currentView === ViewState.Clarifications,
    [currentView]
  );

  const { isWorkspaceHighlighted } = useWorkspaceHighlight();

  useEffect(() => {
    let newActualWidth =
      window.screen.width > 1025 ? width : window.screen.width;
    newActualWidth = isSidebarOpen ? newActualWidth : 53;
    setActualWidth?.(newActualWidth);
  }, [isSidebarOpen, setActualWidth, width]);

  const tabs = [
    {
      icon: faNewspaper,
      onClick: () => setCurrentView(ViewState.Lesson),
      active: currentView === ViewState.Lesson,
      tooltip: "Lesson",
    },
    {
      icon: faFolder,
      onClick: () => setCurrentView(ViewState.File),
      active: currentView === ViewState.File,
      tooltip: "Files",
    },
    {
      icon: faCode,
      onClick: () => setCurrentView(ViewState.IDE),
      active: currentView === ViewState.IDE,
      tooltip: "IDE",
      mobileOnly: true,
    },
    {
      icon: faTerminal,
      onClick: () => setCurrentView(ViewState.Terminal),
      active: currentView === ViewState.Terminal,
      tooltip: "Terminal",
      mobileOnly: true,
    },
    {
      icon: faGlobe,
      onClick: () => setCurrentView(ViewState.Browser),
      active: currentView === ViewState.Browser,
      tooltip: "Browser",
      mobileOnly: true,
    },
    {
      icon: faMessage,
      onClick: () => setCurrentView(ViewState.Clarifications),
      active: currentView === ViewState.Clarifications,
      tooltip: "Clarifications",
    },
    {
      icon: faGithub,
      onClick: () => setCurrentView(ViewState.Github),
      active: currentView === ViewState.Github,
      tooltip: "Github",
    },
  ];

  return (
    <div
      className={styles.sideBarContainer}
      style={{
        width: actualWidth,
      }}
    >
      <div className={styles.sideTab}>
        {tabs.map((tab, index) => (
          <Tooltip
            hidden={tab.mobileOnly && screen.width > 1025}
            key={index}
            title={tab.tooltip}
          >
            <div
              className={tab.active ? styles.activeTab : styles.tab}
              onClick={tab.onClick}
            >
              <FontAwesomeIcon icon={tab.icon} />
            </div>
          </Tooltip>
        ))}
      </div>
      {isSidebarOpen && (
        <div
          className={`${styles.sideBar} ${isWorkspaceHighlighted ? styles.isWorkspaceHighlighted : ""}`}
        >
          {currentView === ViewState.File && (
            <WorkspaceSideBar setError={setError} />
          )}
          {(currentView === ViewState.Clarifications ||
            (currentView === ViewState.ClarificationChat &&
              screen.width > 1025)) && <ClarificationsView />}
        </div>
      )}
    </div>
  );
};
