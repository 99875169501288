import React from "react";
import styles from "./SectionPreview.module.css";
import { ContentTypes, ILessonPageSection } from "../../../../types/lesson";
import TextFieldPreview from "../../../common_components/LessonSectionPreview/TextFieldPreview";
import VideoPreview from "../../../common_components/LessonSectionPreview/VideoPreview";
import CodeBlockPreview from "../../../common_components/LessonSectionPreview/CodeBlockPreview";
import PracticeQuestionsPreview from "../../../common_components/LessonSectionPreview/PracticeQuestionsPreview";
import SectionPreviewIcons from "./SectionPreviewIcons";

interface ISectionPreviewProps {
  section: ILessonPageSection;
  hidePreview: () => void;
  deleteSection: () => void;
}

function SectionPreview({
  section,
  hidePreview,
  deleteSection,
}: ISectionPreviewProps): React.JSX.Element {
  return (
    <div className={styles.sectionPreviewWrapper}>
      {section.contentType === ContentTypes.TextField &&
        section.content.textField && (
          <TextFieldPreview content={section.content.textField} />
        )}
      {section.contentType === ContentTypes.Video && section.content.video && (
        <VideoPreview content={section.content.video} />
      )}
      {section.contentType === ContentTypes.CodeBlock &&
        section.content.codeBlock && (
          <CodeBlockPreview content={section.content.codeBlock} />
        )}
      {section.contentType === ContentTypes.PracticeQuestions &&
        section.content.practiceQuestions && (
          <PracticeQuestionsPreview
            content={section.content.practiceQuestions}
          />
        )}
      <SectionPreviewIcons
        hidePreview={hidePreview}
        deleteSection={deleteSection}
      />
    </div>
  );
}

export default SectionPreview;
