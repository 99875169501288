import { Box, CircularProgress } from "@mui/material";
import React from "react";
import styles from "./ProgressBar.module.css";

interface CircularProgressProps {
  value: number;
  innerCircleComponent?: JSX.Element;
}

export const CircularProgressBarComponent: React.FC<CircularProgressProps> = ({
  value,
  innerCircleComponent,
}) => {
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progress}>
        {/* SVG DEF */}
        <svg style={{ height: 0, width: 0 }}>
          <defs>
            <linearGradient id="my_gradient">
              border: 3px solid; border-image-source: linear-gradient(180deg,
              #66CC8D 0%, #55DDDD 66.25%);
              <stop offset="0%" stopColor="#55DDDD" />
              <stop offset="66.25%" stopColor="#66CC8D" />
            </linearGradient>
          </defs>
        </svg>
        <Box sx={{ position: "relative", display: "flex" }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              position: "absolute",
            }}
            size={50}
            thickness={3}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            sx={{
              "svg circle": { stroke: "url(#my_gradient)" },
            }}
            size={50}
            thickness={3}
            value={value}
          />

          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: 600, padding: "10px" }}>
              {innerCircleComponent}
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};
