import React, { useEffect } from "react";
import styles from "./LessonContainer.module.css";
import MarkdownPreview from "../../../common_components/LessonSectionPreview/MarkdownPreview";
import { ContinueOrClarifySectionCard } from "./ContinueOrClarifySectionCard";
import { ILessonPageSection } from "../../../../types/lesson";

export interface LessonSectionProps {
  section: ILessonPageSection;
  pageId?: string;
  isLastSection: boolean;
  lessonId: string;
  isLessonLoading: boolean;
}

export const LessonSectionRender: React.FC<LessonSectionProps> = ({
  section,
  pageId,
  isLastSection,
  lessonId,
  isLessonLoading,
}) => {
  const [continueStream, setContinueStream] = React.useState<boolean>(false);
  useEffect(() => {
    if (!isLessonLoading) {
      setContinueStream(false);
    }
  }, [isLessonLoading]);

  return (
    <div className={styles.section}>
      {section.content.markdown && pageId && (
        <>
          <MarkdownPreview
            pageId={pageId}
            section={section}
            lessonId={lessonId}
          />

          {!isLessonLoading && !continueStream && isLastSection && pageId && (
            <ContinueOrClarifySectionCard
              pageId={pageId}
              section={section}
              lessonId={lessonId}
              chatExists={section.clarification.id != null}
              continueStream={continueStream}
              setContinueStream={setContinueStream}
            />
          )}
        </>
      )}
    </div>
  );
};
