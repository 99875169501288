import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IIcon {
  className?: string;
  color?: string;
  height?: string;
}
export const FolderIcon: React.FC<IIcon> = ({ className, color }) => {
  return (
    <FontAwesomeIcon icon={faFolderPlus} color={color} className={className} />
  );
};
