import React from "react";
import DashboardHeaderProvider from "../common_components/DashboardHeaderComponent/DashboardHeaderContext";
import { LessonOutline } from "./components/LessonOutline";


export default function LessonOutlineView(): React.JSX.Element {
  return (
      <DashboardHeaderProvider isTransparent={true}>
        <LessonOutline />
      </DashboardHeaderProvider>
  );
}
