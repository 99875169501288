export interface LessonManagementData {
  title: string;
  imageUrl?: string;
}

export interface ILesson {
  id: string;
  authorId: string;
  title: string;
  imageUrl?: string;
  duration?: number;
  pages: ILessonPage[];
  createdDate?: string;
  modifiedDate?: string;
}

export interface PaginatedLesson {
  lessons: ILesson[];
  totalElements: number;
  totalPages: number;
  hasNext: boolean;
}

export interface ILessonPage {
  id?: string;
  lessonId: string;
  title: string;
  order: number;
  sections: ILessonPageSection[];
}

export interface ILessonPageSection {
  id?: string;
  showPreview: boolean;
  content: ILessonPageContent;
  order: number;
  contentType: ContentTypes;
  clarification: ILessonClarification;
}

export interface ILessonClarification {
  id?: string;
  summary?: string;
}

export enum ContentTypes {
  Video = "video",
  CodeBlock = "codeBlock",
  TextField = "textField",
  PracticeQuestions = "practiceQuestions",
  Markdown = "markdown",
  Unknown = "unknown",
}

export interface ILessonPageContent {
  video?: ILessonVideoContent;
  codeBlock?: ILessonCodeBlockContent;
  textField?: ILessonTextFieldContent;
  practiceQuestions?: ILessonPracticeQuestionsContent;
  markdown?: ILessonMarkdownContent;
}

export interface ILessonVideoContent {
  videoLink: string;
  dimensions: {
    width: number;
    height: number;
  };
}

export interface ILessonCodeBlockContent {
  codeBlock: string;
  codeLanguage: string;
}

export enum CodeLanguages {
  Javascript = "javascript",
  Typescript = "typescript",
  Python = "python",
  Java = "java",
  Rust = "rust",
  C = "c",
}

export interface ILessonTextFieldContent {
  text: string;
}

export interface ILessonPracticeQuestionsContent {
  questions: ILessonPracticeQuestion[];
}

export interface ILessonPracticeQuestion {
  text: string;
  order: number;
}

export interface ILessonMarkdownContent {
  content: string;
}

export interface IPageErrorData {
  title: string;
  message: string;
}
