interface Domain {
  app: string;
  api: string;
  swarmManager: string;
}

const getDomain = (): Domain => {
  if (
    typeof window === "undefined" ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("127.0.0.1")
  ) {
    return {
      app: "https://app.staging.buildql.com",
      api: "https://api.staging.buildql.com",
      swarmManager: "https://swarm-manager-staging.hosted.buildql.com",
    };
  }

  const match = [
    ...(/app\.([A-z]*)\.buildql\.com/.exec(window.location.href) ?? []),
    ...(/app\.([A-z]*)\.learnchameleon\.com/.exec(window.location.href) ?? []),
  ];
  if (match && match.length > 1) {
    const environment = match[1];
    return {
      app: `https://app.${environment}.buildql.com`,
      api: `https://api.${environment}.buildql.com`,
      swarmManager: `https://swarm-manager-${environment}.hosted.buildql.com`,
    };
  }
  return {
    app: "https://app.buildql.com",
    api: "https://api.buildql.com",
    swarmManager: "https://swarm-manager.hosted.buildql.com",
  };
};

const {
  api: apiDomain,
  app: appDomain,
  swarmManager: swarmManagerDomain,
} = getDomain();
const services = {
  FILE_SERVICE: `${apiDomain}/file-service`,
  LESSON_GRAPHQL_API: `${apiDomain}/lesson-service/graphql`,
  LESSON_GRAPHQL_WS_API: `wss://${apiDomain.replace(
    "https://",
    "",
  )}/lesson-service/graphql`,
  SWARM_MANAGER_API: `${swarmManagerDomain}/swarm`,
  AUTH_GRAPHQL_API: `${apiDomain}/auth-service/graphql`,
  USER_SERVICE_GRAPHQL_API: `${apiDomain}/user-service/graphql`,
  AI_SERVICE_GRAPHQL_API: `${apiDomain}/ai-service-v2/graphql`,
  AI_GRAPHQL_WS_API: `wss://${apiDomain.replace(
    "https://",
    "",
  )}/ai-service-v2/graphql`,
  SEARCH_SERVICE_GRAPHQL_API: `${apiDomain}/search-service/graphql`,
};

export { apiDomain, appDomain, services };
