import React from "react";
import DashboardComponent from "../common_components/DashboardComponent";
import styles from "./Subscription.module.css";
import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";
import { useUserApiClient } from "../../hooks/useUserApiClient";
import { Status } from "../../__generated__/graphql";
import { Redirect } from "react-router-dom";

export const SubscriptionPage: React.FC = () => {
  const { getUserSubscriptionStatus } = useUserApiClient();
  const [isUserSubscribed, setIsUserSubscribed] = React.useState<boolean>();

  React.useEffect(() => {
    const loadIsSubscribed = async (): Promise<void> => {
      const { status } = await getUserSubscriptionStatus();
      if (status === Status.Active) {
        setIsUserSubscribed(true);
      } else {
        setIsUserSubscribed(false);
      }
    };

    void loadIsSubscribed();
  }, []);
  // TODO get these dynamically from stripe
  const name = "Premium plan";
  const price = "20";
  const currency = "$";
  const features = [
    "ChamIDE Access",
    "Lesson Access",
    "Unlimited Usage",
    "24/7 Support",
    "Access to all features",
  ];
  return (
    <DashboardComponent>
      {isUserSubscribed ? (
        <Redirect to={"/menu"} />
      ) : (
        <div className={styles.container}>
          <div>
            <div className={styles.gridTitle}>Subscription</div>

            <div className={styles.sectionText}>
              To access lessons and use the ChamIDE, users must subscribe.
              Select a plan and subscribe below to get started.
            </div>
          </div>

          <SubscriptionCard
            name={name}
            price={price}
            currency={currency}
            features={features}
          />
        </div>
      )}
    </DashboardComponent>
  );
};
