import { NodeModel } from "@minoru/react-dnd-treeview";
import { ROOT_DIRECTORY, TreeNodeDataType } from "../../utils";
import { apiDomain } from "../../../../../config";

export const createSession = async (
  lessonId: string,
  node?: NodeModel<TreeNodeDataType>,
  name?: string,
): Promise<string> => {
  const response = await fetch(`${apiDomain}/session-service/session/create`, {
    method: "POST",

    body: JSON.stringify(node && name ? {
      lessonId,
      directory: createInitialDirectory(name, node),
    } : {lessonId}),
    
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
  });
  const sessionIdCreated = await response.json().then((data) => data.sessionId);
  return sessionIdCreated;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createInitialDirectory = (
  name: string,
  node: NodeModel<TreeNodeDataType>,
) => {
  if (node.droppable) {
    return {
      directoryName: ROOT_DIRECTORY,
      subdirectories: [
        {
          directoryName: name,
        },
      ],
    };
  }
  return {
    directoryName: ROOT_DIRECTORY,
    files: [
      {
        filename: name,
        contentType: 1,
        content: "",
      },
    ],
  };
};
