import React, { useState } from "react";
import HeaderComponent from "../common_components/MainHeaderComponent";
import SignupFormComponent from "./SignupFormComponent";
import FooterComponent from "../common_components/FooterComponent";
import styles from "./signup.module.css";
import { SignupDetails } from "../../types/auth";
import ErrorComponent from "../common_components/ErrorComponent";
import { useUserApiClient } from "../../hooks/useUserApiClient";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";
import { useHistory } from "react-router-dom";

export default function Signup(): React.JSX.Element {
  const [signupDetails, setSignupDetails] = useState<SignupDetails>({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const { createUser, loading } = useUserApiClient();
  const history = useHistory();

  const signup = React.useCallback(() => {
    createUser(signupDetails)
      .then(() => history.push("/login"))
      .catch((error) => {
        if (error instanceof Error) {
          return setError(error.message);
        }
        return setError("Error occured while signing up");
      });
  }, [signupDetails]);

  const headerElements = (
    <>
      <span className={styles.loginButtonLeadingText}>
        Already have an account?
      </span>
      <a href="/login" className={styles.loginButton}>
        Sign In
      </a>
    </>
  );

  return (
    <div className={styles.container + " " + styles.bgStandard}>
      {loading ? (
        <LoadingComponentFullScreen />
      ) : (
        <>
          <HeaderComponent headerElements={headerElements} />
          <div className={styles.innerSignupDiv}>
            <div className={styles.error}>
              {error && (
                <ErrorComponent
                  title="Signup Failed"
                  close={() => setError("")}
                >
                  {error}
                </ErrorComponent>
              )}
            </div>
            <SignupFormComponent
              signupDetails={signupDetails}
              setSignupDetails={setSignupDetails}
              signup={signup}
            />
          </div>
          <FooterComponent />
        </>
      )}
    </div>
  );
}
