import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import styles from "./CheckSubscriptionComponent.module.css";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { Status } from "../../../__generated__/graphql";
import { LoadingComponentFullScreen } from "../LoadingComponent";

interface CheckSubscriptionComponentProps {
  children: React.ReactNode;
}

export default function CheckSubscriptionComponent({
  children,
}: CheckSubscriptionComponentProps): React.JSX.Element {
  const {
    signedInUser,
    loading: userClientLoading,
    getUserSubscriptionStatus,
  } = useUserApiClient();

  const [loading, setLoading] = React.useState(true);
  const [isUserSubscribed, setIsUserSubscribed] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    if (signedInUser) {
      getUserSubscriptionStatus()
        .then((data) => {
          if (data.status === Status.Active) {
            setIsUserSubscribed(true);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [signedInUser, getUserSubscriptionStatus]);

  return (
    <div className={styles.container}>
      {userClientLoading || loading ? (
        <LoadingComponentFullScreen />
      ) : !isUserSubscribed ? (
        <Redirect to="/subscriptions" />
      ) : (
        children
      )}
    </div>
  );
}
