import React from "react";
import styles from "./SubscriptionCard.module.css";
import { useHistory } from "react-router-dom";

interface SubscriptionCardProps {
  name: string;
  price: string;
  currency: string;
  features: string[];
}
const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  name,
  price,
  currency,
  features,
}) => {
  const history = useHistory();
  const handleSubscribeClick = React.useCallback(() => {
    history.push("/subscription/checkout");
  }, []);
  return (
    <div className={styles.card}>
      <h2 className={styles.cardTitle}>{name}</h2>
      <p className={styles.cardPrice}>
        {currency}
        {price}/month
      </p>
      <ul className={styles.cardFeatures}>
        {features.map((feature) => {
          return <li key={feature}>{feature}</li>;
        })}
      </ul>
      <button className={styles.cardButton} onClick={handleSubscribeClick}>
        Subscribe
      </button>
    </div>
  );
};

export default SubscriptionCard;
