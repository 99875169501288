import React, { createContext, useContext } from "react";

export interface TerminalHighlightContextValue {
  isTerminalHighlighted: boolean;
  setIsTerminalHighlighted?: (value: boolean) => void;
}

export const TerminalHighlightContext =
  createContext<TerminalHighlightContextValue>({
    isTerminalHighlighted: false,
  });

interface TerminalHighlightProviderProps {
  children: React.ReactNode;
}

export function TerminalHighlightProvider({
  children,
}: TerminalHighlightProviderProps): React.ReactNode {
  const [isTerminalHighlighted, setIsTerminalHighlighted] =
    React.useState(false);

  return (
    <TerminalHighlightContext.Provider
      value={{ isTerminalHighlighted, setIsTerminalHighlighted }}
    >
      {children}
    </TerminalHighlightContext.Provider>
  );
}

export function useTerminalHighlight(): TerminalHighlightContextValue {
  const { isTerminalHighlighted, setIsTerminalHighlighted } = useContext(
    TerminalHighlightContext,
  );

  if (setIsTerminalHighlighted === undefined) {
    throw new Error(
      "useTerminalHighlight must be used within a TerminalHighlightProvider",
    );
  }

  return { isTerminalHighlighted, setIsTerminalHighlighted };
}
