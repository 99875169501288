import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLessonApiClient from "../../hooks/useLessonApiClient";
import { useUserApiClient } from "../../hooks/useUserApiClient";
import { User } from "../../types/auth";
import { ILesson, PaginatedLesson } from "../../types/lesson";
import DashboardComponent from "../common_components/DashboardComponent";
import LoggedOutDashboardComponent from "../common_components/LoggedOutDashboardComponent";
import ProfileContent from "./components/content";
import ProfileHeader from "./components/header";
import styles from "./UserProfile.module.css";
import { UserDetails } from "./components/user-details";
import ErrorComponent from "../common_components/ErrorComponent";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";

export interface AccountDetails {
  firstName: string;
  lastName: string;
}

export interface RouterParams {
  userId: string;
}

export default function UserProfile(): JSX.Element {
  const { userId } = useParams<RouterParams>();
  const [paginatedLessons, setPaginatedLessons] = useState<PaginatedLesson>({
    hasNext: false,
    lessons: [],
    totalElements: 0,
    totalPages: 0,
  });
  const [enrolledLessons, setEnrolledLessons] = useState<ILesson[]>([]);
  const [user, setUser] = useState<User>();
  const {
    fetchLessonsByAuthorId,
    fetchPaginatedLessonsByAuthorId,
    fetchLesson,
  } = useLessonApiClient();
  const { fetchUserById, signedInUser, fetchUserEnrolledLessons } =
    useUserApiClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const fetchLessonById = async (lessonId: string): Promise<void> => {
    try {
      const lesson = await fetchLesson(lessonId);
      if (lesson) {
        setEnrolledLessons((prevLessons) => {
          if (prevLessons.some((prevLesson) => prevLesson.id === lesson.id)) {
            return prevLessons;
          }
          return [...prevLessons, lesson];
        });
      }
    } catch (error) {
      console.error("Error fetching lesson:", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async (): Promise<void> => {
      try {
        const paginatedLesson = await fetchPaginatedLessonsByAuthorId(
          userId,
          currentPage,
          10
        );
        setPaginatedLessons(paginatedLesson);
      } catch (error) {
        console.error("Error fetching lessons:", error);
        setError("Failed to fetch lessons.");
      }

      try {
        const fetchedUser = await fetchUserById(userId);
        setUser(fetchedUser);
      } catch (error) {
        console.error("Error fetching user:", error);
        setError("Failed to fetch user profile.");
      }

      try {
        const enrolledLessonIdsResponse = await fetchUserEnrolledLessons();
        if (enrolledLessonIdsResponse.enrolledLessons) {
          const promises =
            enrolledLessonIdsResponse.enrolledLessons.map(fetchLessonById);
          await Promise.all(promises);
        }
      } catch (error) {
        console.error("Error fetching enrolled lessons:", error);
        setError("Failed to fetch enrolled lessons.");
      }
    };

    fetchData()
      .catch((error) => console.error("Error in fetchData:", error))
      .finally(() => setIsLoading(false));
  }, [
    userId,
    currentPage,
    fetchLessonsByAuthorId,
    fetchUserById,
    fetchUserEnrolledLessons,
    fetchLesson,
  ]);

  const Dashboard = ({
    children,
  }: {
    children: React.JSX.Element;
  }): React.JSX.Element => {
    return (
      <>
        {isLoading && <LoadingComponentFullScreen />}
        {signedInUser ? (
          <DashboardComponent>{children}</DashboardComponent>
        ) : (
          <LoggedOutDashboardComponent>{children}</LoggedOutDashboardComponent>
        )}
        ;
      </>
    );
  };

  return (
    <Dashboard>
      <>
        <div className={styles.container}>
          {user && (
            <>
              {error && (
                <div className={styles.errorComponentContainer}>
                  <ErrorComponent
                    title="Could not update profile"
                    close={() => setError("")}
                  >
                    {error}
                  </ErrorComponent>
                </div>
              )}
              <ProfileHeader />
              <div className={styles.profileContent}>
                <UserDetails
                  user={user}
                  lessonCount={paginatedLessons.totalElements}
                  setError={setError}
                  setIsLoading={setIsLoading}
                  signedInUser={signedInUser ?? undefined}
                />
                <ProfileContent
                  paginatedLessons={paginatedLessons}
                  myLessons={enrolledLessons}
                  user={user}
                  signedInUser={signedInUser ?? undefined}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </>
          )}
        </div>
      </>
    </Dashboard>
  );
}
