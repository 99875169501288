import React from "react";
import { Input, Submit } from "../../common_components/Form";
import styles from "./SignupFormComponent.module.css";
import { SignupDetails } from "../../../types/auth";

interface SignupFormComponentProps {
  signupDetails: SignupDetails;
  setSignupDetails: (signupDetails: SignupDetails) => void;
  signup: () => void;
}

export default function SignupFormComponent({
  signupDetails,
  setSignupDetails,
  signup,
}: SignupFormComponentProps): React.JSX.Element {
  const { email, password } = signupDetails;

  return (
    <div className={styles.form}>
      <div className={styles.titleComponent}>Create Account</div>
      <Input
        className={styles.input}
        required
        type="email"
        placeholder="Email"
        onChange={(e) =>
          setSignupDetails({ ...signupDetails, email: e.target.value })
        }
        value={email}
      />
      <Input
        className={styles.input}
        required
        type="password"
        placeholder="Password"
        onChange={(e) =>
          setSignupDetails({ ...signupDetails, password: e.target.value })
        }
        value={password}
      />

      <div className={styles.spacing} />
      <Submit
        className={styles.submit}
        type="submit"
        value="Submit"
        onClick={signup}
      />
    </div>
  );
}
