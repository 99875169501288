import React from "react";
import styles from "./ChamEditor.module.css";

export const NoFileOpenedEditor: React.FC = () => {
  return (
    <div className={styles.noFileState}>
      <div className={styles.noFileStateText}>
        Create or select a file in the workspace to start coding.
      </div>
    </div>
  );
};
