import React from "react";
import { Input, Submit } from "../../../../common_components/Form";
import styles from "./PasswordResetRequestForm.module.css";

interface ForgotPasswordComponentProps {
  submit: (email: string) => void;
}

export default function ForgotPasswordFormComponent({
  submit,
}: ForgotPasswordComponentProps): JSX.Element {
  const [email, setEmail] = React.useState("");
  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault();
        submit(email);
      }}
    >
      <div className={styles.titleComponent}>Reset Password</div>
      <div className={styles.textComponent}>
        Enter the email address associated with your Chameleon account.
      </div>
      <Input
        className={styles.input}
        required
        type="email"
        placeholder="Email"
        onChange={(event) => setEmail(event.target.value)}
      />
      <div className={styles.spacing} />
      <Submit className={styles.submit} type="submit" value="Continue" />
    </form>
  );
}
