import { useSubscription } from "@apollo/client";
import { GET_CLARIFICATION_SUBSCRIPTION } from "../graphql";
import { Clients } from "..";
import { ClarificationWithMessage } from "../types/clarifications";

export const useLiveAIClarification = (
  clarificationId: string,
): {
  clarification?: ClarificationWithMessage;
  loading: boolean;
  error?: string;
} => {
  const { data, loading } = useSubscription(GET_CLARIFICATION_SUBSCRIPTION, {
    client: Clients.AI_WS,
    fetchPolicy: "network-only",
    variables: {
      clarificationId,
    },
  });
  const clarification = data?.clarification?.data;
  if (!data?.clarification) {
    return {
      error: data?.clarification?.error?.message ?? "Failed to fetch lesson",
      loading,
    };
  }

  const result = clarification as ClarificationWithMessage;

  return {
    clarification: result,
    loading,
  };
};
