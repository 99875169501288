import {
  Lesson,
  LessonSubscriptionSubscription,
  Page,
  Section,
  SectionContent,
  SectionContentInput,
} from "../../__generated__/graphql";
import {
  ContentTypes,
  ILesson,
  ILessonPage,
  ILessonPageContent,
  ILessonPageSection,
} from "../../types/lesson";

function transformLessonAPIToModel(lessonAPIData: Lesson): ILesson {
  const lesson: ILesson = {
    id: lessonAPIData.id,
    authorId: lessonAPIData.authorId,
    title: lessonAPIData.title ?? "",
    imageUrl: lessonAPIData.imageUrl ?? "",
    duration: lessonAPIData.duration ?? 0,
    pages:
      lessonAPIData.pages?.map((page): ILessonPage => {
        return transformPageAPIToModel(page);
      }) ?? [],
    createdDate: lessonAPIData.createdDate,
    modifiedDate: lessonAPIData.modifiedDate,
  };
  return lesson;
}

function transformPageAPIToModel(
  pageAPIData: Page
): ILessonPage & { id: string } {
  if (!pageAPIData.id) {
    throw new Error("Page ID is missing");
  }

  return {
    id: pageAPIData.id,
    title: pageAPIData.title ?? "",
    order: pageAPIData.pageOrder,
    lessonId: pageAPIData.lessonId,
    sections:
      [...(pageAPIData.sections ?? [])]
        .sort((a, b) => a.sectionOrder - b.sectionOrder)
        .map(
          (section): ILessonPageSection => transformSectionAPIToModel(section)
        ) ?? [],
  };
}

function transformSectionAPIToModel(
  sectionAPIData: Section
): ILessonPageSection {
  return {
    id: sectionAPIData.id,
    showPreview: true,
    content: transFormContentAPIToModel(sectionAPIData.content),
    contentType: getContentTypeFromSectionContent(sectionAPIData.content),
    order: sectionAPIData.sectionOrder,
    clarification: {
      id: sectionAPIData.clarification?.id ?? undefined,
      summary: sectionAPIData.clarification?.summary ?? undefined,
    },
  };
}

function transformLessonSubscriptionToModel(
  subscription: LessonSubscriptionSubscription | undefined
): ILesson | null {
  const lessonAPIData = subscription?.lesson.data;
  if (!lessonAPIData) {
    return null;
  }
  const lesson: ILesson = {
    id: lessonAPIData.id,
    authorId: lessonAPIData.authorId,
    title: lessonAPIData.title ?? "",
    imageUrl: "",
    pages:
      lessonAPIData.pages?.map((page, index) => ({
        id: page.id ?? "",
        title: page.title ?? "",
        order: page.pageOrder,
        lessonId: lessonAPIData.id,
        sections:
          [...(page.sections ?? [])]
            .sort((a, b) => a.sectionOrder - b.sectionOrder)
            .map((section) => {
              const transformedContent: ILessonPageContent = {};
              const content = section.content;
              let contentType: ContentTypes = ContentTypes.Unknown;
              if (content.video) {
                transformedContent.video = content.video;
                contentType = ContentTypes.Video;
              }
              if (content.codeBlock) {
                transformedContent.codeBlock = {
                  codeBlock: content.codeBlock.codeBlock,
                  codeLanguage: "",
                };
                contentType = ContentTypes.CodeBlock;
              }
              if (content.practiceQuestions) {
                transformedContent.practiceQuestions = {
                  questions: content.practiceQuestions.questions.map(
                    (question) => ({
                      order: question.questionOrder,
                      text: question.questionText,
                    })
                  ),
                };
                contentType = ContentTypes.PracticeQuestions;
              }
              if (content.textField) {
                transformedContent.textField = {
                  text: content.textField.text,
                };
                contentType = ContentTypes.TextField;
              }
              if (content.markdown) {
                transformedContent.markdown = {
                  content: content.markdown.content,
                };
                contentType = ContentTypes.Markdown;
              }

              const data = {
                id: section.id,
                showPreview: true,
                content: transformedContent,
                contentType,
                order: section.sectionOrder,
                clarification: {
                  id: section.clarification?.id ?? undefined,
                  summary: section.clarification?.summary ?? undefined,
                },
              };
              return data;
            }) ?? [],
      })) ?? [],
    createdDate: lessonAPIData.createdDate,
    modifiedDate: lessonAPIData.modifiedDate,
  };
  return lesson;
}

function transFormContentAPIToModel(
  apiContent: SectionContent
): ILessonPageContent {
  const transformedContent: ILessonPageContent = {};
  if (apiContent.video) {
    transformedContent.video = apiContent.video;
  } else if (apiContent.codeBlock) {
    transformedContent.codeBlock = apiContent.codeBlock;
  } else if (apiContent.practiceQuestions) {
    transformedContent.practiceQuestions = {
      questions: apiContent.practiceQuestions.questions.map((question) => {
        return {
          order: question.questionOrder,
          text: question.questionText,
        };
      }),
    };
  } else if (apiContent.textField) {
    transformedContent.textField = {
      text: apiContent.textField.text,
    };
  } else if (apiContent.markdown) {
    transformedContent.markdown = {
      content: apiContent.markdown.content,
    };
  }
  return transformedContent;
}

function getContentTypeFromSectionContent(
  apiContent: SectionContent
): ContentTypes {
  if (apiContent.video) {
    return ContentTypes.Video;
  } else if (apiContent.codeBlock) {
    return ContentTypes.CodeBlock;
  } else if (apiContent.practiceQuestions) {
    return ContentTypes.PracticeQuestions;
  } else if (apiContent.textField) {
    return ContentTypes.TextField;
  }
  return ContentTypes.Unknown;
}

function transformContentModelToAPIInput(
  contentType: ContentTypes,
  contentModelData: ILessonPageContent
): SectionContentInput {
  const content: SectionContentInput = {};
  if (contentType === ContentTypes.Video && contentModelData.video) {
    const { width, height } = contentModelData.video.dimensions;
    content.video = {
      videoLink: contentModelData.video.videoLink,
      dimensions: { width, height },
    };
  } else if (
    contentType === ContentTypes.CodeBlock &&
    contentModelData.codeBlock
  ) {
    content.codeBlock = {
      codeBlock: contentModelData.codeBlock.codeBlock,
      codeLanguage: contentModelData.codeBlock.codeLanguage,
    };
  } else if (
    contentType === ContentTypes.PracticeQuestions &&
    contentModelData.practiceQuestions
  ) {
    content.practiceQuestions = {
      questions: contentModelData.practiceQuestions.questions.map(
        (question) => {
          return {
            questionOrder: question.order,
            questionText: question.text,
          };
        }
      ),
    };
  } else if (
    contentType === ContentTypes.TextField &&
    contentModelData.textField
  ) {
    content.textField = {
      text: contentModelData.textField.text,
    };
  }
  return content;
}

const APIToModel = {
  transformLesson: transformLessonAPIToModel,
  transformPage: transformPageAPIToModel,
  transformSection: transformSectionAPIToModel,
};

const SubscriptionToModel = {
  transformLesson: transformLessonSubscriptionToModel,
};

const ModelToAPIInput = {
  transformContent: transformContentModelToAPIInput,
};

export { APIToModel, SubscriptionToModel, ModelToAPIInput };
