import * as React from "react";
import DashboardComponent from "../../common_components/DashboardComponent";
import styles from "./Checkout.module.css";
import { Status } from "../../../__generated__/graphql";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { Redirect } from "react-router-dom";

interface SubscriptionCheckoutPageProps {
  children: JSX.Element;
}

export const SubscriptionCheckoutPage: React.FC<
  SubscriptionCheckoutPageProps
> = ({ children }) => {
  const { getUserSubscriptionStatus } = useUserApiClient();
  const [isUserSubscribed, setIsUserSubscribed] = React.useState<boolean>();

  React.useEffect(() => {
    const loadIsSubscribed = async (): Promise<void> => {
      const { status } = await getUserSubscriptionStatus();
      if (status === Status.Active) {
        setIsUserSubscribed(true);
      } else {
        setIsUserSubscribed(false);
      }
    };

    void loadIsSubscribed();
  }, []);

  return (
    <DashboardComponent>
      {isUserSubscribed ? (
        <Redirect to={"/menu"} />
      ) : (
        <div className={styles.container}>{children}</div>
      )}
    </DashboardComponent>
  );
};
