import React from "react";

export interface ClarificationContextData {
  codeChatId?: string;
  setCodeChatId?: (chatId?: string) => void;
  activeChatId?: string;
  setActiveChatId?: (chatId?: string) => void;
  fetchClarificationsTrigger?: number;
  setFetchClarificationsTrigger?: (fetch: number) => void;
}
export const ClarificationChatContext = React.createContext<
  ClarificationContextData | undefined
>(undefined);

interface ClarificationChatProviderProps extends ClarificationContextData {
  children: React.ReactNode;
}

export function ClarificationChatProvider({
  children,
  codeChatId,
  setCodeChatId,
  activeChatId,
  setActiveChatId,
  fetchClarificationsTrigger,
  setFetchClarificationsTrigger,
}: ClarificationChatProviderProps): React.ReactNode {
  React.useState<boolean>(false);

  return (
    <ClarificationChatContext.Provider
      value={{
        codeChatId,
        setCodeChatId,
        activeChatId,
        setActiveChatId,
        fetchClarificationsTrigger,
        setFetchClarificationsTrigger,
      }}
    >
      {children}
    </ClarificationChatContext.Provider>
  );
}

export function useClarificationChat(): ClarificationContextData {
  const {
    setCodeChatId,
    activeChatId,
    setActiveChatId,
    codeChatId,
    fetchClarificationsTrigger,
    setFetchClarificationsTrigger,
  } = React.useContext(ClarificationChatContext) ?? {};
  return {
    setCodeChatId,
    activeChatId,
    setActiveChatId,
    codeChatId,
    fetchClarificationsTrigger,
    setFetchClarificationsTrigger,
  };
}
