import React from "react";

interface LessonContextData {
  lessonId: string;
  lessonAuthorId: string;
}

export const LessonContext = React.createContext<LessonContextData | undefined>(
  undefined,
);

interface LessonProviderProps {
  lessonId: string;
  lessonAuthorId: string;
  children: React.ReactNode;
}

export function LessonProvider({
  children,
  lessonId,
  lessonAuthorId,
}: LessonProviderProps): React.ReactNode {
  return (
    <LessonContext.Provider value={{ lessonId, lessonAuthorId }}>
      {children}
    </LessonContext.Provider>
  );
}

export function useLessonId(): string | undefined {
  const { lessonId } = React.useContext(LessonContext) ?? {};
  return lessonId;
}

export function useLessonAuthorId(): string | undefined {
  const { lessonAuthorId } = React.useContext(LessonContext) ?? {};
  return lessonAuthorId;
}
