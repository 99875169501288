import React from "react";
import { useHistory } from "react-router-dom";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { ChamIDEContainer } from "./components/ChamIDEContainer/ChamIDEContainer";
import styles from "./index.module.css";
import { ChamBrowserContainer } from "./components/ChamBrowserContainer/ChamBrowserContainer";
import { ExecutionServiceProvider } from "./contexts/ExecutionServiceContext/ExecutionServiceContext";

interface ChamIdeProps {
  showChamBrowser: boolean;
  toggleShowChamBrowser: () => void;
}

export function ChamIDE({
  showChamBrowser,
  toggleShowChamBrowser,
}: ChamIdeProps): JSX.Element {
  const history = useHistory();
  const { signedInUser } = useUserApiClient();
  const isUserLoggedIn = signedInUser?.id != null;
  const handleGoToLogin = React.useCallback(() => {
    history.push(`/login/?next=${window.location.pathname}`);
  }, []);
  return (
    <div className={styles.mainParent}>
      <div className={styles.containerParent}>
        {!isUserLoggedIn ? (
          <div className={styles.loginBlock}>
            <div className={styles.loginBlockContainer}>
              <h3>
                The coding environment is only available to users when logged in
              </h3>
              <div className={styles.loginButton} onClick={handleGoToLogin}>
                Login
              </div>
            </div>
          </div>
        ) : (
          <ExecutionServiceProvider>
            <div className={showChamBrowser ? styles.hidden : styles.show}>
              <ChamIDEContainer toggleShowChamBrowser={toggleShowChamBrowser} />
            </div>
            <div className={showChamBrowser ? styles.show : styles.hidden}>
              <ChamBrowserContainer
                toggleShowChamBrowser={toggleShowChamBrowser}
              />
            </div>
          </ExecutionServiceProvider>
        )}
      </div>
    </div>
  );
}
