import React from "react";
import { Redirect } from "react-router-dom";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

interface OnboardingCheckComponentProps {
  children: React.ReactNode;
}

export default function OnboardingCheckComponent({
  children,
}: OnboardingCheckComponentProps): React.JSX.Element {
  const { signedInUser } = useUserApiClient();
  if (signedInUser && !signedInUser.hasCompletedOnboarding) {
    return <Redirect to="/finish-setup" />;
  }

  return <>{children}</>;
}
