import React from "react";

export default function Logo({
  height,
  className,
}: {
  height?: string;
  className?: string;
}): JSX.Element {
  return (
    <svg
      height={height ?? "60"}
      viewBox="0 0 1341 427"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_107_62)">
        <path
          d="M231.952 306.439C228.552 306.439 225.186 305.786 221.957 304.479L171.366 284.331L244.008 255.345L246.24 256.755C254.105 261.706 258.811 270.233 258.811 279.517C258.811 294.371 246.755 306.439 231.952 306.439ZM199.22 284.331L225.804 294.921C227.796 295.712 229.857 296.124 231.952 296.124C241.088 296.124 248.507 288.663 248.507 279.517C248.507 274.634 246.412 270.096 242.771 266.967L199.22 284.331Z"
          fill="#29834D"
        />
        <path
          d="M127.987 267.036L89.9313 251.872C74.1664 245.58 64 230.52 64 213.535C64 205.283 66.4386 197.271 71.0753 190.394C75.712 183.552 82.2034 178.291 89.8969 175.231L222.164 122.487C224.156 121.73 226.113 121.18 228.071 120.871C235.73 119.667 243.561 121.937 249.503 127.06C255.411 132.114 258.777 139.507 258.777 147.312C258.777 156.595 253.865 165.329 245.931 170.108C244.66 170.933 243.149 171.69 241.535 172.274L132.967 213.328L165.973 225.81L158.829 254.52L132.898 264.319C131.971 264.663 131.044 265.144 130.185 265.66L127.987 267.001V267.036ZM232.124 130.876C231.3 130.876 230.51 130.945 229.685 131.048C228.483 131.255 227.213 131.598 225.976 132.08L93.7437 184.79C87.9736 187.094 83.0964 191.048 79.6275 196.171C76.1585 201.328 74.3038 207.346 74.3038 213.535C74.3038 226.257 81.9286 237.569 93.7437 242.279L127.162 255.586C127.849 255.276 128.536 254.967 129.223 254.692L150.106 246.784L153.746 232.205L126.269 221.821C122.697 220.48 120.396 217.145 120.396 213.328C120.396 209.512 122.697 206.177 126.269 204.836L237.894 162.647C238.856 162.269 239.749 161.822 240.539 161.34C245.485 158.349 248.507 152.985 248.507 147.312C248.507 142.429 246.481 138.028 242.806 134.865C239.783 132.286 236.005 130.876 232.124 130.876Z"
          fill="#29834D"
        />
        <path
          d="M126.785 171.621L124.587 170.246C116.824 165.363 112.016 156.664 112.016 147.484C112.016 132.63 124.071 120.562 138.874 120.562C142.309 120.562 145.675 121.215 148.869 122.487L199.392 142.705L126.819 171.621H126.785ZM138.84 130.877C129.704 130.877 122.285 138.338 122.285 147.484C122.285 152.298 124.449 156.905 128.021 160.034L171.572 142.67L144.988 132.08C143.03 131.289 140.97 130.877 138.84 130.877Z"
          fill="#29834D"
        />
        <path
          d="M138.668 306.439C132.349 306.439 126.166 304.17 121.289 299.941C115.382 294.886 112.016 287.494 112.016 279.689C112.016 270.405 116.927 261.672 124.827 256.858C126.201 255.999 127.712 255.276 129.258 254.692L237.826 213.638L204.819 201.157L211.997 172.446L237.894 162.647C238.856 162.269 239.749 161.822 240.539 161.34L242.771 159.931L280.861 175.128C296.626 181.42 306.827 196.446 306.827 213.432C306.827 221.684 304.388 229.695 299.751 236.572C295.08 243.448 288.555 248.709 280.895 251.769L148.629 304.514C146.671 305.27 144.713 305.82 142.756 306.13C141.416 306.336 140.077 306.439 138.737 306.439H138.668ZM217.012 194.761L244.489 205.145C248.026 206.452 250.327 209.787 250.362 213.603C250.362 217.42 248.061 220.79 244.489 222.131L132.864 264.319C131.937 264.663 131.009 265.145 130.151 265.66C125.205 268.652 122.285 273.912 122.285 279.689C122.285 284.537 124.312 288.972 127.987 292.136C131.627 295.265 136.402 296.674 141.107 295.952C142.309 295.746 143.546 295.402 144.782 294.955L277.014 242.211C282.75 239.941 287.662 235.987 291.165 230.83C294.634 225.672 296.489 219.689 296.489 213.5C296.489 200.744 288.864 189.466 277.014 184.756L243.63 171.415C242.943 171.759 242.256 172.034 241.535 172.309L220.652 180.217L217.012 194.796V194.761Z"
          fill="#29834D"
        />
        <path
          d="M154.639 363C140.214 363 128.468 351.275 128.468 336.868C128.468 334.736 128.742 332.57 129.257 330.473L137.741 296.467L141.107 295.952C142.343 295.745 143.58 295.402 144.816 294.955L197.194 274.049L194.034 287.046L180.021 343.16C177.136 354.816 166.695 363 154.674 363H154.639ZM146.121 305.373L139.252 332.983C138.943 334.289 138.771 335.562 138.771 336.868C138.771 345.602 145.881 352.685 154.639 352.685C161.921 352.685 168.24 347.733 169.992 340.685L182.391 291.069L148.56 304.548C147.736 304.857 146.946 305.132 146.121 305.373ZM173.53 152.985L178.235 134.177L199.358 142.67L173.53 152.985Z"
          fill="#29834D"
        />
        <path
          d="M147.083 258.989L164.153 190.532L223.709 168.01L206.605 236.468L147.083 258.989ZM172.843 198.268L161.852 242.382L197.915 228.732L208.94 184.618L172.843 198.268Z"
          fill="#29834D"
        />
        <path
          d="M185.482 148.205L175.693 144.286L190.771 83.8394C193.691 72.1489 204.132 64 216.153 64C230.578 64 242.325 75.7248 242.325 90.1659C242.325 92.2977 242.05 94.4295 241.535 96.5613L233.051 130.532L229.685 131.048C228.449 131.254 227.178 131.598 225.942 132.08L185.448 148.24L185.482 148.205ZM216.153 74.3151C208.872 74.3151 202.518 79.2663 200.766 86.3494L188.401 135.965L222.198 122.487C223.022 122.177 223.847 121.902 224.671 121.661L231.54 94.0857C231.849 92.7791 232.021 91.4725 232.021 90.2003C232.021 81.4669 224.911 74.3495 216.153 74.3495V74.3151Z"
          fill="#29834D"
        />
        <path
          d="M375.695 301.213H363.261C361.372 301.213 359.827 299.666 359.827 297.775V131.358C359.827 129.467 361.372 127.919 363.261 127.919H375.695C377.584 127.919 379.129 129.467 379.129 131.358V197.718H382.083C386.376 190.291 392.318 184.652 399.874 180.767C407.465 176.882 415.708 174.956 424.603 174.956C432.194 174.956 439.372 176.366 446.104 179.151C452.87 181.97 458.846 185.993 464.033 191.288C469.219 196.584 473.34 203.082 476.397 210.853C479.454 218.623 480.965 227.426 480.965 237.328V242.279C480.965 252.354 479.488 261.259 476.5 269.03C473.546 276.801 469.459 283.299 464.273 288.594C459.087 293.889 453.008 297.878 446.104 300.594C439.166 303.31 431.85 304.686 424.088 304.686C420.138 304.686 416.086 304.239 411.964 303.31C407.842 302.416 403.927 301.007 400.218 299.115C396.508 297.224 393.074 294.921 389.948 292.17C386.823 289.454 384.178 286.187 382.049 282.405H379.095V297.775C379.095 299.666 377.549 301.213 375.66 301.213H375.695ZM420.173 286.359C426.115 286.359 431.541 285.328 436.487 283.265C441.433 281.202 445.761 278.279 449.47 274.462C453.179 270.68 456.064 266.038 458.125 260.606C460.186 255.173 461.216 249.053 461.216 242.279V237.328C461.216 230.726 460.186 224.744 458.125 219.38C456.064 214.016 453.179 209.409 449.47 205.523C445.761 201.638 441.399 198.647 436.35 196.48C431.335 194.349 425.909 193.248 420.138 193.248C414.368 193.248 409.01 194.349 404.064 196.584C399.119 198.818 394.791 201.913 391.082 205.867C387.372 209.821 384.453 214.566 382.289 220.102C380.16 225.638 379.06 231.689 379.06 238.291V241.248C379.06 248.021 380.125 254.176 382.289 259.677C384.418 265.213 387.372 269.958 391.082 273.912C394.791 277.866 399.119 280.927 404.064 283.058C409.01 285.19 414.368 286.29 420.138 286.29L420.173 286.359Z"
          fill="#2A2A2A"
        />
        <path
          d="M591.422 178.429H603.855C605.744 178.429 607.29 179.976 607.29 181.867V297.774C607.29 299.665 605.744 301.213 603.855 301.213H591.422C589.533 301.213 587.988 299.665 587.988 297.774V278.932H585.034C581.084 286.84 575.898 293.133 569.441 297.74C563.018 302.347 554.844 304.685 544.952 304.685C538.186 304.685 531.969 303.585 526.268 301.35C520.566 299.115 515.723 295.78 511.671 291.31C507.618 286.84 504.492 281.339 502.26 274.737C500.027 268.136 498.928 260.468 498.928 251.7V181.867C498.928 179.976 500.474 178.429 502.363 178.429H514.796C516.685 178.429 518.231 179.976 518.231 181.867V249.225C518.231 262.084 520.875 271.505 526.13 277.454C531.42 283.402 539.491 286.359 550.379 286.359C562.091 286.359 571.261 282.37 577.959 274.359C584.622 266.348 587.988 255.654 587.988 242.313V181.867C587.988 179.976 589.533 178.429 591.422 178.429Z"
          fill="#2A2A2A"
        />
        <path
          d="M635.179 283.402H671.345C673.234 283.402 674.78 281.855 674.78 279.964V199.712C674.78 197.821 673.234 196.274 671.345 196.274H639.644C637.755 196.274 636.209 194.727 636.209 192.836V181.902C636.209 180.011 637.755 178.463 639.644 178.463H690.648C692.537 178.463 694.082 180.011 694.082 181.902V279.998C694.082 281.889 695.628 283.436 697.517 283.436H730.695C732.584 283.436 734.13 284.984 734.13 286.875V297.809C734.13 299.7 732.584 301.247 730.695 301.247H635.179C633.29 301.247 631.744 299.7 631.744 297.809V286.875C631.744 284.984 633.29 283.436 635.179 283.436V283.402ZM666.846 141.81C666.846 137.031 668.529 132.939 671.929 129.57C675.33 126.2 679.382 124.481 684.156 124.481C688.931 124.481 693.018 126.166 696.384 129.57C699.75 132.939 701.467 137.031 701.467 141.81C701.467 146.59 699.784 150.681 696.384 154.051C692.983 157.42 688.931 159.14 684.156 159.14C679.382 159.14 675.295 157.455 671.929 154.051C668.563 150.681 666.846 146.59 666.846 141.81Z"
          fill="#2A2A2A"
        />
        <path
          d="M751.406 283.402H789.049C790.939 283.402 792.484 281.855 792.484 279.964V149.203C792.484 147.312 790.939 145.764 789.049 145.764H752.883C750.994 145.764 749.448 144.217 749.448 142.326V131.392C749.448 129.501 750.994 127.954 752.883 127.954H808.318C810.207 127.954 811.752 129.501 811.752 131.392V279.998C811.752 281.889 813.298 283.436 815.187 283.436H852.83C854.719 283.436 856.265 284.984 856.265 286.875V297.809C856.265 299.7 854.719 301.247 852.83 301.247H751.372C749.483 301.247 747.937 299.7 747.937 297.809V286.875C747.937 284.984 749.483 283.436 751.372 283.436L751.406 283.402Z"
          fill="#2A2A2A"
        />
        <path
          d="M970.294 282.405H967.34C965.348 286.187 962.841 289.488 959.784 292.307C956.727 295.127 953.43 297.431 949.892 299.253C946.355 301.075 942.611 302.416 938.627 303.345C934.677 304.239 930.864 304.72 927.258 304.72C919.015 304.72 911.356 303.31 904.246 300.525C897.171 297.706 890.92 293.683 885.562 288.388C880.204 283.093 876.014 276.56 872.957 268.72C869.9 260.881 868.389 251.941 868.389 241.867V237.913C868.389 228.01 869.969 219.139 873.094 211.3C876.22 203.46 880.41 196.858 885.699 191.495C890.989 186.131 897.171 182.039 904.246 179.254C911.322 176.435 918.843 175.059 926.743 175.059C935.158 175.059 943.023 176.916 950.373 180.629C957.689 184.343 963.356 190.085 967.306 197.821H970.259V131.461C970.259 129.57 971.805 128.022 973.694 128.022H986.127C988.016 128.022 989.562 129.57 989.562 131.461V297.878C989.562 299.769 988.016 301.316 986.127 301.316H973.694C971.805 301.316 970.259 299.769 970.259 297.878V282.508L970.294 282.405ZM929.25 286.359C935.02 286.359 940.378 285.293 945.324 283.127C950.27 280.995 954.598 277.935 958.307 273.981C962.016 270.027 964.936 265.282 967.099 259.746C969.263 254.21 970.328 248.056 970.328 241.316V238.36C970.328 231.758 969.263 225.706 967.099 220.171C964.97 214.635 962.016 209.89 958.307 205.936C954.598 201.982 950.236 198.887 945.187 196.652C940.172 194.417 934.849 193.317 929.25 193.317C923.652 193.317 918.088 194.383 913.039 196.549C908.024 198.681 903.628 201.741 899.919 205.695C896.209 209.649 893.324 214.36 891.263 219.792C889.203 225.225 888.172 231.276 888.172 237.878V241.832C888.172 248.778 889.203 254.967 891.263 260.399C893.324 265.832 896.209 270.508 899.919 274.394C903.628 278.279 907.956 281.236 912.901 283.299C917.847 285.362 923.274 286.393 929.216 286.393L929.25 286.359Z"
          fill="#2A2A2A"
        />
        <path
          d="M1019.99 195.277C1019.99 172.824 1025.35 155.426 1036.07 143.048C1046.78 130.67 1061.69 124.481 1080.82 124.481C1099.95 124.481 1114.86 130.67 1125.57 143.048C1136.29 155.426 1141.65 172.859 1141.65 195.277V233.89C1141.65 255.001 1137.15 271.574 1128.18 283.54C1119.77 294.783 1108.16 301.522 1093.42 303.826C1091.74 304.101 1090.47 305.511 1090.47 307.196V314.588C1090.47 319.539 1092.7 322.015 1097.13 322.015H1114.48C1116.37 322.015 1117.91 323.562 1117.91 325.453V336.387C1117.91 338.278 1116.37 339.826 1114.48 339.826H1090.23C1084.63 339.826 1080.06 338.003 1076.49 334.393C1072.95 330.748 1071.17 326.141 1071.17 320.536V307.161C1071.17 305.442 1069.9 304.067 1068.21 303.792C1053.31 301.35 1041.66 294.577 1033.32 283.402C1024.42 271.505 1019.96 255.001 1019.96 233.89V195.277H1019.99ZM1080.82 285.396C1094.18 285.396 1104.17 280.823 1110.87 271.643C1117.54 262.497 1120.9 249.397 1120.9 232.411V196.755C1120.9 180.904 1117.57 168.114 1110.87 158.383C1104.21 148.653 1094.18 143.77 1080.82 143.77C1067.46 143.77 1057.46 148.653 1050.77 158.383C1044.1 168.114 1040.74 180.904 1040.74 196.755V232.411C1040.74 249.397 1044.07 262.497 1050.77 271.643C1057.43 280.789 1067.46 285.396 1080.82 285.396Z"
          fill="#2A2A2A"
        />
        <path
          d="M1169.6 127.919H1183.51C1185.4 127.919 1186.95 129.467 1186.95 131.358V281.889H1274.02C1275.91 281.889 1277.45 283.437 1277.45 285.328V297.775C1277.45 299.666 1275.91 301.213 1274.02 301.213H1169.6C1167.72 301.213 1166.17 299.666 1166.17 297.775V131.358C1166.17 129.467 1167.72 127.919 1169.6 127.919Z"
          fill="#2A2A2A"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_62">
          <rect
            width="1213"
            height="299"
            fill="white"
            transform="translate(64 64)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
