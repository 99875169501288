import React, { HTMLAttributes, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TextFieldEditor.css";

interface IRichTextEditorProps {
  value: string;
  setValue: (value: string) => void;
}

function TextFieldEditor({
  value,
  setValue,
}: HTMLAttributes<HTMLDivElement> & IRichTextEditorProps): React.JSX.Element {
  // If opened, force value to exist
  useEffect(() => {
    if (!value) {
      setValue("<p><br></p>");
    }
  }, [value]);

  return (
    <div className="textFieldEditor">
      <ReactQuill
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4] }],
            [
              "bold",
              "italic",
              "underline",
              { list: "ordered" },
              { list: "bullet" },
              "image",
              "link",
            ],
            ["code-block"],
          ],
        }}
        theme="snow"
        value={value}
        onChange={(value, _delta, source) => {
          if (source !== "api" && value != null) {
            setValue(value);
          }
        }}
      />
    </div>
  );
}

export default TextFieldEditor;
