import React from "react";
import styles from "./Chat.module.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ClarificationWithMessage } from "../../../../../types/clarifications";
import { useLiveAIClarification } from "../../../../../hooks/useLiveAIClarification";
import { MarkdownCodeBlock } from "./MarkdownCodeBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import useAILessonCreatorClient from "../../../../../hooks/useAILessonCreatorClient";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useLessonManager from "../../../../../hooks/useLessonManager";
import IconLogo from "../../../../common_components/Logo/icon-logo";
import { useViewContext } from "../../../../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import { ViewState } from "../../../../common_components/LessonLayout/types";

interface IChatView {
  chatId: string;
  clarification?: ClarificationWithMessage;
  isLoading: boolean;
  lessonId: string;
}
export const ChatMessageView: React.FC<IChatView> = ({
  chatId,
  clarification,
  isLoading,
  lessonId,
}) => {
  const { clarification: clarificationUpdate } = useLiveAIClarification(
    chatId ?? ""
  );
  const { lesson } = useLessonManager(lessonId);
  const { setCurrentView } = useViewContext();
  const maybePageId = React.useMemo(() => {
    return lesson.pages.find((page) =>
      page.sections.find((section) => section.id === clarification?.sectionId)
    )?.id;
  }, [lesson, clarification?.sectionId]);
  const [isLoadingSummary, setIsLoadingSummary] = React.useState(false);
  const { summarizeClarificationChat } = useAILessonCreatorClient();
  const handleCopy = React.useCallback((text: string) => {
    navigator.clipboard
      .writeText(text ?? "")
      .then((text) => {
        console.log("Copied");
      })
      .catch((err) => {
        console.error("Failed to copy to clipboard", err);
      });
  }, []);

  const handleSummarizeAndSave = React.useCallback(async () => {
    setIsLoadingSummary(true);
    if (clarification?.sectionId && maybePageId) {
      await summarizeClarificationChat(
        clarification.clarificationId,
        maybePageId,
        clarification.sectionId
      );
      setCurrentView(ViewState.Lesson);
    } else {
      console.log("Failed to get page or section id for summary");
      console.log(
        "Page ID",
        maybePageId,
        "Section Id",
        clarification?.sectionId
      );
    }
    setIsLoadingSummary(false);
  }, [chatId, clarification, maybePageId, setIsLoadingSummary]);

  return (
    <div className={styles.chatMessageContainer}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        <div className={styles.messages}>
          {(clarificationUpdate ?? clarification)?.messages.map((message) => {
            if (message.role === "USER") {
              return (
                <div
                  key={message.content}
                  className={`${styles.userChatMessage} ${styles.chatMessage}`}
                >
                  {message.content}
                </div>
              );
            } else if (message.role === "ASSISTANT") {
              return (
                <div
                  key={message.content}
                  className={`${styles.aiChatMessage} ${styles.chatMessage}`}
                >
                  <div className={styles.logo}>
                    <IconLogo height="30px" />
                  </div>
                  <div className={styles.aiMessageContent}>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code: MarkdownCodeBlock,
                      }}
                    >
                      {message.content}
                    </ReactMarkdown>
                    <div className={styles.aiBottomIcons}>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className={styles.iconElem}
                        onClick={() => handleCopy(message.content)}
                      />

                      {clarification?.sectionId && maybePageId && (
                        <div
                          className={styles.textElem}
                          onClick={() => {
                            void handleSummarizeAndSave();
                          }}
                        >
                          {isLoadingSummary ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className={"fa-spin"}
                              color="DFE2E0"
                              style={{ padding: "5px" }}
                            />
                          ) : (
                            "Add summary to lesson"
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </div>
  );
};
