import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { ITerminal } from "../ChamTerminal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "./TerminalMenu.module.css";
interface TerminalMenuProps {
  anchorEl: HTMLElement | null;
  terminals: ITerminal[];
  setTerminals: (terminals: ITerminal[]) => void;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  activeTerminal?: ITerminal;
  setActiveTerminal: (terminal: ITerminal) => void;
  setShowTerminal: (show: boolean) => void;
}

export const TerminalMenu: React.FC<TerminalMenuProps> = ({
  anchorEl,
  terminals,
  setTerminals,
  setAnchorEl,
  activeTerminal,
  setActiveTerminal,
  setShowTerminal,
}) => {
  const [showDel, setShowDel] = React.useState<string>();
  const open = Boolean(anchorEl);
  const handleClose = React.useCallback((): void => {
    setAnchorEl(null);
  }, []);
  const handleDeleteTerminal = React.useCallback(
    (id: string, e: React.MouseEvent) => {
      e.stopPropagation();
      if (activeTerminal?.id === id) {
        setActiveTerminal(terminals[0]);
      }
      setTerminals(terminals.filter((term) => term.id !== id));
    },
    [terminals]
  );
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {terminals.map((terminal) => (
        <MenuItem
          key={terminal.id}
          onClick={() => {
            setActiveTerminal(terminal);
            setShowTerminal(true);
            handleClose();
          }}
          onMouseEnter={() => setShowDel(terminal.id)}
          onMouseLeave={() => setShowDel(terminal.id)}
          selected={activeTerminal?.id === terminal.id}
          style={{
            fontFamily: "CircularStd",
            fontWeight: 600,
            fontSize: "0.8em",
            background: "#BEC1BF",
          }}
        >
          {terminal.name}
          {showDel === terminal.id && (
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) => handleDeleteTerminal(terminal.id, e)}
              className={styles.trash}
            />
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};
