import { useLazyQuery, useMutation } from "@apollo/client";
import React from "react";
import { Clients } from "..";
import {
  CLARIFY_TEXT_MUTATION,
  CLARIFY_SECTION_MUTATION,
  GENERATE_SECTION_CONTENT_FOR_LESSON_MUTATION,
  GET_USER_CLARIFICATIONS_FOR_LESSON_QUERY,
  GET_CLARIFICATION_QUERY,
  REGENERATE_SECTION_IN_LESSON_MUTATION,
  GENERATE_NEXT_SECTION_IN_LESSON_MUTATION,
  SUMMARIZE_CLARIFICATION_MUTATION,
  REGENERATE_SECTION_IN_LESSON_FROM_QUESTION_MUTATION,
} from "../graphql";
import {
  Clarification,
  ClarificationWithMessage,
} from "../types/clarifications";
import { ClarificationType } from "../__generated__/graphql";

interface UseAILessonCreatorClient {
  loading: boolean;
  generateSectionContentFromPrompt: (question: string) => Promise<string>;
  clarifyTextWithChamAI: (
    text: string,
    lessonId: string,
    sectionId?: string,
    question?: string,
    clarificationType?: ClarificationType,
    chatId?: string
  ) => Promise<string>;
  clarifySectionWithChamAI: (
    lessonId: string,
    sectionId: string,
    pageId: string,
    question: string,
    text?: string
  ) => Promise<string>;
  getUserClarificationsForLesson: (
    lessonId: string
  ) => Promise<Clarification[]>;
  getClarification: (
    clarificationId: string
  ) => Promise<ClarificationWithMessage>;
  generateNextSectionInLesson: (
    lessonId: string,
    pageId: string,
    prompt?: string
  ) => Promise<string>;
  regenerateSectionContentFromClarification: (
    lessonId: string,
    pageId: string,
    sectionId: string,
    clarificationId: string
  ) => Promise<string>;
  regenerateSectionContentFromPrompt: (
    lessonId: string,
    pageId: string,
    sectionOrder: number,
    question: string
  ) => Promise<string>;
  summarizeClarificationChat: (
    clarificationId: string,
    pageId: string,
    sectionId: string
  ) => Promise<string>;
}

function useAILessonCreatorClient(): UseAILessonCreatorClient {
  const [generateSectionContentFromPromptMutation] = useMutation(
    GENERATE_SECTION_CONTENT_FOR_LESSON_MUTATION,
    {
      client: Clients.AI,
    }
  );

  const [regenerateSectionContentFromClarificationMutation] = useMutation(
    REGENERATE_SECTION_IN_LESSON_MUTATION,
    {
      client: Clients.AI,
    }
  );

  const [regenerateSectionContentFromPromptMutation] = useMutation(
    REGENERATE_SECTION_IN_LESSON_FROM_QUESTION_MUTATION,
    {
      client: Clients.AI,
    }
  );

  const [generateNextSectionInLessonMutation] = useMutation(
    GENERATE_NEXT_SECTION_IN_LESSON_MUTATION,
    {
      client: Clients.AI,
    }
  );

  const [getUserClarificationsQuery] = useLazyQuery(
    GET_USER_CLARIFICATIONS_FOR_LESSON_QUERY,
    {
      client: Clients.AI,
    }
  );

  const [getClarificationQuery] = useLazyQuery(GET_CLARIFICATION_QUERY, {
    client: Clients.AI,
    fetchPolicy: "no-cache",
  });

  const [clarifyTextWithChamAIMutation] = useMutation(CLARIFY_TEXT_MUTATION, {
    client: Clients.AI,
  });

  const [clarifySectionWithChamAIMutation] = useMutation(
    CLARIFY_SECTION_MUTATION,
    {
      client: Clients.AI,
    }
  );

  const [summarizeClarificationChatMutation] = useMutation(
    SUMMARIZE_CLARIFICATION_MUTATION,
    {
      client: Clients.AI,
    }
  );

  const [loading, setLoading] = React.useState(false);

  const generateSectionContentFromPrompt = React.useCallback(
    async (question: string): Promise<string> => {
      setLoading(true);
      return await generateSectionContentFromPromptMutation({
        variables: { question },
      })
        .then((result) => {
          const maybeLessonId =
            result.data?.generateSectionContentForLesson?.data?.lessonId;

          const maybeError =
            result.data?.generateSectionContentForLesson?.error;
          if (!maybeLessonId) {
            if (maybeError) {
              throw new Error(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to generate lesson pages with error ${maybeError.message}`
              );
            } else {
              throw new Error("Failed to generate lesson pages");
            }
          }
          return maybeLessonId;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const generateNextSectionInLesson = React.useCallback(
    async (
      lessonId: string,
      pageId: string,
      prompt?: string
    ): Promise<string> => {
      setLoading(true);
      return await generateNextSectionInLessonMutation({
        variables: { lessonId, pageId, prompt },
      })
        .then((result) => {
          const res = result.data?.generateNextSection?.data;

          const maybeError = result.data?.generateNextSection?.error;
          if (!res) {
            if (maybeError) {
              throw new Error(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to generate next section with error ${maybeError.message}`
              );
            } else {
              throw new Error("Failed to generate section");
            }
          }
          return res;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const regenerateSectionContentFromClarification = React.useCallback(
    async (
      lessonId: string,
      pageId: string,
      sectionId: string,
      clarificationId: string
    ): Promise<string> => {
      setLoading(true);
      return await regenerateSectionContentFromClarificationMutation({
        variables: { lessonId, pageId, sectionId, clarificationId },
      })
        .then((result) => {
          const res = result.data?.regenerateSectionWithClarification?.data;

          const maybeError =
            result.data?.regenerateSectionWithClarification?.error;
          if (!res) {
            if (maybeError) {
              throw new Error(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to regenerate section with error ${maybeError.message}`
              );
            } else {
              throw new Error("Failed to regenerate section");
            }
          }
          return res;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const regenerateSectionContentFromPrompt = React.useCallback(
    async (
      lessonId: string,
      pageId: string,
      sectionOrder: number,
      question: string
    ): Promise<string> => {
      setLoading(true);
      return await regenerateSectionContentFromPromptMutation({
        variables: { lessonId, pageId, sectionOrder, question },
      })
        .then((result) => {
          const res = result.data?.regenerateSection?.data;

          const maybeError = result.data?.regenerateSection?.error;
          if (!res) {
            if (maybeError) {
              throw new Error(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to regenerate section with error ${maybeError.message}`
              );
            } else {
              throw new Error("Failed to regenerate section");
            }
          }
          return res;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const summarizeClarificationChat = React.useCallback(
    async (
      clarificationId: string,
      pageId: string,
      sectionId: string
    ): Promise<string> => {
      setLoading(true);
      return await summarizeClarificationChatMutation({
        variables: { clarificationId, pageId, sectionId },
      })
        .then((result) => {
          const res = result.data?.summarizeSectionClarification?.data;

          const maybeError = result.data?.summarizeSectionClarification?.error;
          if (!res) {
            if (maybeError) {
              throw new Error(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to summarize chat with error ${maybeError.message}`
              );
            } else {
              throw new Error("Failed to summarize");
            }
          }
          return res;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const clarifyTextWithChamAI = React.useCallback(
    async (
      text: string,
      lessonId: string,
      sectionId?: string,
      question?: string,
      clarificationType?: ClarificationType,
      chatId?: string
    ): Promise<string> => {
      setLoading(true);
      return await clarifyTextWithChamAIMutation({
        variables: {
          text,
          lessonId,
          question,
          clarificationType,
          clarificationId: chatId,
          sectionId,
        },
      })
        .then((result) => {
          const maybeClarificationId =
            result.data?.clarifyText?.data?.clarificationId;
          const maybeError = result.data?.clarifyText?.error?.message;
          if (!maybeClarificationId) {
            if (maybeError) {
              throw new Error(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to clarify with error ${maybeError}`
              );
            }
            throw new Error("Error occured while clarifying the text.");
          }
          return maybeClarificationId;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const clarifySectionWithChamAI = React.useCallback(
    async (
      lessonId: string,
      sectionId: string,
      pageId: string,
      question: string,
      text?: string
    ): Promise<string> => {
      setLoading(true);
      return await clarifySectionWithChamAIMutation({
        variables: {
          lessonId,
          question,
          sectionId,
          pageId,
          text,
        },
      })
        .then((result) => {
          const maybeClarificationId =
            result.data?.createSectionClarification?.data;
          const maybeError =
            result.data?.createSectionClarification?.error?.message;
          if (!maybeClarificationId) {
            if (maybeError) {
              throw new Error(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to clarify with error ${maybeError}`
              );
            }
            throw new Error("Error occured while clarifying the text.");
          }
          return maybeClarificationId;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const getUserClarificationsForLesson = React.useCallback(
    async (lessonId: string): Promise<Clarification[]> => {
      setLoading(true);
      return await getUserClarificationsQuery({
        variables: { lessonId },
        fetchPolicy: "network-only",
      })
        .then((result) => {
          const maybeClarifications =
            result.data?.clarifications?.data?.clarifications;
          const maybeError = result.data?.clarifications?.error?.message;
          if (maybeError ?? !maybeClarifications) {
            throw new Error(
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `Failed to get user clarifications with error ${maybeError}`
            );
          }
          return maybeClarifications as Clarification[];
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const getClarification = React.useCallback(
    async (clarificationId: string): Promise<ClarificationWithMessage> => {
      setLoading(true);
      return await getClarificationQuery({
        variables: { clarificationId },
      })
        .then((result) => {
          const maybeClarification = result?.data?.clarification?.data;
          const maybeError = result.error?.message;
          if (maybeError ?? !maybeClarification) {
            throw new Error(
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `Failed to get clarifications with error ${maybeError}`
            );
          }
          return maybeClarification as ClarificationWithMessage;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  return {
    loading,
    generateSectionContentFromPrompt,
    clarifyTextWithChamAI,
    clarifySectionWithChamAI,
    getUserClarificationsForLesson,
    getClarification,
    generateNextSectionInLesson,
    regenerateSectionContentFromPrompt,
    regenerateSectionContentFromClarification,
    summarizeClarificationChat,
  };
}

export default useAILessonCreatorClient;
