import React from "react";
import { ILessonVideoContent } from "../../../types/lesson";
import styles from "./LessonSectionPreview.module.css";

interface IVideoPreviewProps {
  canResize?: true;
  content: ILessonVideoContent;
  width: number;
  setWidth: (width: number) => void;
  height: number;
  setHeight: (height: number) => void;
}

interface IVideoPreviewPublicProps {
  canResize?: false;
  content: ILessonVideoContent;
}

function VideoPreview(
  props: IVideoPreviewProps | IVideoPreviewPublicProps,
): React.JSX.Element {
  if (!props.canResize) {
    const iFrameLink = props.content.videoLink.includes(
      "docs.google.com/presentation",
    )
      ? props.content.videoLink.replace("/pub?", "/embed?")
      : props.content.videoLink;
    return (
      <>
        <div className={styles.videoPreview}>
          <iframe src={iFrameLink} allowFullScreen={true}></iframe>
        </div>
      </>
    );
  }

  const adjustPreviewWidth = React.useCallback(() => {
    document.onmousemove = (event) => {
      event.preventDefault();
      props.setWidth(event.clientX - 12.5);
    };
    document.onmouseup = (event) => {
      event.preventDefault();
      document.onmousemove = null;
    };
  }, []);
  return (
    <>
      <div className={styles.videoPreview}>
        <iframe
          src={props.content.videoLink}
          allowFullScreen={true}
          style={{ width: `${props.width}px` }}
        ></iframe>
        <div
          style={{ width: "5px", backgroundColor: "#eee" }}
          onMouseDown={adjustPreviewWidth}
        ></div>
      </div>
    </>
  );
}

export default VideoPreview;
