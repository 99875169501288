import React, { useState } from "react";
import ForgotPasswordForm from "./components/PasswordResetRequestForm";
import HeaderComponent from "../../common_components/MainHeaderComponent";
import FooterComponent from "../../common_components/FooterComponent";
import styles from "./PasswordResetRequest.module.css";
import ErrorComponent from "../../common_components/ErrorComponent";
import useAuthApiClient from "../../../hooks/useAuthApiClient";

function RequestPasswordResetForm(): React.JSX.Element {
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const { requestPasswordReset } = useAuthApiClient();
  const [error, setError] = useState("");

  const submit = (email: string): void => {
    requestPasswordReset(email)
      .then(() => setIsEmailSent(true))
      .catch((e: Error) => {
        setError(e.message);
      });
  };

  const headerElements = (
    <>
      <span className={styles.signupButtonLeadingText}>New to Chameleon?</span>
      <a href="/signup" className={styles.signupButton}>
        Create an account
      </a>
    </>
  );

  return (
    <div className={styles.container}>
      <HeaderComponent headerElements={headerElements} />
      <div className={styles.formContainer}>
        {error && (
          <ErrorComponent
            title="Password Reset Failed"
            close={() => setError("")}
          >
            {error}
          </ErrorComponent>
        )}
        {isEmailSent ? (
          <>
            <h1>Check your email</h1>
            <div className={styles.textComponent}>
              We have sent you an email with a link to reset your password. To
              continue, complete this verification step.
            </div>
          </>
        ) : (
          <ForgotPasswordForm submit={submit} />
        )}
      </div>
      <FooterComponent />
    </div>
  );
}

export default RequestPasswordResetForm;
