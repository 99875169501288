import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IIcon {
  className?: string;
  color?: string;
  height?: string;
}
export const FileIcon: React.FC<IIcon> = ({ className, color, height }) => {
  return (
    <FontAwesomeIcon
      icon={faFileCirclePlus}
      color={color}
      className={className}
    />
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={className}
    //   height={height ?? "1em"}
    //   viewBox="0 0 576 576"
    // >
    //   <path
    //     d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm16 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H448V304z"
    //     fill={color}
    //   />
    // </svg>
  );
};
