import React from "react";
import styles from "./ContextMenu.module.css";

interface ContextMenuProps {
  top: number;
  left: number;
  handleCopy: () => void;
  handlePaste: () => void;
  handleClarifyWithCham: () => void;
}

export const ContextMenu: React.FC<ContextMenuProps> = ({
  top,
  left,
  handleCopy,
  handlePaste,
  handleClarifyWithCham,
}: ContextMenuProps) => {
  return (
    <div
      className={styles.contextMenu}
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      <ul className={styles.list}>
        <li className={styles.item} onClick={handleCopy}>
          Copy
        </li>
        <li className={styles.item} onClick={handlePaste}>
          Paste
        </li>
        <li className={styles.item} onClick={handleClarifyWithCham}>
          Clarify With ChamAI
        </li>
      </ul>
    </div>
  );
};
