import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightFromBracket,
  faBullhorn,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { FEEDBACK_URL } from "../FeedbackButton";
import styles from "./SidebarComponent.module.css";

export default function SideBarComponent(): JSX.Element {
  const { signedInUser } = useUserApiClient();
  const history = useHistory();
  const location = useLocation();
  const isActive = (queryUrl: string): string => {
    if (queryUrl === location.pathname) {
      return styles.active;
    } else if (
      (location.pathname === "/" || location.pathname === "") &&
      queryUrl === "/menu"
    ) {
      return styles.active;
    }
    return "";
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button
          onClick={() => {
            history.push("/menu");
          }}
          className={`${styles.button} ${isActive("/menu")}`}
        >
          <FontAwesomeIcon icon={faHome} className={styles.buttonIcon} />
          <div className={styles.buttonText}>Home</div>
        </button>
        {/* Deprecated tabs */}
        {/* <button
          onClick={() => {
            history.push("/lesson-creation");
          }}
          className={`${styles.button} ${isActive("/lesson-creation")}`}
        >
          <FontAwesomeIcon icon={faScroll} className={styles.buttonIcon} />
          <div className={styles.buttonText}>Lesson Creator</div>
        </button>
        <button
          onClick={() => {
            history.push("/saved-lessons");
          }}
          className={`${styles.button} ${isActive("/saved-lessons")}`}
        >
          <FontAwesomeIcon icon={faFolderOpen} className={styles.buttonIcon} />
          <div className={styles.buttonText}>Saved lessons</div>
        </button>
        <button
          onClick={() => {
            history.push("/analytics");
          }}
          className={`${styles.button} ${isActive("/analytics")}`}
        >
          <FontAwesomeIcon icon={faChartLine} className={styles.buttonIcon} />
          <div className={styles.buttonText}>Analytics</div>
        </button> */}
        <button
          onClick={() => {
            history.push(`/profile/${signedInUser?.id ?? ""}`);
          }}
          className={`${styles.button} ${isActive(
            `/profile/${signedInUser?.id ?? ""}`,
          )}`}
        >
          <FontAwesomeIcon icon={faUser} className={styles.buttonIcon} />
          <div className={styles.buttonText}>Profile</div>
        </button>
        {/* <button className={styles.button}><i className="fas fa-cog"></i><div className={styles.buttonText}>Settings</div></button>
                <button className={styles.button}><i className="fas fa-satellite-dish"></i><div className={styles.buttonText}>Chameleon Live</div></button> */}
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.button}
          onClick={() => {
            window.open(FEEDBACK_URL, "_blank");
          }}
        >
          <FontAwesomeIcon
            icon={faBullhorn}
            className={styles.buttonIcon}
            flip="horizontal"
          />
          <div className={styles.buttonText}>Feedback</div>
        </button>
        <button
          className={`${styles.button} ${styles.buttonRed}`}
          onClick={() => {
            history.push("/logout");
          }}
        >
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className={styles.buttonIcon}
          />
          <div className={styles.buttonText}>Logout</div>
        </button>
      </div>
    </div>
  );
}
