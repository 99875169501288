import React from "react";
import styles from "./SectionEdit.module.css";
import { ContentTypes, ILessonPageSection } from "../../../../types/lesson";
import TextFieldEditor from "./TextFieldEditor";
import SectionEditIcons from "./ContentEditIcons";
import VideoEditor from "./VideoEditor";
import CodeBlockEditor from "./CodeBlockEditor";
import PracticeQuestionsEditor from "./PracticeQuestionEditor";

interface ISectionEditProps {
  section: ILessonPageSection;
  updateSection: (section: ILessonPageSection) => void;
  deleteSection: () => void;
}

function SectionEdit({
  section,
  updateSection,
  deleteSection,
}: ISectionEditProps): React.JSX.Element {
  return (
    <div className={styles.sectionEditWrapper}>
      {section.contentType === ContentTypes.TextField && (
        <TextFieldEditor
          value={section.content.textField?.text ?? ""}
          setValue={(value) =>
            updateSection({
              ...section,
              content: {
                ...section.content,
                textField: { ...section.content.textField, text: value },
              },
            })
          }
        />
      )}
      {section.contentType === ContentTypes.Video && (
        <VideoEditor
          videoLink={section.content.video?.videoLink}
          setVideoLink={(videoLink) => {
            const newSection = { ...section };
            newSection.content.video = {
              dimensions: { width: 640, height: 360 },
              videoLink: videoLink,
            };
            updateSection(newSection);
          }}
        />
      )}
      {section.contentType === ContentTypes.CodeBlock && (
        <CodeBlockEditor
          content={section.content.codeBlock}
          setContent={(content) => {
            const codeBlock = {
              codeLanguage: section.content.codeBlock?.codeLanguage ?? "python",
              codeBlock: section.content.codeBlock?.codeBlock ?? "",
              ...content,
            };
            updateSection({ ...section, content: { codeBlock } });
          }}
        />
      )}
      {section.contentType === ContentTypes.PracticeQuestions && (
        <PracticeQuestionsEditor
          questions={section.content.practiceQuestions?.questions ?? []}
          setQuestions={(questions) =>
            updateSection({
              ...section,
              content: { ...section.content, practiceQuestions: { questions } },
            })
          }
        />
      )}
      <SectionEditIcons
        deleteSection={() => deleteSection()}
        showPreview={() => updateSection({ ...section, showPreview: true })}
        contentType={section.contentType}
        updateContentType={(contentType: ContentTypes) => {
          updateSection({
            ...section,
            contentType,
          });
        }}
      />
    </div>
  );
}

export default SectionEdit;
