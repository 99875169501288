import { apiDomain } from "../config";

export const uploadPublicAsset = async (
  data: FormData,
): Promise<{ assetUrl: string; success: boolean; error: string }> => {
  const response = await fetch(`${apiDomain}/file-service/uploadPublicAsset`, {
    method: "POST",
    body: data,
    credentials: "include",
  });
  const imageUploaded = await response.json().then((data) => data);
  return imageUploaded;
};
