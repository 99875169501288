export enum ViewState {
  Lesson = "lesson",
  IDE = "ide",
  File = "file",
  Terminal = "terminal",
  Clarifications = "clarifications",
  ClarificationChat = "clarification-chat",
  Github = "github",
  Browser = "browser",
}
