import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import { useUserApiClient } from "./hooks/useUserApiClient";
import PasswordResetView from "./views/ForgotPassword/PasswordReset";
import PasswordResetRequestView from "./views/ForgotPassword/PasswordResetRequest";
import LessonView from "./views/Lesson";
import LessonCreationView from "./views/LessonCreation";
import LessonCreationDashboardView from "./views/LessonCreationDashboard";
import LoginView from "./views/Login";
import LogoutComponent from "./views/Logout";
import MenuView from "./views/Menu";
import OnboardingView from "./views/Onboarding";
import SavedLessonsView from "./views/SavedLessonsDashboard";
import SignupView from "./views/Signup";
import UserProfile from "./views/UserProfile";
import AnalyticsView from "./views/Analytics";
import { SavedLessonProvider } from "./views/common_components/SavedLessonContext/SavedLessonContext";
import LessonOutlineView from "./views/LessonOutline";
import { PromptOutlineProvider } from "./views/common_components/PromptOutlineContext/PromptOutlineContext";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SubscriptionCheckoutPage } from "./views/Subscription/Checkout/Checkout";
import { SubscriptionPage } from "./views/Subscription";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  // TODO: replace with cham
  // TODO: Conditional to check environment
  "pk_test_51OTTBZCvw19Je3sXQhb3PLqCjJtEYLkYLcV4gtiSP6kJqw0fiUPZHgwq7TtrLWwg2fu4EBnXPc7Qsy2AWMejeyVH003i2lOhvf",
  {},
);

function App(): JSX.Element {
  const [lessonsSaved, setLessonsSaved] = React.useState<Set<string>>(
    new Set(),
  );
  const { fetchUserBookmarkedLessons, createCheckoutSession } =
    useUserApiClient();
  const fetchClientSecret = React.useCallback(async () => {
    return await createCheckoutSession().then(
      (session) => session.clientSecret,
    );
  }, []);
  const options = { fetchClientSecret };

  const [prompt, setPrompt] = React.useState("");
  const [outline, setOutline] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetchUserBookmarkedLessons()
      .then((lessons) =>
        setLessonsSaved(new Set(lessons.bookmarkedLessons ?? [])),
      )
      .catch(() => setLessonsSaved(new Set([])));
  }, []);

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <SavedLessonProvider
        savedLessons={new Set(lessonsSaved)}
        setSavedLessons={setLessonsSaved}
      >
        <PromptOutlineProvider
          outline={outline}
          setOutline={setOutline}
          prompt={prompt}
          setPrompt={setPrompt}
        >
          <div className="App">
            <Router>
              <Switch>
                <Route path="/login">
                  <LoginView />
                </Route>
                <Route path="/signup">
                  <SignupView />
                </Route>
                <Route path="/analytics">
                  <AnalyticsView />
                </Route>
                <Route path="/finish-setup">
                  <OnboardingView />
                </Route>
                <Route path="/lesson/:lessonId">
                  <LessonView />
                </Route>
                <Route path="/lesson-creation/:lessonId">
                  <LessonCreationView />
                </Route>
                <Route path="/lesson-outline-generated/:lessonId">
                  <LessonOutlineView />
                </Route>
                <Route path="/lesson-creation">
                  <LessonCreationDashboardView />
                </Route>
                <Route path="/profile/:userId">
                  <UserProfile />
                </Route>
                <Route path="/logout">
                  <LogoutComponent />
                </Route>
                <Route path="/forgotpassword">
                  <PasswordResetRequestView />
                </Route>
                <Route path="/passwordReset">
                  <PasswordResetView />
                </Route>
                <Route path="/saved-lessons">
                  <SavedLessonsView />
                </Route>
                <Route path="/subscriptions">
                  <SubscriptionPage />
                </Route>
                <Route path="/subscription/checkout">
                  <SubscriptionCheckoutPage>
                    <EmbeddedCheckout />
                  </SubscriptionCheckoutPage>
                </Route>
                <Route path="/">
                  <MenuView />
                </Route>
              </Switch>
            </Router>
          </div>
        </PromptOutlineProvider>
      </SavedLessonProvider>
    </EmbeddedCheckoutProvider>
  );
}

export default App;
