import React from "react";

export interface TerminalSessionContextValue {
  terminalSessionId?: string;
  setTerminalSessionId?: (terminalSessionId?: string) => void;
  programToRun?: string;
  setProgramToRun?: (programToRun?: string) => void;
  terminalConnected: boolean;
  setTerminalConnected?: (terminalConnected: boolean) => void;
}

export const TerminalSessionContext =
  React.createContext<TerminalSessionContextValue>({terminalConnected: false});

interface TerminalSessionProviderProps {
  children: React.ReactNode;
}

export function TerminalSessionProvider({
  children,
}: TerminalSessionProviderProps): React.ReactNode {
  const [terminalSessionId, setTerminalSessionId] = React.useState<string>();
  const [programToRun, setProgramToRun] = React.useState<string>();
  const [terminalConnected, setTerminalConnected] = React.useState<boolean>(false);
  return (
    <TerminalSessionContext.Provider
      value={{
        terminalSessionId,
        setTerminalSessionId,
        programToRun,
        setProgramToRun,
        terminalConnected,
        setTerminalConnected,
      }}
    >
      {children}
    </TerminalSessionContext.Provider>
  );
}

export function useTerminalSession(): TerminalSessionContextValue {
  const {
    terminalSessionId,
    setTerminalSessionId,
    programToRun,
    setProgramToRun,
    terminalConnected, 
    setTerminalConnected
  } = React.useContext(TerminalSessionContext);
  return {
    terminalSessionId,
    setTerminalSessionId,
    programToRun,
    setProgramToRun,
    terminalConnected, 
    setTerminalConnected
  };
}
