import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { ILessonCodeBlockContent } from "../../../types/lesson";
import styles from "./LessonSectionPreview.module.css";
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface ICodeBlockPreviewProps {
  content: ILessonCodeBlockContent;
  handleClarifyWithChamAi?: (
    e: React.MouseEvent,
    maybeText?: string
  ) => Promise<void>;
}

function CodeBlockPreview({
  content,
  handleClarifyWithChamAi,
}: ICodeBlockPreviewProps): React.JSX.Element {
  const handleCopyToClipboard = React.useCallback(() => {
    void navigator.clipboard.writeText(content.codeBlock.replace(/\\n/g, "\n"));
  }, [content.codeBlock]);
  return (
    <>
      <div className={styles.codeBlockPreview} id="codeSection">
        <SyntaxHighlighter
          customStyle={{ margin: 0 }}
          language={content.codeLanguage}
          theme={a11yDark}
        >
          {content.codeBlock.replace(/\\n/g, "\n")}
        </SyntaxHighlighter>
        <div className={styles.icons}>
          <FontAwesomeIcon
            icon={faCopy}
            className={styles.copyIcon}
            onClick={handleCopyToClipboard}
          />
          <FontAwesomeIcon
            icon={faMagicWandSparkles}
            className={styles.wandIcon}
            onClick={(e) => {
              void handleClarifyWithChamAi?.(
                e,
                "Kindly clarify what the following code does " +
                  content.codeBlock.replace(/\\n/g, "\n")
              );
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CodeBlockPreview;
