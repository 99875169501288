import React, { useEffect } from "react";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";
import useAuthApiClient from "../../hooks/useAuthApiClient";

export default function Logout(): React.JSX.Element {
  const { signOut } = useAuthApiClient();
  useEffect(() => {
    signOut()
      .then(() => {
        window.location.href = "/login";
      })
      .catch(() => {
        alert("Unable to logout. Please try again.");
        window.location.href = "/menu";
      });
  }, []);

  return <LoadingComponentFullScreen />;
}
