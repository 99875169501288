import { faBookmark as faBookmarkTransparent } from "@fortawesome/free-regular-svg-icons";
import {
  faBookmark,
  faCircle,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appDomain } from "../../../config";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { User } from "../../../types/auth";
import { ILesson } from "../../../types/lesson";
import { CircularProgressBarComponent } from "../ProgressBarComponent";
import { useSavedLessonIds } from "../SavedLessonContext/SavedLessonContext";
import styles from "./LessonCardHorizontal.module.css";
import {
  calculateTimeAgo,
  hashString,
  selectRandomLessonImageMobile,
} from "./utils";
interface LessonCardProps {
  lesson: ILesson;
  clickUrl: string;
  showLessonEditModal?: (lessonId: string) => void;
  canBeDeleted?: boolean;
}

export const LessonCardHorizontal: React.FC<LessonCardProps> = ({
  lesson,
  clickUrl,
  showLessonEditModal,
  canBeDeleted = false,
}: LessonCardProps) => {
  const [author, setAuthor] = useState<User | undefined>();
  const [pagesCompleted, setPagesCompleted] = useState<string[]>();
  const { savedLessons, setSavedLessons } = useSavedLessonIds();
  const {
    fetchUserById,
    fetchUsersLessonPagesCompleted,
    bookmarkLesson,
    removeBookmarkedLesson,
    removeEnrolledLesson,
  } = useUserApiClient();
  const lessonProgress = React.useMemo(() => {
    const progress = Math.floor(
      ((pagesCompleted?.length ?? 0) / Math.max(lesson.pages.length, 1)) * 100,
    );
    // Hacky hack if lesson 100% complete unenrol
    if (progress === 100) {
      void removeEnrolledLesson(lesson.title);
    }
    return progress;
  }, [pagesCompleted?.length, lesson.pages]);

  useEffect(() => {
    fetchUserById(lesson.authorId)
      .then((author) => setAuthor(author))
      .catch(() => setAuthor(undefined));
    const pageIds = new Set(lesson.pages.map((page) => page.id));
    fetchUsersLessonPagesCompleted(lesson.id)
      .then(({ pagesCompleted }) =>
        setPagesCompleted(
          pagesCompleted?.filter((pageId) => pageIds.has(pageId)) ?? undefined,
        ),
      )
      .catch(() => setPagesCompleted(undefined));
  }, [
    fetchUserById,
    fetchUsersLessonPagesCompleted,
    lesson.authorId,
    lesson.id,
    lesson.pages,
  ]);

  const defaultImage = React.useMemo(() => {
    const titleHash = hashString(lesson.id);
    return selectRandomLessonImageMobile(titleHash);
  }, [lesson.title]);

  const handleToggleBookmark = React.useCallback(async () => {
    if (savedLessons.has(lesson.id)) {
      const removedLesson = await removeBookmarkedLesson(lesson.id);
      if (removedLesson.success) {
        savedLessons.delete(lesson.id);
        setSavedLessons(savedLessons);
      } else {
        console.error("Failed to remove bookmark");
      }
    } else {
      const addedLesson = await bookmarkLesson(lesson.id);
      if (addedLesson.success) {
        savedLessons.add(lesson.id);
        setSavedLessons(savedLessons);
      } else {
        console.error("Failed to bookmark lesson");
      }
    }
  }, [savedLessons]);

  const bookmarkIcon = savedLessons?.has(lesson.id)
    ? faBookmark
    : faBookmarkTransparent;

  const handleShareLesson = React.useCallback(async () => {
    const lessonUrl = `${appDomain}/lesson/${lesson.id}`;
    try {
      await navigator.clipboard.writeText(lessonUrl);
      alert(
        "Lesson URL successfully copied to clipboard!\n📋 Share it with anyone! 😊",
      );
    } catch (error) {
      console.error("Failed to copy lesson URL:", error);
      alert("Oops! Something went wrong. Please try again.");
    }
  }, [lesson.id, lesson.title]);

  return (
    <Link to={clickUrl} className={styles.lessonCard}>
      {/* TODO enable icons and images with backend */}
      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <img
            src={lesson.imageUrl ? lesson.imageUrl : defaultImage}
            className={styles.imgElem}
          />
          <div className={styles.mobileBookmark}>
            <div
              className={styles.bookmark}
              onClick={(e) => {
                e.preventDefault();
                void handleToggleBookmark();
              }}
            >
              <FontAwesomeIcon
                icon={bookmarkIcon}
                className={styles.bookmarkIcon}
              />
              32
            </div>
          </div>
        </div>
      </div>
      <div className={styles.lessonText}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {lesson.title || "Untitled Lesson"}
          </div>
          {!canBeDeleted && lessonProgress > 0 && (
            <div className={styles.innerProgressContainer}>
              <CircularProgressBarComponent
                value={lessonProgress}
                innerCircleComponent={<div>{lessonProgress}%</div>}
              />
            </div>
          )}
        </div>
        <div className={styles.author}>
          Uploaded by
          {` ${author?.firstName ?? "Anonymous"} ${author?.lastName ?? "User"}`}
          {/* Todo get from backend */}
          <FontAwesomeIcon
            icon={faCircle}
            style={{ color: "#c1c3c2", fontSize: "6px" }}
          />
          {calculateTimeAgo(lesson.createdDate ?? "")}
        </div>
        <div
          className={styles.mobileShare}
          onClick={(e) => {
            e.preventDefault();
            void handleShareLesson();
          }}
        >
          <FontAwesomeIcon
            icon={faShareNodes}
            style={{ color: "#29834D" }}
            className={styles.share}
          />
        </div>
      </div>
    </Link>
  );
};
