import { useState, useEffect } from "react";

interface IContextMenu {
  clicked: boolean;
  setClicked: (clicked: boolean) => void;
  points: {
    x: number,
    y: number,
  };
  setPoints: (points: {x: number, y: number}) => void;
}

const useContextMenu = (): IContextMenu => {
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const handleClick = (): void => setClicked(false);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return {
    clicked,
    setClicked,
    points,
    setPoints,
  };
};
export default useContextMenu;