import { Monaco } from "@monaco-editor/react";
import React from "react";

export interface MonacoContextValue {
  monaco?: Monaco;
  setMonaco?: (monaco: Monaco) => void;
  refreshMonaco?: boolean;
  setRefreshMonaco?: (refresh: boolean) => void;
}

export const MonacoContext = React.createContext<MonacoContextValue>({});

interface MonacoProviderProps {
  children: React.ReactNode;
}

export function MonacoProvider({
  children,
}: MonacoProviderProps): React.ReactNode {
  const [monaco, setMonaco] = React.useState<Monaco>();
  const [refreshMonaco, setRefreshMonaco] = React.useState<boolean>(false);
  return (
    <MonacoContext.Provider
      value={{
        monaco,
        setMonaco,
        refreshMonaco,
        setRefreshMonaco,
      }}
    >
      {children}
    </MonacoContext.Provider>
  );
}

export function useMonaco(): MonacoContextValue {
  const { monaco, setMonaco, refreshMonaco, setRefreshMonaco } = React.useContext(MonacoContext);
  return {
    monaco,
    setMonaco,
    refreshMonaco,
    setRefreshMonaco
  };
}
