import React, { useState } from "react";
import styles from "./SectionEdit.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import SectionTypeIcons from "./ContentTypeIcons";
import { ContentTypes } from "../../../../types/lesson";

interface IContentEditIconsProps {
  deleteSection: () => void;
  showPreview: () => void;
  contentType: ContentTypes;
  updateContentType: (contentType: ContentTypes) => void;
}

function ContentEditIcons({
  deleteSection,
  showPreview,
  contentType,
  updateContentType,
}: IContentEditIconsProps): React.JSX.Element {
  const [showContentTypePopOver, setShowContentTypePopOver] = useState(false);
  return (
    <>
      <div className={styles.sectionEditIcons}>
        <button onClick={() => showPreview()} className={styles.icon}>
          <FontAwesomeIcon icon={faEye} />
        </button>
        <button
          onClick={() => setShowContentTypePopOver(!showContentTypePopOver)}
          className={styles.icon}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
          {showContentTypePopOver && (
            <div className={styles.sectionTypePopOverContainer}>
              <div className={styles.sectionTypePopOver}>
                <SectionTypeIcons
                  activeContentType={contentType}
                  updateContentType={(content: ContentTypes) => {
                    updateContentType(content);
                    setShowContentTypePopOver(false);
                  }}
                />
              </div>
              <span className={styles.sectionTypePopOverPointer}></span>
            </div>
          )}
        </button>
        <button onClick={() => deleteSection()}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            className={`${styles.icon} ${styles.sectionDeleteIcon}`}
          />
        </button>
      </div>
    </>
  );
}

export default ContentEditIcons;
