import React from "react";
import styles from "./DashboardComponent.module.css";
import HeaderComponent from "../LoggedOutDashboardHeaderComponent";
import LoggedOutSideBarComponent from "../LoggedOutSideBarComponent";

interface ILoggedOutDashboardComponent {
  children: React.ReactNode;
}

export default function LoggedOutDashboardComponent(
  props: ILoggedOutDashboardComponent,
): JSX.Element {
  return (
    <div className={styles.container}>
      <HeaderComponent />
      <LoggedOutSideBarComponent />
      <div className={styles.mainWindow}>{props.children}</div>
    </div>
  );
}
