import React from "react";
import { Link } from "react-router-dom";
import styles from "./LessonViewButton.module.css";

interface LessonViewButtonProps {
  lessonId: string;
  shouldStyle?: boolean;
}

function LessonViewButton({
  lessonId,
  shouldStyle,
}: LessonViewButtonProps): JSX.Element {
  return (
    <Link
      to={`/lesson/${lessonId}`}
      className={shouldStyle ? styles.lessonEditButton : ""}
    >
      View Lesson
    </Link>
  );
}

export default LessonViewButton;
