import React from "react";
import CheckLoginComponent from "../CheckLoginComponent";
import HeaderComponent from "../DashboardHeaderComponent";
import OnboardingCheckComponent from "../OnboardingCheckComponent/OnboardingCheckComponent";
import SideBarComponent from "../SideBarComponent";
import styles from "./DashboardComponent.module.css";

interface DashboardComponentProps {
  children: React.ReactNode;
  headerText?: string;
}

export default function DashboardComponent(
  props: DashboardComponentProps,
): JSX.Element {
  return (
    <CheckLoginComponent>
      <OnboardingCheckComponent>
        <div className={styles.container}>
          <HeaderComponent showBrowser={false} toggleShowBrowser={() => {return null;}}  headerText={props.headerText} />
          <SideBarComponent />
          <div className={styles.mainWindow}>{props.children}</div>
        </div>
      </OnboardingCheckComponent>
    </CheckLoginComponent>
  );
}
