import React, { useEffect, useState } from "react";
import HeaderComponent from "../../common_components/MainHeaderComponent";
import styles from "./LessonOutline.module.css";
import FooterComponent from "../../common_components/FooterComponent";
import { useHistory, useParams } from "react-router-dom";
import useLessonApiClient from "../../../hooks/useLessonApiClient";
import { ILesson } from "../../../types/lesson";
import { LoadingComponentContained } from "../../common_components/LoadingComponent";
import { usePromptOutlineProvider } from "../../common_components/PromptOutlineContext/PromptOutlineContext";

interface Page {
  title: string;
  id?: string;
  order: number;
}

export const LessonOutline: React.FC = () => {
  const history = useHistory();
  const { lessonId } = useParams<{ lessonId: string }>();
  const [lessonPageTitles, setLessonPageTitles] = useState<Page[]>([]);
  const { fetchLesson } = useLessonApiClient();
  // const {generatePageContentFromLessonOutline} = useAILessonCreatorClient();
  const [lesson, setLesson] = React.useState<ILesson | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { prompt, outline } = usePromptOutlineProvider();
  useEffect(() => {
    fetchLesson(lessonId)
      .then((result) => {
        setLesson({
          ...result,
          pages:
            outline?.map((item, idx) => ({
              order: idx,
              lessonId,
              title: item,
              sections: [],
            })) ?? [],
        });
      })
      .catch((error) => {
        console.error("Something went wrong", error);
      });
    setLessonPageTitles(
      outline?.map((page, idx) => ({ title: page, order: idx })) ?? [],
    );
  }, [lessonId]);

  const handleGenerateLesson = React.useCallback(async () => {
    setIsLoading(true);
    if (lesson) {
      // await generatePageContentFromLessonOutline(lesson, prompt ?? "");
      setIsLoading(false);
      history.push(`/lesson/${lesson?.id}`);
    }
  }, [lesson]);

  return (
    <div className={styles.container}>
      <HeaderComponent headerElements={undefined} />
      <div className={styles.mainWindow}>
        <div className={styles.promptSection}>
          <div className={styles.outlineTitle}>Prompt</div>
          <div>{prompt}</div>
        </div>
        <div className={styles.generatedOutline}>
          <div className={styles.outlineTitle}>
            Outline of content to be generated
          </div>
          <div className={styles.outlines}>
            {!isLoading ? (
              lessonPageTitles.map((page) => {
                return (
                  <div className={styles.outline} key={page.id}>
                    <div>{page.order + 1}.</div>
                    <div>{page.title}</div>
                  </div>
                );
              })
            ) : (
              <LoadingComponentContained />
            )}
          </div>

          <div>
            <button
              onClick={() => {
                void handleGenerateLesson();
              }}
              className={styles.generateButton}
            >
              Generate lesson
            </button>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};
