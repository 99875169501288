import React from "react";
import styles from "./ChamIDEContainer.module.css";

import ErrorComponent from "../../../ErrorComponent";
import { useLessonId } from "../../../LessonContext/LessonContext";
import { useDirectoryTree } from "../../contexts/DirectoryContext/DirectoryContext";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";
import { getSessionDirectory } from "../utils/getSessionDirectory";
import { getUserSessionForLesson } from "../utils/getUserSessionForLesson";
import { DesktopIdeContainer } from "./DesktopIdeContainer";

export const APP_HEADER_HEIGHT = 64;

interface IContainerProps {
  toggleShowChamBrowser: () => void;
}

export const ChamIDEContainer: React.FC<IContainerProps> = ({
  toggleShowChamBrowser,
}) => {
  const lessonId = useLessonId();

  const { terminalSessionId, setTerminalSessionId } = useTerminalSession();

  const { setDirectoryTree } = useDirectoryTree();

  const [showTerminal, setShowTerminal] = React.useState(false);

  const [error, setError] = React.useState<string | undefined>();

  React.useEffect(() => {
    const getSessionId = async (): Promise<void> => {
      if (
        lessonId != null &&
        setTerminalSessionId != null &&
        !terminalSessionId
      ) {
        const sessionId = await getUserSessionForLesson(lessonId);
        if (sessionId && sessionId !== "null") {
          setTerminalSessionId(sessionId);
        }
      }
    };
    void getSessionId();
  }, [lessonId, setTerminalSessionId, terminalSessionId]);

  React.useEffect(() => {
    const getDir = async (): Promise<void> => {
      if (terminalSessionId && setDirectoryTree) {
        try {
          const directoryTree = await getSessionDirectory(terminalSessionId);
          setDirectoryTree(directoryTree);
        } catch (e: any) {
          setError(e.message);
        }
      }
    };
    void getDir();
  }, [terminalSessionId, setDirectoryTree]);

  const handleToggleTerminal = React.useCallback(() => {
    setShowTerminal(!showTerminal);
  }, [showTerminal, setShowTerminal]);

  return (
    <>
      {error && (
        <div className={styles.errorComponentContainer}>
          <ErrorComponent
            title="Something went wrong"
            close={() => setError("")}
          >
            {error}
          </ErrorComponent>
        </div>
      )}
      <div className={styles.container}>
        <DesktopIdeContainer
          showTerminal={showTerminal}
          setShowTerminal={setShowTerminal}
          setError={setError}
          handleToggleTerminal={handleToggleTerminal}
          toggleShowChamBrowser={toggleShowChamBrowser}
        />
      </div>
    </>
  );
};
