import React, { createContext, useContext } from "react";

export interface WorkspaceHighlightContextValue {
  isWorkspaceHighlighted: boolean;
  setIsWorkspaceHighlighted?: (value: boolean) => void;
}

export const WorkspaceHighlightContext =
  createContext<WorkspaceHighlightContextValue>({
    isWorkspaceHighlighted: false,
  });

interface WorkspaceHighlightProviderProps {
  children: React.ReactNode;
}

export function WorkspaceHighlightProvider({
  children,
}: WorkspaceHighlightProviderProps): React.ReactNode {
  const [isWorkspaceHighlighted, setIsWorkspaceHighlighted] =
    React.useState(false);

  return (
    <WorkspaceHighlightContext.Provider
      value={{ isWorkspaceHighlighted, setIsWorkspaceHighlighted }}
    >
      {children}
    </WorkspaceHighlightContext.Provider>
  );
}

export function useWorkspaceHighlight(): WorkspaceHighlightContextValue {
  const { isWorkspaceHighlighted, setIsWorkspaceHighlighted } = useContext(
    WorkspaceHighlightContext,
  );

  if (setIsWorkspaceHighlighted === undefined) {
    throw new Error(
      "useWorkspaceHighlight must be used within a WorkspaceHighlightProvider",
    );
  }

  return { isWorkspaceHighlighted, setIsWorkspaceHighlighted };
}
