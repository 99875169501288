import React from "react";

export default function IconLogo({ height }: { height?: string }): JSX.Element {
  return (
    <svg
      height={height ?? "60"}
      viewBox="0 0 1080 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_107_15)">
        <path
          d="M652.367 763.8C644.16 763.8 636.037 762.226 628.244 759.08L506.137 710.561L681.464 640.764L686.853 644.158C705.836 656.081 717.193 676.615 717.193 698.97C717.193 734.738 688.096 763.8 652.367 763.8ZM573.366 710.561L637.529 736.063C642.337 737.967 647.311 738.961 652.367 738.961C674.418 738.961 692.324 720.994 692.324 698.97C692.324 687.213 687.267 676.283 678.48 668.749L573.366 710.561Z"
          fill="#29834D"
        />
        <path
          d="M401.437 668.915L309.587 632.401C271.538 617.249 247 580.985 247 540.083C247 520.212 252.886 500.92 264.16 484.361C275.268 467.884 291.018 455.216 309.587 447.847L628.824 320.837C633.632 319.016 638.357 317.691 643.083 316.946C661.569 314.131 680.469 319.513 694.81 331.849C709.069 344.02 717.193 361.822 717.193 380.617C717.193 402.972 705.338 424.002 686.189 435.511C683.039 437.498 679.392 439.319 675.578 440.727L413.54 539.586L493.205 569.641L475.962 638.777L413.375 662.374C411.053 663.202 408.898 664.361 406.826 665.603L401.52 668.832L401.437 668.915ZM652.782 341.04C650.792 341.04 648.885 341.205 646.896 341.454C643.995 341.95 640.927 342.778 637.943 343.938L318.872 470.865C304.945 476.412 293.174 485.934 284.801 498.271C276.428 510.69 271.952 525.18 271.952 540.083C271.952 570.718 290.355 597.958 318.872 609.301L399.531 641.343C401.189 640.598 402.847 639.853 404.505 639.191L454.906 620.147L463.693 585.042L397.375 560.037C388.754 556.808 383.2 548.777 383.2 539.586C383.2 530.396 388.754 522.364 397.375 519.135L666.791 417.544C669.112 416.633 671.268 415.557 673.174 414.398C685.111 407.194 692.406 394.278 692.406 380.617C692.406 368.859 687.515 358.261 678.645 350.644C671.351 344.352 662.149 341.04 652.864 341.04H652.782Z"
          fill="#29834D"
        />
        <path
          d="M398.536 439.154L393.23 435.842C374.496 424.168 362.89 403.137 362.89 381.03C362.89 345.262 391.987 316.201 427.716 316.201C436.005 316.201 444.129 317.774 451.839 320.837L573.78 369.522L398.619 439.237L398.536 439.154ZM427.633 341.04C405.582 341.04 387.676 359.007 387.676 381.03C387.676 392.622 392.899 403.717 401.52 411.251L506.634 369.439L442.471 343.938C437.746 342.033 432.772 341.04 427.633 341.04Z"
          fill="#29834D"
        />
        <path
          d="M427.218 763.8C411.965 763.8 397.044 758.335 385.272 748.151C371.014 735.98 362.89 718.179 362.89 699.384C362.89 677.029 374.744 655.998 393.811 644.407C397.127 642.337 400.774 640.598 404.505 639.191L666.542 540.331L586.878 510.276L604.204 441.141L666.708 417.544C669.029 416.633 671.185 415.557 673.091 414.398L678.48 411.003L770.413 447.599C808.462 462.751 833.083 498.933 833.083 539.835C833.083 559.706 827.197 578.997 815.923 595.557C804.649 612.116 788.899 624.784 770.413 632.153L451.176 759.163C446.45 760.984 441.725 762.309 437 763.054C433.767 763.551 430.534 763.8 427.301 763.8H427.218ZM616.307 494.876L682.624 519.881C691.163 523.027 696.717 531.058 696.8 540.249C696.8 549.439 691.246 557.553 682.624 560.782L413.209 662.374C410.888 663.202 408.732 664.361 406.66 665.603C394.723 672.806 387.676 685.474 387.676 699.384C387.676 711.058 392.567 721.739 401.437 729.356C410.224 736.891 421.747 740.285 433.104 738.547C436.005 738.05 439.073 737.305 441.974 736.145L761.128 609.135C775.055 603.671 786.826 594.149 795.282 581.73C803.654 569.31 808.131 554.904 808.131 540C808.131 509.283 789.728 482.125 761.128 470.865L680.552 438.74C678.894 439.568 677.236 440.23 675.495 440.893L625.177 459.936L616.39 495.042L616.307 494.876Z"
          fill="#29834D"
        />
        <path
          d="M465.766 900C430.949 900 402.598 871.766 402.598 837.074C402.598 831.941 403.261 826.725 404.505 821.674L424.98 739.788L433.104 738.546C436.089 738.05 439.073 737.222 442.057 736.145L568.475 685.805L560.849 717.102L527.027 852.226C520.063 880.377 494.863 900 465.849 900H465.766ZM445.207 761.233L428.628 827.718C427.882 830.865 427.467 834.011 427.467 837.074C427.467 858.105 444.627 875.161 465.766 875.161C483.34 875.161 498.593 863.238 502.821 846.265L532.747 726.789L451.093 759.246C449.103 759.991 447.197 760.653 445.207 761.233ZM511.359 394.361L522.716 349.071L573.698 369.522L511.359 394.361Z"
          fill="#29834D"
        />
        <path
          d="M447.528 649.54L488.728 484.692L632.472 430.46L591.189 595.308L447.528 649.54ZM509.701 503.321L483.174 609.549L570.216 576.679L596.826 470.451L509.701 503.321Z"
          fill="#29834D"
        />
        <path
          d="M540.207 382.769L516.582 373.33L552.973 227.774C560.02 199.623 585.22 180 614.234 180C649.051 180 677.402 208.234 677.402 243.008C677.402 248.142 676.739 253.275 675.495 258.408L655.02 340.212L646.896 341.454C643.912 341.95 640.927 342.778 637.86 343.937L540.207 382.852V382.769ZM614.234 204.839C596.66 204.839 581.324 216.762 577.096 233.735L547.253 353.211L628.824 320.754C630.814 320.009 632.803 319.347 634.793 318.767L651.372 252.364C652.118 249.218 652.533 246.072 652.533 243.008C652.533 221.978 635.373 204.839 614.234 204.839Z"
          fill="#29834D"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_15">
          <rect
            width="586"
            height="720"
            fill="white"
            transform="translate(247 180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
