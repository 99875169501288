import React from "react";
import { CppIcon } from "./LanguageIcons/CppIcon";
import { JavaIcon } from "./LanguageIcons/JavaIcon";
import { JavaScriptIcon } from "./LanguageIcons/JavaScriptIcon";
import { PythonIcon } from "./LanguageIcons/PythonIcon";
import { ScalaIcon } from "./LanguageIcons/ScalaIcon";
import { TypeScriptIcon } from "./LanguageIcons/TypeScriptIcon";
import { UnknownFileIcon } from "./UnknownFileIcon";
import { HtmlIcon } from "./LanguageIcons/HtmlIcon";
import { CSSIcon } from "./LanguageIcons/CssIcon";

export interface LanguageExtension {
  ext: "py" | "cpp" | "scala" | "js" | "ts" | "jsx" | "tsx" | "java" | "css" | "html";
}
export const LanguageIcon: React.FC<LanguageExtension> = (
  languageExtension,
) => {
  switch (languageExtension.ext) {
    case "cpp":
      return <CppIcon />;
    case "java":
      return <JavaIcon />;
    case "js":
    case "jsx":
      return <JavaScriptIcon />;
    case "py":
      return <PythonIcon />;
    case "scala":
      return <ScalaIcon />;
    case "ts":
    case "tsx":
      return <TypeScriptIcon />;
    case "css":
      return <CSSIcon/>;
    case "html":
      return <HtmlIcon />;
    default:
      return <UnknownFileIcon />;
  }
};
