import React, { useCallback, useState } from "react";
import ErrorComponent from "../common_components/ErrorComponent";
import HeaderComponent from "../common_components/MainHeaderComponent";
import LoginFormComponent from "./components/LoginFormComponent";
import FooterComponent from "../common_components/FooterComponent";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";
import styles from "./Login.module.css";
import useAuthApiClient from "../../hooks/useAuthApiClient";

interface LoginCredential {
  email: string;
  password: string;
}

export default function Login(): React.JSX.Element {
  const params = new URLSearchParams(location.search);
  const nextUrl = params.get("next");
  const [credential, setCredential] = useState<LoginCredential>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>();
  const authClient = useAuthApiClient();

  const headerElements = (
    <>
      <span className={styles.signupButtonLeadingText}>New to Chameleon?</span>
      <a href="/signup" className={styles.signupButton}>
        Create an account
      </a>
    </>
  );

  const login = useCallback(() => {
    authClient
      .signIn(credential.email, credential.password)
      .then((result) => {
        if (result.success) {
          if (nextUrl) {
            window.location.href = nextUrl;
          } else {
            window.location.href = "/menu";
          }
        } else {
          setError(result.error);
        }
      })
      .catch(() => setError("Unable to login"));
  }, [credential]);

  return (
    <div className={styles.container + " " + styles.bgStandard}>
      {authClient.loading ? (
        <LoadingComponentFullScreen />
      ) : (
        <>
          <HeaderComponent headerElements={headerElements} />
          <div className={styles.innerLoginDiv}>
            {error && (
              <div className={styles.error}>
                <ErrorComponent title="Login Failed" close={() => setError("")}>
                  {error}
                </ErrorComponent>
              </div>
            )}
            <LoginFormComponent
              credential={credential}
              setCredential={setCredential}
              login={login}
            />
          </div>
          <FooterComponent />
        </>
      )}
    </div>
  );
}
