import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./GithubComponent.module.css";
import { apiDomain } from "../../../../config";
import { useTerminalSession } from "../../ChamIDE/contexts/TerminalSessionContext/TerminalSessionContext";
import { useHistory } from "react-router-dom";

const GithubComponent = (): JSX.Element => {
  const history = useHistory();

  const { terminalSessionId: sessionId } = useTerminalSession();
  const [repositoryUrl, setRepositoryUrl] = useState("");
  const [isGithubLoggedIn, setIsGithubLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  const handleGitHubLogin = React.useCallback((): void => {
    const clientId = "Iv23liqVlFvUheNdJXjf";
    const redirectUri = encodeURIComponent(
      window.location.origin + window.location.pathname
    );
    const scope = "repo";
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  }, []);

  const handleGithubDisconnect = React.useCallback((): void => {
    axios
      .post(
        `${apiDomain}/session-service/github/delete-token`,
        {},
        { withCredentials: true }
      )
      .then(() => {
        setIsGithubLoggedIn(false);
      })
      .catch((error) => {
        alert("Error disconnecting GitHub");
        console.error("Error disconnecting Github", error);
      });
  }, []);

  const importRepository = React.useCallback((): void => {
    const splttedRepoUrl = repositoryUrl.split("github.com/");
    if (splttedRepoUrl.length !== 2) {
      alert("Invalid repository URL");
      return;
    }
    setLoading(true);
    axios
      .post(
        `${apiDomain}/session-service/github/import-repo`,
        {
          repoName: splttedRepoUrl[1],
          sessionId,
        },
        { withCredentials: true }
      )
      .then(() => {
        alert("Repository imported successfully");
      })
      .catch((error: Error) => {
        console.error("Error importing repository:", error);
        alert("Error importing repository");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [repositoryUrl, sessionId, setLoading]);

  const storeToken = React.useCallback(async (): Promise<void> => {
    setLoading(true);
    await axios
      .post(
        `${apiDomain}/session-service/github/store-token`,
        { code },
        { withCredentials: true }
      )
      .catch((error) => {
        console.error("Error storing token", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [code, setLoading]);

  const checkIsLoggedIn = React.useCallback(async (): Promise<boolean> => {
    return await axios
      .get(`${apiDomain}/session-service/github/check-token`, {
        withCredentials: true,
      })
      .then((response) => response.status === 200)
      .catch(() => false);
  }, []);

  useEffect(() => {
    async function process(): Promise<void> {
      try {
        let loggedIn = await checkIsLoggedIn();
        if (!loggedIn && code) {
          await storeToken();
          params.delete("code");
          history.replace({
            search: params.toString(),
          });
          loggedIn = await checkIsLoggedIn();
        }
        setIsGithubLoggedIn(loggedIn);
      } catch (error) {
        console.error("Error checking login status", error);
        setIsGithubLoggedIn(false);
      }
    }
    void process();
  }, []);

  return (
    <div className={styles.container}>
      {!sessionId ? (
        <div>No session found</div>
      ) : !isGithubLoggedIn ? (
        <button
          className={styles.loginButton}
          disabled={loading}
          onClick={handleGitHubLogin}
        >
          Connect GitHub
        </button>
      ) : (
        <>
          <input
            className={styles.input}
            type="url"
            placeholder="Enter Repository URL (e.g. github.com/username/repo)"
            value={repositoryUrl}
            onChange={(e) => setRepositoryUrl(e.target.value)}
          />
          <div className={styles.buttonsContainer}>
            <button
              className={styles.importButton}
              disabled={loading}
              onClick={importRepository}
            >
              Import Repository
            </button>
            <button
              className={styles.disconnectButton}
              onClick={handleGithubDisconnect}
              disabled={loading}
            >
              Disconnect
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default GithubComponent;
