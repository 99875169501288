import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FEEDBACK_URL } from "../FeedbackButton";
import Logo from "../Logo/logo";
import styles from "./DashboardHeaderComponent.module.css";
import { DashboardHeaderContext } from "../DashboardHeaderComponent/DashboardHeaderContext";

interface DashboardHeaderProps {
  headerText?: string;
}

export default function DashboardHeaderComponent({
  headerText,
}: DashboardHeaderProps): JSX.Element {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const history = useHistory();
  const { isTransparent } = useContext(DashboardHeaderContext);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.desktopContainer}>
          <div
            className={`${styles.titleBar} ${
              isTransparent ? styles.titleBarTransparent : ""
            }`}
            onClick={() => history.push("/menu")}
          >
            <Logo />
          </div>
          <div className={styles.rightNav}>
            <div className={styles.leftBar}>
              <span className={styles.welcome}>{headerText}</span>
            </div>
          </div>
        </div>

        <div className={styles.mobileContainer}>
          <div
            className={`${styles.titleBar}`}
            onClick={() => history.push("/menu")}
          >
            <Logo />
          </div>

          <div className={styles.rightBar}>
            <button
              className={styles.mobileMenuButton}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <FontAwesomeIcon icon={faBars} className={styles.menuBars} />
            </button>
            {showMobileMenu && (
              <div
                className={styles.mobileMenuOverlay}
                onClick={() => setShowMobileMenu(false)}
              >
                <div className={styles.mobileMenu}>
                  <Link to="/menu">Home</Link>
                  <Link to="/lesson-creation">Lesson Creator</Link>

                  <Link to={FEEDBACK_URL}>Give Feedback</Link>
                  <Link to="/logout" className={styles.logoutButton}>
                    Logout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
