import React from "react";
import { OnboardingDetails } from "../../../types/auth";
import { Input, Submit } from "../../common_components/Form";
import styles from "./OnboardingFormComponent.module.css";

interface OnboardingFormComponentProps {
  onboardingDetails: OnboardingDetails;
  setOnboardingDetails: (onboardingDetails: OnboardingDetails) => void;
  onboard: () => void;
}

export default function OnboardingFormComponent({
  onboardingDetails,
  setOnboardingDetails,
  onboard,
}: OnboardingFormComponentProps): React.JSX.Element {
  const { firstName, userName, lastName } = onboardingDetails;

  return (
    <div className={styles.form}>
      <div className={styles.titleComponent}>Set Up Your Profile</div>
      <Input
        className={styles.input}
        required
        placeholder="First name"
        onChange={(e) =>
          setOnboardingDetails({
            ...onboardingDetails,
            firstName: e.target.value,
          })
        }
        value={firstName}
      />
      <Input
        className={styles.input}
        required
        placeholder="Last name"
        onChange={(e) =>
          setOnboardingDetails({
            ...onboardingDetails,
            lastName: e.target.value,
          })
        }
        value={lastName}
      />
      <Input
        className={styles.input}
        required
        placeholder="User name"
        onChange={(e) =>
          setOnboardingDetails({
            ...onboardingDetails,
            userName: e.target.value,
          })
        }
        value={userName}
      />
      {/* <Input
        className={styles.fileInput}
        required
        type="file"
        id="ppDiv"
        style={{ display: "none" }}
        accept="image/*"
        placeholder="Profile picture"
        title="Choose imagexs"
        onChange={(e) =>
          setOnboardingDetails({
            ...onboardingDetails,
            profilePicture: e.target.files ? e.target.files[0] : undefined,
          })
        }
      /> */}
      {/* <label htmlFor={"ppDiv"} className={styles.fileInput}>
        <FontAwesomeIcon icon={faUpload} style={{ color: "gainsboro" }} />
        {profilePicture ? profilePicture?.name : "Select Profile picture"}
      </label> */}
      <div className={styles.spacing} />
      <Submit
        className={styles.submit}
        type="submit"
        value="Submit"
        onClick={onboard}
      />
    </div>
  );
}
