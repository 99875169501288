import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { services } from "./config";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

const authClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: services.AUTH_GRAPHQL_API,
    credentials: "include",
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

const userClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: services.USER_SERVICE_GRAPHQL_API,
    credentials: "include",
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

const lessonClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: services.LESSON_GRAPHQL_API,
    credentials: "include",
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

const lessonWsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new GraphQLWsLink(
    createClient({
      url: services.LESSON_GRAPHQL_WS_API,
    }),
  ),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

const aiWsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new GraphQLWsLink(
    createClient({
      url: services.AI_GRAPHQL_WS_API,
    }),
  ),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

const AIClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: services.AI_SERVICE_GRAPHQL_API,
    credentials: "include",
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

const searchClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: services.SEARCH_SERVICE_GRAPHQL_API,
    credentials: "include",
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export const Clients = {
  AUTH: authClient,
  USER: userClient,
  LESSON: lessonClient,
  LESSON_WS: lessonWsClient,
  AI_WS: aiWsClient,
  AI: AIClient,
  SEARCH: searchClient,
};

const container = document.getElementById("root");
const root = createRoot(container as Element);

root.render(
  <React.StrictMode>
    <ApolloProvider client={authClient}>
      <ApolloProvider client={userClient}>
        <ApolloProvider client={lessonClient}>
          <ApolloProvider client={AIClient}>
            <ApolloProvider client={searchClient}>
              <App />
            </ApolloProvider>
          </ApolloProvider>
        </ApolloProvider>
      </ApolloProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
