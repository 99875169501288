import React from "react";
import { Link } from "react-router-dom";
import styles from "./LessonEditButton.module.css";

interface LessonEditButtonProps {
  lessonId: string;
  shouldStyle?: boolean;
}

function LessonEditButton({
  lessonId,
  shouldStyle,
}: LessonEditButtonProps): JSX.Element {
  return (
    <Link
      to={`/lesson-creation/${lessonId}`}
      className={shouldStyle ? styles.lessonEditButton : ""}
    >
      Edit Lesson
    </Link>
  );
}

export default LessonEditButton;
