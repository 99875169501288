import { type NodeModel } from "@minoru/react-dnd-treeview";

export const ROOT_DIRECTORY = "root";
export interface TreeNodeDataType {
  content?: string;
}

export enum INodeTypes {
  file = "file",
  folder = "folder",
}

export const createNewTreeNode = (
  initialContent: string,
  type: INodeTypes,
  parentId?: string | number,
): NodeModel<TreeNodeDataType> => {
  switch (type) {
    case "file":
      return {
        id: crypto.randomUUID(),
        parent: parentId ?? ROOT_DIRECTORY,
        text: "",
        data: {
          content: initialContent,
        },
      };

    case "folder":
      return {
        id: crypto.randomUUID(),
        parent: parentId ?? ROOT_DIRECTORY,
        text: "",
        droppable: true,
      };

    default:
      return {
        id: crypto.randomUUID(),
        parent: parentId ?? ROOT_DIRECTORY,
        text: "",
        droppable: true,
      };
  }
};

export const getAbsoluteNodePath = (
  node: NodeModel<TreeNodeDataType>,
  treeData: Array<NodeModel<TreeNodeDataType>>,
): string => {
  const parents = [];
  let currNode = node;
  const nodesMap = new Map<string | number, NodeModel<TreeNodeDataType>>();
  treeData.forEach((node) => nodesMap.set(node.id, node));
  while (currNode.parent !== ROOT_DIRECTORY) {
    const parentNode = nodesMap.get(currNode.parent);
    if (parentNode == null) {
      throw new Error("A folder's parent does not exist");
    }
    parents.push(parentNode.text);
    currNode = parentNode;
  }
  parents.push(ROOT_DIRECTORY);
  return parents.reverse().join("/");
};

export const getMonacoModelPath = (
  node: NodeModel<TreeNodeDataType>,
  treeData: Array<NodeModel<TreeNodeDataType>>,
): string => {
  return getAbsoluteNodePath(node, treeData) + "/" + node.text;
};
