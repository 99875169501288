import { useSubscription } from "@apollo/client";
import { LIVE_LESSON_SUBSCRIPTION } from "../graphql";
import { Clients } from "..";
import { SubscriptionToModel } from "../views/LessonCreation/data-transformer";
import { ILesson } from "../types/lesson";
import React, { useEffect } from "react";

export const useLiveLessonFeed = (
  lessonId: string
): {
  lesson?: ILesson;
  loading: boolean;
  error?: string;
  isStreamingCompleted: boolean;
} => {
  const [isStreamingCompleted, setIsStreamingCompleted] = React.useState(false);
  const { data, loading } = useSubscription(LIVE_LESSON_SUBSCRIPTION, {
    client: Clients.LESSON_WS,
    fetchPolicy: "network-only",
    variables: {
      lessonId,
    },
  });

  useEffect(() => {
    if (!data) {
      // No data is being streamed
      setIsStreamingCompleted(true);
      return;
    }
    setIsStreamingCompleted(data.lesson.endOfStream ?? false);
  }, [data?.lesson.endOfStream]);

  const lesson = SubscriptionToModel.transformLesson(data);
  if (!lesson) {
    return {
      error: data?.lesson.error?.message ?? "Failed to fetch lesson",
      loading,
      isStreamingCompleted,
    };
  }
  return {
    lesson,
    loading,
    isStreamingCompleted,
  };
};
