import { faCircleInfo, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import styles from "./LessonSectionPreview.module.css";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "@mui/material";

export const MarkdownPreviewCodeBlock = ({
  children,
  className,
  ...props
}: JSX.IntrinsicElements["code"]): JSX.Element => {
  // In markdown, blocks have classes of language-bash, language-javascripts etc.
  const isBlock = className?.startsWith("language");

  let code = (children as string) ?? "";
  const handleCopyToClipboard = (): void => {
    void navigator.clipboard.writeText(code);
  };

  const index = code.indexOf("\n");
  const maybeFilePath = code.slice(0, index);
  let filePath = "Terminal";

  if (maybeFilePath.includes("file:")) {
    filePath = maybeFilePath.split("file:")[1];
    code = code.slice(index + 1);
  }

  if (isBlock) {
    return (
      <div className={styles.codeBlockPreview} id="codeSection">
        <div className={styles.codeBlockPreviewHeader}>
          <div className={styles.codeBlockPreviewHeaderTitle}>
            <FontAwesomeIcon icon={faFile} />
            <h4>{filePath}</h4>
            <Tooltip title="The path to the file in the workspace.">
              <FontAwesomeIcon icon={faCircleInfo} />
            </Tooltip>
          </div>
          <div className={styles.codeBlockPreviewHeaderButtons}>
            <button
              className={styles.codeBlockPreviewHeaderButton}
              onClick={handleCopyToClipboard}
            >
              <FontAwesomeIcon icon={faCopy} /> Copy
            </button>
            {/* <button className={styles.codeBlockPreviewHeaderButton}>
              <FontAwesomeIcon icon={faPlay} /> Run
            </button> */}
          </div>
        </div>
        <SyntaxHighlighter
          customStyle={{
            margin: 0,
            borderRadius: "10px",
            border: "1px solid #4B4E4C",
          }}
          style={atomOneDark}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    );
  } else {
    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  }
};
