import React from "react";

export interface ExecutionServiceContextValue {
  portUrl?: string;
  setPortUrl?: (portUrl: string) => void;
}

export const ExecutionServiceContext = React.createContext<ExecutionServiceContextValue>({});

interface ExecutionServiceProviderProps {
  children: React.ReactNode;
}

export function ExecutionServiceProvider({
  children,
}: ExecutionServiceProviderProps): React.ReactNode {
  const [portUrl, setPortUrl] = React.useState<string>();
  return (
    <ExecutionServiceContext.Provider
      value={{
        portUrl,
        setPortUrl,
      }}
    >
      {children}
    </ExecutionServiceContext.Provider>
  );
}

export function useExecutionService(): ExecutionServiceContextValue {
    const { portUrl, setPortUrl } = React.useContext(ExecutionServiceContext);
    return {
      portUrl,
      setPortUrl,
    };
  }