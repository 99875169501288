import React from "react";
import { ViewState } from "../../../LessonLayout/types";

export interface ViewContextData {
  currentView: ViewState;
  setCurrentView: (view: ViewState) => void;
}
export const ViewContext = React.createContext<ViewContextData>({
  currentView: ViewState.Lesson,
  setCurrentView: () => undefined,
});

interface ViewProviderProps {
  children: React.ReactNode;
}

export function ViewContextProvider({
  children,
}: ViewProviderProps): React.ReactNode {
  const [currentView, setCurrentView] = React.useState<ViewState>(
    ViewState.Lesson
  );

  return (
    <ViewContext.Provider
      value={{
        currentView,
        setCurrentView,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
}

export function useViewContext(): ViewContextData {
  const { currentView, setCurrentView } = React.useContext(ViewContext);
  return {
    currentView,
    setCurrentView,
  };
}
