import React, { createContext } from "react";

export const DashboardHeaderContext = createContext({
  isTransparent: false,
});

interface DashboardHeaderProviderProps {
  children: React.ReactNode;
  isTransparent?: boolean;
}

const DashboardHeaderProvider = ({
  children,
  isTransparent = false,
}: DashboardHeaderProviderProps): JSX.Element => {
  return (
    <DashboardHeaderContext.Provider value={{ isTransparent }}>
      {children}
    </DashboardHeaderContext.Provider>
  );
};

export default DashboardHeaderProvider;
