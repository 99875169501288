import React from "react";
import styles from "./SectionPreview.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

interface ISectionPreviewIconsProps {
  deleteSection: () => void;
  hidePreview: () => void;
}

function SectionPreviewIcons({
  deleteSection,
  hidePreview,
}: ISectionPreviewIconsProps): React.JSX.Element {
  return (
    <div className={styles.sectionPreviewIcons}>
      <button onClick={() => hidePreview()} className={styles.icon}>
        <FontAwesomeIcon icon={faPencil} />
      </button>
      <button
        onClick={() => deleteSection()}
        className={`${styles.icon} ${styles.sectionDeleteIcon}`}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    </div>
  );
}

export default SectionPreviewIcons;
