import { apiDomain } from "../../../../../config";
export const moveDirectory = async (
  currentDirectoryPath: string,
  targetDirectoryPath: string,
  sessionId: string,
): Promise<boolean> => {
  const response = await fetch(
    `${apiDomain}/session-service/session/moveDirectory`,
    {
      method: "PUT",
      body: JSON.stringify({
        sessionId,
        currentDirectoryPath,
        targetDirectoryPath,
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    },
  );

  const movedDirectory = await response.json().then((data) => data.success);
  return movedDirectory;
};
