import React from "react";
import styles from "./FeedbackButton.module.css";

interface FeedbackButtonProps {
  className?: string;
}

export const FEEDBACK_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLScagt1sHbfJW4iHte6zxNKkn6L73GActah_2R6UR-gNxk651g/viewform";

export default function FeedbackButton({
  className = "",
}: FeedbackButtonProps): JSX.Element {
  return (
    <a
      href={FEEDBACK_URL}
      target="_blank"
      className={`${styles.feedback} ${className}`}
      rel="noreferrer"
    >
      Give Feedback
    </a>
  );
}
