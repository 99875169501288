import React, { useState, useEffect } from "react";
import useLessonApiClient from "../../hooks/useLessonApiClient";
import { ILesson } from "../../types/lesson";
import DashboardComponent from "../common_components/DashboardComponent";
import GridComponent from "../common_components/GridComponent";
import LessonCard from "../common_components/LessonCard";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";
import { useSavedLessonIds } from "../common_components/SavedLessonContext/SavedLessonContext";
import styles from "./SavedLessonsDashboard.module.css";

export default function SavedLessonsView(): JSX.Element {
  const { savedLessons: savedLessonIds } = useSavedLessonIds();
  const { loading, fetchLesson } = useLessonApiClient();
  const [lessons, setLessons] = useState<ILesson[]>([]);

  const fetchLessonById = async (lessonId: string): Promise<void> => {
    try {
      const lesson = await fetchLesson(lessonId);

      if (lesson) {
        setLessons((prevLessons) => {
          if (prevLessons.some((prevLesson) => prevLesson.id === lesson.id)) {
            return prevLessons;
          }

          return [...prevLessons, lesson];
        });
      }
    } catch (error) {
      console.error("Error fetching lesson:", error);
    }
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const promises = Array.from(savedLessonIds ?? [])
          .filter((id) => !lessons.some((lesson) => lesson.id === id))
          .map(fetchLessonById);
        await Promise.all(promises);
      } catch (error) {
        console.error("Error fetching lessons:", error);
      }
    };

    fetchData().catch((error) => console.error("Error in fetchData:", error));
  }, [savedLessonIds, lessons]);

  const lessonCards = lessons.map((lesson) => (
    <LessonCard
      key={lesson.id}
      lesson={lesson}
      canBeDeleted={true}
      clickUrl={`/lesson/${lesson.id}`}
    />
  ));

  return (
    <DashboardComponent>
      {loading ? (
        <LoadingComponentFullScreen />
      ) : (
        <div className={styles.container}>
          <div className={styles.gridTitle}>Saved Lessons</div>
          <GridComponent boxes={lessonCards} />
        </div>
      )}
    </DashboardComponent>
  );
}
