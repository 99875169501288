import { faPaperPlane, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./InputSection.module.css";

interface IInputSectionProps {
  value: string;
  placeholder: string;
  handleChangeValue: (value: string) => void;
  handleSubmitInput: () => Promise<void>;
  isSending?: boolean;
}
export const InputSection: React.FC<IInputSectionProps> = ({
  value,
  placeholder,
  handleChangeValue,
  handleSubmitInput,
  isSending,
}) => {
  return (
    <div className={styles.inputBoxContainer}>
      <input
        name="Prompt ai"
        className={styles.inputBox}
        placeholder={placeholder}
        onChange={(event) => handleChangeValue(event.target.value)}
        value={value}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            void handleSubmitInput();
          }
        }}
      />
      <FontAwesomeIcon
        icon={isSending ? faSpinner : faPaperPlane}
        className={
          isSending
            ? "fa-spin"
            : value === ""
              ? styles.submitButtonDisabled
              : styles.submitButton
        }
        onClick={() => {
          void handleSubmitInput();
        }}
      />
    </div>
  );
};
