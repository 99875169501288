import React from "react";
import BoxComponent from "./BoxComponent";
import styles from "./GridComponent.module.css";

interface GridComponentProps {
  boxes: React.ReactNode[];
}

export default function GridComponent(props: GridComponentProps): JSX.Element {
  const boxes = props.boxes.map((element, index) => (
    <BoxComponent key={index}>{element}</BoxComponent>
  ));
  return <div className={styles.container}>{boxes}</div>;
}
