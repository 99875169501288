export interface RunProgramForLanguage {
  language:
    | "py"
    | "cpp"
    | "scala"
    | "js"
    | "ts"
    | "jsx"
    | "tsx"
    | "java"
    | undefined;
  filePath: string;
}

export const getRunCommandForLanguage = ({
  language,
  filePath,
}: RunProgramForLanguage): string => {
  switch (language) {
    case "cpp":
      // eslint-disable-next-line no-case-declarations
      const executableName = filePath.replace("cpp", "") ?? "";
      return `g++ ${filePath} -o ${executableName} \n`;
    case "java":
      // eslint-disable-next-line no-case-declarations
      const javaFileName =
        filePath.split("/").pop()?.replace("java", "") ?? undefined;
      if (javaFileName == null) {
        return "";
      }
      return `javac ${filePath} && java ${javaFileName}`;
    case "js":
    case "jsx":
      return `node ${filePath} \n`;
    case "py":
      return `python3 ${filePath} \n`;
    case "scala":
      // eslint-disable-next-line no-case-declarations
      const scalaFileName =
        filePath.split("/").pop()?.replace("scala", "") ?? undefined;
      if (scalaFileName == null) {
        return "";
      }
      return `scalac ${filePath} && scala ${scalaFileName}`;
    case "ts":
    case "tsx":
      return `node ${filePath} \n`;
    default:
      return "";
  }
};