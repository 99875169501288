import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "../button.module.css";
import { Tooltip } from "@mui/material";

export const WebButton = ({
  toggleShowChamBrowser,
  className = "",
}: {
  toggleShowChamBrowser: () => void;
  className?: string;
}): React.JSX.Element => {
  return (
    <Tooltip
      className={`${styles.button} ${className}`}
      onClick={toggleShowChamBrowser}
      title={"View Browser"}
    >
      <div>
        <FontAwesomeIcon icon={faGlobe} color="1b5a37" />
        Web
      </div>
    </Tooltip>
  );
};
