import React from "react";
import styles from "./ErrorComponent.module.css";

interface ErrorComponentProps {
  title?: string;
  children: React.ReactNode;
  close?: () => void;
}

export default function ErrorComponent({
  title,
  children,
  close,
}: ErrorComponentProps): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.sideContainer}>
        <i className="fas fa-times-circle"></i>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.errorPrefix}>{title ?? "Error Message"}</div>
        <div className={styles.errorMessage}>{children}</div>
      </div>
      {close && (
        <div className={styles.closeContainer} onClick={close}>
          CLOSE
        </div>
      )}
    </div>
  );
}
