import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const MarkdownCodeBlock = ({
  children,
  className,
  ...props
}: JSX.IntrinsicElements["code"]): JSX.Element => {
  // In markdown, blocks have classes of language-bash, language-javascripts etc.
  const isBlock = className?.startsWith("language");
  if (isBlock) {
    return (
      <SyntaxHighlighter customStyle={{ margin: 0 }} theme={a11yDark}>
        {(children as string) ?? ""}
      </SyntaxHighlighter>
    );
  } else {
    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  }
};
