/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import StompJS from "stompjs";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { useTerminalSession } from "../../contexts/TerminalSessionContext/TerminalSessionContext";
import styles from "./ChamTerminal.module.css";
import {
  SessionResponse,
  useCodeExecutionService,
} from "./hooks/use-code-execution-service";
import "./xterm.css";
import { getOrCreateExecutionService } from "../utils/getOrCreateExecutionService";
import { useUserApiClient } from "../../../../../hooks/useUserApiClient";
import { useExecutionService } from "../../contexts/ExecutionServiceContext/ExecutionServiceContext";
import useContextMenu from "./hooks/useContextMenu";
import { ContextMenu } from "../utils/contextMenu/ContextMenu";
import { AIClarificationPopover } from "../../../../Lesson/components/AIClarificationPopover";
import { useAIPopover } from "../../../../Lesson/components/LessonContainer/useAIPopover";

interface ChamTerminalProps {
  showTerminal: boolean;
  setShowTerminal: (show: boolean) => void;
  terminals: ITerminal[];
  activeTerminalId?: string;
}

export interface ITerminal {
  name: string;
  id: string;
  terminal: Terminal;
}

export const TERMINAL_BACKGROUND = "#161817";
export const TERMINAL_FOREGROUND = "#C5C9C7";
const MAX_RETRIES = 40;
const TIMEOUT = 3000;

export const ChamTerminal: React.FC<ChamTerminalProps> = ({
  showTerminal,
  setShowTerminal,
  terminals,
  activeTerminalId,
}: ChamTerminalProps) => {
  const {
    terminalSessionId: sessionId,
    programToRun,
    setProgramToRun,
    terminalConnected: stompConnected,
    setTerminalConnected: setStompConnected,
  } = useTerminalSession();
  const [isShowingPopover, setIsShowingPopover] = React.useState(false);
  const [popoverPosition, setPopoverPosition] = React.useState({
    top: 0,
    left: 0,
  });

  const { handleTerminalClarifyWithCham } = useAIPopover();
  const { signedInUser } = useUserApiClient();
  const { setPortUrl } = useExecutionService();
  const [interactiveCodeServer, setInteractiveCodeServer] =
    React.useState<string>();
  const [retryCount, setRetryCount] = React.useState(0);
  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const activeTerminal = terminals.find(
    (terminal) => terminal.id === activeTerminalId
  );
  const term = React.useMemo(() => activeTerminal?.terminal, [activeTerminal]);
  const { current: fitAddon } = useRef(new FitAddon());
  const [attachedTerms, setAttatchedTerms] = React.useState(new Set());
  const [activeProcesses, setActiveProcesses] = React.useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    const getCodeServer = async (): Promise<void> => {
      const codeServer = await getOrCreateExecutionService(
        sessionId,
        signedInUser?.id
      );
      if (codeServer == null) {
        return;
      }
      setInteractiveCodeServer(codeServer[9091]);
      setPortUrl?.(codeServer["8000"].replace("*.", ""));
    };
    void getCodeServer();
  }, [sessionId, signedInUser, setPortUrl]);

  const stompClient = useMemo<StompJS.Client | undefined>(() => {
    if (retryCount < MAX_RETRIES && interactiveCodeServer != null) {
      console.log("Re-creating stomp client");
      return StompJS.client(
        `wss://${interactiveCodeServer}/interactive-code-server`
      );
    }
  }, [interactiveCodeServer, retryCount]);

  useEffect(() => {
    terminals.forEach((terminal) => {
      const term = terminal.terminal;
      term.loadAddon(fitAddon);
      const termDocument = document.getElementById(terminal.id);
      if (term && termDocument && !attachedTerms.has(terminal.id)) {
        term.open(termDocument);
        fitAddon.fit();
        setAttatchedTerms(new Set([...attachedTerms, terminal.id]));
      }
    });
  }, [terminals]);

  const setResponseReceived = useCallback(
    (response?: SessionResponse): void => {
      if (response !== undefined) {
        if (response.message !== undefined) {
          term?.write(response.message);
        }
      }
    },
    [term]
  );

  const subscribed = useCodeExecutionService(
    stompClient,
    stompConnected,
    sessionId,
    setResponseReceived,
    activeTerminalId,
    activeProcesses
  );

  useEffect(() => {
    if (subscribed && activeTerminalId) {
      setActiveProcesses(new Set([...activeProcesses, activeTerminalId]));
    } else if (
      activeTerminalId &&
      !subscribed &&
      activeProcesses.has(activeTerminalId)
    ) {
      const updatedProcesses = new Set(activeProcesses);
      updatedProcesses.delete(activeTerminalId);
      setActiveProcesses(updatedProcesses);
    }
  }, [subscribed, activeTerminalId]);

  const sendInput = useCallback(
    (message: string, terminalId?: string) => {
      if (!terminalId) {
        return false;
      }
      stompClient?.send(
        "/app/input",
        {},
        JSON.stringify({ threadId: terminalId, message })
      );
      return true;
    },
    [term, stompClient]
  );

  const sendHeartbeat = useCallback(() => {
    if (stompConnected && activeTerminal) {
      stompClient?.send(
        "/app/heartbeat",
        {},
        JSON.stringify({ threadId: activeTerminalId })
      );
    }
  }, [activeTerminal, stompConnected]);

  const handleCopy = React.useCallback(() => {
    const selection = term?.getSelection();
    navigator.clipboard
      .writeText(selection ?? "")
      .then((text) => {
        console.log("Copied");
      })
      .catch((err) => {
        console.error("Failed to copy to clipboard", err);
      });
  }, [term]);

  const handleShowClarifyWithCham = React.useCallback(() => {
    setIsShowingPopover(true);
    setPopoverPosition({
      top: points.y + 10,
      left: points.x + 10,
    });
  }, [setIsShowingPopover, points]);

  const handleSubmitClarification = React.useCallback(
    async (question: string) => {
      if (!term?.getSelection()) {
        return;
      }
      await handleTerminalClarifyWithCham(term.getSelection(), question);
    },
    [handleTerminalClarifyWithCham, term]
  );
  const handlePaste = React.useCallback(() => {
    navigator.clipboard
      .readText()
      .then((text) => {
        sendInput(text, activeTerminalId);
      })
      .catch((err) => {
        console.error("Failed to read clipboard contents: ", err);
      });
  }, [navigator, sendInput]);

  useEffect(() => {
    if (programToRun && setProgramToRun != null) {
      sendInput(programToRun, activeTerminalId);
      setShowTerminal(true);
      setProgramToRun(undefined);
    }
  }, [programToRun, setProgramToRun]);

  useEffect(() => {
    const interval = setInterval(sendHeartbeat, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [sendHeartbeat]);

  useEffect(() => {
    const listener = term?.onKey(({ key, domEvent }) => {
      sendInput(key, activeTerminalId);
    });
    return () => {
      listener?.dispose();
    };
  }, [sendInput]);

  useEffect(() => {
    term?.attachCustomKeyEventHandler((event) => {
      const isPasteEvent =
        (event.ctrlKey || event.metaKey) && event.key === "v";
      if (event.type === "keydown" && isPasteEvent) {
        window.navigator.clipboard
          .readText()
          .then((clipText) => {
            console.log(`Is sending clipboard text ${clipText}`);
            sendInput(clipText, activeTerminalId);
          })
          .catch((error) => {
            console.error(`Error pasting ${error as string}`);
          });
        return false;
      }
      return true;
    });
  }, [sendInput]);

  useEffect(() => {
    if (stompClient && !stompConnected) {
      console.log(
        "Attempting connection ",
        retryCount + 1,
        " of ",
        MAX_RETRIES
      );

      stompClient.connect(
        "",
        "",
        () => {
          setStompConnected?.(true);
          console.log("Connected");
        },
        () => {
          setTimeout(() => {
            console.log("Connection attempt timed out, Retrying...");
            setRetryCount((retryCount) => retryCount + 1);
          }, TIMEOUT);

          setStompConnected?.(false);
          console.log("Disconnected");
        }
      );
    } else if (stompConnected) {
      return;
    } else {
      console.log("Failed to connect after ", MAX_RETRIES, " attempts");
    }
    if (stompClient) {
      stompClient.debug = () => null;
    }
  }, [stompClient, stompClient?.connected]);

  const handleLongPress = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    // Set a timeout for detecting long press (adjust as needed)
    const timeoutId = setTimeout(() => {
      setClicked(true);
      setPoints({
        x: event.touches[0].pageX,
        y: event.touches[0].pageY,
      });
    }, 500); // 500 milliseconds for a long press
    // Clear the timeout if touchend or touchcancel occurs before long press
    const handleTouchEnd = () => clearTimeout(timeoutId);
    const handleTouchCancel = () => clearTimeout(timeoutId);
    event.target.addEventListener("touchend", handleTouchEnd);
    event.target.addEventListener("touchcancel", handleTouchCancel);
  };

  return (
    <div className={showTerminal ? styles.container : styles.hide}>
      {interactiveCodeServer && !stompConnected && (
        <div>
          <div className={styles.loader}></div>
        </div>
      )}
      {terminals.map((terminal) => (
        <div
          id={terminal.id}
          key={terminal.id}
          style={{ height: "100%" }}
          className={
            interactiveCodeServer && !stompConnected
              ? styles.hide
              : !(activeTerminalId === terminal.id)
                ? styles.hide
                : ""
          }
          onContextMenu={(e) => {
            e.preventDefault();
            setClicked(true);
            setPoints({
              x: e.pageX,
              y: e.pageY,
            });
          }}
          onTouchStart={handleLongPress}
          onTouchMove={() => setClicked(false)}
          onTouchCancel={() => setClicked(false)}
        ></div>
      ))}
      {clicked && (
        <ContextMenu
          top={points.y}
          left={points.x}
          handleCopy={handleCopy}
          handlePaste={handlePaste}
          handleClarifyWithCham={handleShowClarifyWithCham}
        />
      )}
      {isShowingPopover && (
        <AIClarificationPopover
          onSubmit={handleSubmitClarification}
          isShowingPopover={isShowingPopover}
          popoverPosition={popoverPosition}
          setIsShowingPopover={setIsShowingPopover}
        />
      )}
    </div>
  );
};
