import React, { useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";

interface RenderActiveShapeProps {
  cx?: number;
  cy?: number;
  innerRadius?: number;
  outerRadius?: number;
  startAngle?: number;
  endAngle?: number;
  fill?: string;
  name?: string;
  percent?: number;
}

const RenderActiveShape = (props: RenderActiveShapeProps): JSX.Element => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    name,
    percent,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {name}
      </text>
      {percent && (
        <text x={cx} y={cy} dy={28} textAnchor="middle" fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={(outerRadius ?? 0) + 6}
        outerRadius={(outerRadius ?? 0) + 10}
        fill={fill}
      />
      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{payload.name}</text> */}
      {/* <text x={0.5} y={ey} dy={5} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

interface ChamPieChartProps {
  data: unknown[];
  dataKey: string;
  nameKey: string;
}

export default function ChamPieChart({
  data,
  dataKey,
  nameKey,
}: ChamPieChartProps): JSX.Element {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_: unknown, index: number): void => {
    setActiveIndex(index);
  };
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "hsl(147, 52%, 46%)"];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart
        margin={{
          top: 5,
          right: 30,
          left: 30,
          bottom: 60,
        }}
      >
        <Pie
          activeIndex={activeIndex}
          activeShape={RenderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={115}
          outerRadius={140}
          fill="hsl(147, 52%, 46%)"
          dataKey={dataKey}
          nameKey={nameKey}
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
