import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ContentTypes,
  ILesson,
  ILessonPage,
  ILessonPageSection,
} from "../types/lesson";
import useLessonApiClient from "./useLessonApiClient";
import { useLiveLessonFeed } from "./useLiveLessonFeed";

interface UseLessonManager {
  isLoading: boolean;
  isLessonSaved: boolean;
  lesson: ILesson;
  currentPageIndex: number;
  isStreamingCompleted: boolean;
  addPage: () => void;
  savePage: () => Promise<void>;
  updatePage: (updatedPage: ILessonPage) => void;
  addPageSection: () => void;
  deletePageSection: (sectionIndex: number) => void;
  updatePageSection: (
    sectionIndex: number,
    updatedSection: ILessonPageSection
  ) => void;
  updateCurrentPageIndex: (newPageIndex: number) => void;
}

function useLessonManager(lessonId: string): UseLessonManager {
  const [lesson, setLesson] = useState<ILesson>({
    id: lessonId,
    title: "",
    authorId: "",
    imageUrl: "",
    pages: [{ lessonId, title: "", sections: [], order: 0 }],
  });
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [sectionIDsToDelete, setSectionIDsToDelete] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLessonSaved, setIsLessonSaved] = useState<boolean>(true);
  const history = useHistory();
  const apiClient = useLessonApiClient();
  const { lesson: lessonUpdate, isStreamingCompleted } =
    useLiveLessonFeed(lessonId);

  useEffect(() => {
    updateCurrentPageIndex(lesson.pages.length - 1);
  }, [lesson.pages.length]);

  useEffect(() => {
    if (isLessonSaved) {
      setIsLoading(true);
      setSectionIDsToDelete([]);
      apiClient
        .fetchLesson(lessonId)
        .then((lesson) => {
          if (lesson.pages.length === 0) {
            lesson.pages.push({ lessonId, title: "", sections: [], order: 0 });
          }
          setLesson(lesson);
        })
        .catch(() => history.push("/menu"))
        .finally(() => setIsLoading(false));
    }
  }, [isLessonSaved, lessonId]);

  // This should be fixed
  useEffect(() => {
    if (lessonUpdate) {
      setLesson({
        ...lessonUpdate,
        id: lessonId,
      });
    }
  }, [lessonUpdate]);

  const copyLesson = React.useCallback(
    (originalLesson: ILesson) => {
      try {
        return structuredClone(originalLesson);
      } catch (error) {
        console.log("structured clone throws error", error);
        const copiedLesson = { ...originalLesson };
        copiedLesson.pages = [...copiedLesson.pages];
        copiedLesson.pages[currentPageIndex] = {
          ...copiedLesson.pages[currentPageIndex],
        };
        copiedLesson.pages[currentPageIndex].sections = [
          ...copiedLesson.pages[currentPageIndex].sections,
        ];
        return copiedLesson;
      }
    },
    [currentPageIndex]
  );

  const addPage = React.useCallback(() => {
    const copiedLesson = copyLesson(lesson);
    copiedLesson.pages.push({
      lessonId: lesson.id,
      title: "",
      sections: [],
      order: lesson.pages[lesson.pages.length - 1].order + 1,
    });
    setLesson(copiedLesson);
    setCurrentPageIndex(copiedLesson.pages.length - 1);
  }, [lesson]);

  const updatePage = React.useCallback(
    (updatedPage: ILessonPage) => {
      setLesson((prevLesson) => {
        const copiedLesson = copyLesson(prevLesson);
        copiedLesson.pages[currentPageIndex] = updatedPage;
        return copiedLesson;
      });
      setIsLessonSaved(false);
    },
    [currentPageIndex]
  );

  const addPageSection = React.useCallback(() => {
    setLesson((prevLesson) => {
      const copiedLesson = copyLesson(prevLesson);
      const copiedLessonSections =
        copiedLesson.pages[currentPageIndex].sections;
      copiedLessonSections.push({
        showPreview: false,
        order:
          copiedLessonSections[copiedLessonSections.length - 1]?.order + 1 || 0,
        content: {},
        contentType: ContentTypes.Unknown,
        clarification: {},
      });
      return copiedLesson;
    });
  }, [currentPageIndex]);

  const updatePageSection = React.useCallback(
    (sectionIndex: number, updatedSection: ILessonPageSection) => {
      setLesson((prevLesson) => {
        const copiedLesson = copyLesson(prevLesson);
        copiedLesson.pages[currentPageIndex].sections[sectionIndex] =
          updatedSection;
        return copiedLesson;
      });
      setIsLessonSaved(false);
    },
    [currentPageIndex]
  );

  const deletePageSection = React.useCallback(
    (sectionIndex: number) => {
      if (!confirm("Are you sure you want to delete?")) {
        return;
      }
      setLesson((prevLesson) => {
        const section =
          prevLesson.pages[currentPageIndex].sections[sectionIndex];
        if (section.id) {
          setSectionIDsToDelete([...sectionIDsToDelete, section.id]);
        }
        const copiedLesson = copyLesson(prevLesson);
        copiedLesson.pages[currentPageIndex].sections.splice(sectionIndex, 1);
        return copiedLesson;
      });
      setIsLessonSaved(false);
    },
    [currentPageIndex, lesson]
  );

  const savePage = React.useCallback(async () => {
    if (!isLessonSaved) {
      setIsLoading(true);
      const currentPage = lesson.pages[currentPageIndex];
      return await apiClient
        .saveLessonPage(currentPage)
        .then(async (currentPage) => {
          await Promise.all(
            sectionIDsToDelete.map(
              async (sectionId) =>
                await apiClient.deleteLessonSection(sectionId)
            )
          );
          return currentPage;
        })
        .then(
          async (savedPage) =>
            await Promise.all(
              currentPage.sections.map(
                async (section) =>
                  await apiClient.saveLessonSection(savedPage.pageId, section)
              )
            )
        )
        .then(() => {
          setIsLessonSaved(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [currentPageIndex, lesson]);

  const updateCurrentPageIndex = React.useCallback(
    (newPageIndex: number) => {
      const pagesLength = lesson.pages.length;
      setCurrentPageIndex(
        ((newPageIndex % pagesLength) + pagesLength) % pagesLength
      );
    },
    [lesson.pages.length]
  );

  return {
    isLoading,
    isLessonSaved,
    lesson,
    currentPageIndex,
    isStreamingCompleted,
    addPage,
    savePage,
    updatePage,
    addPageSection,
    updatePageSection,
    deletePageSection,
    updateCurrentPageIndex,
  };
}

export default useLessonManager;
