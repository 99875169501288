import React, { useEffect, useState } from "react";
import DashboardComponent from "../common_components/DashboardComponent";
import GridComponent from "../common_components/GridComponent";
import LessonManagementModal from "./components/LessonManagementModal";
import CreateLessonBoxComponent from "./components/CreateLessonBoxComponent";
import styles from "./LessonCreationDashboard.module.css";
import LessonCard from "../common_components/LessonCard";
import { ILesson } from "../../types/lesson";
import useLessonApiClient from "../../hooks/useLessonApiClient";
import { useUserApiClient } from "../../hooks/useUserApiClient";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";

export default function LessonCreationDashboard(): JSX.Element {
  const [lessonsCreated, setLessonsCreated] = useState<ILesson[]>([]);
  const [lessonToEditIndex, setLessonToEditIndex] = useState<number>();
  const [showLessonCreationModal, setShowLessonCreationModal] = useState(false);
  const { loading, fetchLessonsByAuthorId } = useLessonApiClient();
  const { signedInUser } = useUserApiClient();

  useEffect(() => {
    if (signedInUser) {
      fetchLessonsByAuthorId(signedInUser.id)
        .then((lessons: ILesson[]) => {
          setLessonsCreated(lessons);
        })
        .catch(() => setLessonsCreated([]));
    }
  }, [signedInUser]);

  const coursesCreatedBoxes = lessonsCreated.map((lesson, lessonIndex) => (
    <LessonCard
      key={lessonIndex}
      lesson={lesson}
      showLessonEditModal={() => setLessonToEditIndex(lessonIndex)}
      canBeDeleted={true}
      clickUrl={`/lesson/${lesson.id}`}
    />
  ));

  return (
    <DashboardComponent>
      {loading ? (
        <LoadingComponentFullScreen />
      ) : (
        <div className={styles.container}>
          <div className={styles.gridTitle}>Created Lessons</div>
          <GridComponent
            boxes={[
              <CreateLessonBoxComponent
                showModal={() => setShowLessonCreationModal(true)}
                key={"create-lesson"}
              />,
              ...coursesCreatedBoxes,
            ]}
          />
          {showLessonCreationModal && (
            <LessonManagementModal
              closeModal={() => setShowLessonCreationModal(false)}
            />
          )}
          {lessonToEditIndex !== undefined && (
            <LessonManagementModal
              lesson={lessonsCreated[lessonToEditIndex]}
              setLesson={(lesson) => {
                const newLessons = [...lessonsCreated];
                newLessons[lessonToEditIndex] = lesson;
                setLessonsCreated(newLessons);
              }}
              closeModal={() => setLessonToEditIndex(undefined)}
            />
          )}
        </div>
      )}
    </DashboardComponent>
  );
}
