import { faPlay, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useTerminalSession } from "../../../../contexts/TerminalSessionContext/TerminalSessionContext";
import styles from "../button.module.css";
import { RunProgramForLanguage, getRunCommandForLanguage } from "./utils";
import { Tooltip } from "@mui/material";
import { useViewContext } from "../../../../contexts/ViewContext/ViewContext";
import { ViewState } from "../../../../../LessonLayout/types";

export const RunButton = ({
  editorPath,
}: {
  editorPath?: string;
}): React.JSX.Element => {
  const { setProgramToRun, terminalConnected } = useTerminalSession();
  const { setCurrentView } = useViewContext();

  const runDirectory = React.useMemo(() => {
    return editorPath?.replace("root", "//workspace") ?? "";
  }, [editorPath]);

  const runCommand = useMemo(
    () =>
      getRunCommandForLanguage({
        language: runDirectory
          .split(".")
          .pop() as RunProgramForLanguage["language"],
        filePath: runDirectory,
      }),
    [runDirectory]
  );

  return (
    <Tooltip
      className={styles.button}
      onClick={() => {
        setProgramToRun?.(runCommand);
        setCurrentView(ViewState.Terminal);
      }}
      title={terminalConnected ? "Run Code" : "Provisioning Resources"}
    >
      <div>
        <FontAwesomeIcon
          icon={terminalConnected ? faPlay : faSpinner}
          className={terminalConnected ? "" : "fa-spin"}
          color="1b5a37"
        />
        Run
      </div>
    </Tooltip>
  );
};
