import React, { HTMLAttributes } from "react";
import styles from "./SectionEdit.module.css";
import { parseYoutubeLink } from "./utils";

interface IVideoEditorProps {
  videoLink?: string;
  setVideoLink: (value: string) => void;
}

function VideoEditor({
  videoLink,
  setVideoLink,
}: HTMLAttributes<HTMLDivElement> & IVideoEditorProps): React.JSX.Element {
  return (
    <div className={styles.videoEditor}>
      <input
        type="url"
        value={videoLink}
        onChange={(event) => setVideoLink(parseYoutubeLink(event.target.value))}
        placeholder="Enter a URL..."
      />
      <div className={styles.uploadVideoContainer}>
        <span>or</span>
        <div className={styles.uploadVideo}>Upload a file</div>
      </div>
    </div>
  );
}

export default VideoEditor;
