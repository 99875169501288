import React, { useState } from "react";
import styles from "./Collapsible.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
}

export const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapsible = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.collapsible}>
      <button
        onClick={toggleCollapsible}
        className={styles.collapsibleHeader}
        aria-expanded={isOpen}
      >
        {title}
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className={styles.collapsibleIcon}
        />
      </button>
      <div
        className={`${styles.collapsibleContent} ${isOpen ? styles.open : ""}`}
        aria-hidden={!isOpen}
      >
        {children}
      </div>
    </div>
  );
};
