import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import React from "react";
import { BooleanResponse } from "../../../types/auth";
import styles from "../LessonCreation.module.css";

interface IPageMenuProps {
  currentPageIndex: number;
  totalPages: number;
  updateCurrentPageIndex: (newIndex: number) => void;
  isPageCompleted?: boolean;
  isLoading?: boolean;
  togglePageCompletion?: () => Promise<BooleanResponse>;
  addPage?: () => void;
}

function PageMenu({
  currentPageIndex,
  totalPages,
  updateCurrentPageIndex,
  isPageCompleted,
  isLoading,
  togglePageCompletion,
  addPage,
}: IPageMenuProps): React.JSX.Element {
  const handleTogglePageCompletion = React.useCallback(async () => {
    if (togglePageCompletion) {
      const response = await togglePageCompletion();
      if (!response.success) {
        console.error(response.error);
      }
    }
  }, [togglePageCompletion]);
  return (
    <div className={styles.pageMenu}>
      <div className={styles.pagesNavigation}>
        <span className={styles.pagesCount}>
          {currentPageIndex + 1}
          <span> of </span>
          {totalPages}
        </span>
      </div>
      <div>
        <button
          onClick={() => updateCurrentPageIndex(currentPageIndex - 1)}
          className={styles.pageNavigationArrow}
        >
          <FontAwesomeIcon icon={faAngleLeft} className={styles.icon} />
        </button>
        <button
          onClick={() => updateCurrentPageIndex(currentPageIndex + 1)}
          className={styles.pageNavigationArrow}
        >
          <FontAwesomeIcon icon={faAngleRight} className={styles.icon} />
        </button>
        {addPage && (
          <button onClick={() => addPage()} className={styles.addPageButton}>
            Add Page
          </button>
        )}
        {togglePageCompletion && (
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleTogglePageCompletion}
            className={styles.addPageButton}
          >
            {isLoading ? (
              <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <CircularProgress size="2rem" color="inherit" />
              </div>
            ) : isPageCompleted ? (
              "Unmark Complete"
            ) : (
              "Done"
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export default PageMenu;
