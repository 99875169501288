import React from "react";
import styles from "./FooterComponent.module.css";

export default function FooterComponent(): JSX.Element {
  return (
    <div className={styles.container}>
      <span className={styles.copyrightNotice}>
        &copy; {new Date().getFullYear()} Chameleon Learning.
      </span>
      <div className={styles.links}>
        <a className={styles.link} href="#">
          Privacy
        </a>
        <a className={styles.link} href="#">
          Security
        </a>
        <a className={styles.link} href="#">
          Legal
        </a>
        <a className={styles.link} href="#">
          About Us
        </a>
      </div>
    </div>
  );
}
