import React from "react";
import styles from "./CreateLessonBoxComponent.module.css";

interface CreateLessonBoxComponentProps {
  showModal: () => void;
}

export default function CreateLessonBoxComponent({
  showModal,
}: CreateLessonBoxComponentProps): React.JSX.Element {
  return (
    <div className={styles.container} onClick={() => showModal()}>
      <i className={`fas fa-plus ${styles.plusIcon}`}></i>
    </div>
  );
}
