import React from "react";
import { Input, Submit } from "../../../common_components/Form";
import styles from "./LoginFormComponent.module.css";
import { useHistory } from "react-router-dom";
import { LoginCredential } from "../../../../types/auth";

interface LoginFormComponentProps {
  credential: LoginCredential;
  setCredential: (credential: LoginCredential) => void;
  login: () => void;
}

export default function LoginFormComponent({
  credential,
  setCredential,
  login,
}: LoginFormComponentProps): JSX.Element {
  const history = useHistory();
  return (
    <form className={styles.form} onSubmit={() => login()}>
      <div className={styles.titleComponent}>Sign In</div>
      <Input
        className={styles.input}
        required
        type="email"
        placeholder="Email"
        onChange={(event) =>
          setCredential({ ...credential, email: event.target.value })
        }
      />
      <Input
        className={styles.input}
        required
        type="password"
        placeholder="Password"
        onChange={(event) =>
          setCredential({ ...credential, password: event.target.value })
        }
      />
      <div
        onClick={() => history.push("/forgotpassword")}
        className={styles.forgotPassword}
      >
        Forgot password?
      </div>
      <div className={styles.spacing} />
      <Submit className={styles.submit} type="submit" value="Submit" />
    </form>
  );
}
